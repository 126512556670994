import AddRecipientContainer from '../../../AddRecipient/AddRecipientContainer';
import SenderDetail from '../../../SenderDetail';
import CustomizeSubjectLineContainer from './CustomizeSubjectLine/CustomizeSubjectLineContainer';

interface Props {
	titlesArray: string[];
	handleOpenModelClick: (title: string) => void;
	ModelTitle: string;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const Operations: React.FC<Props> = ({
	titlesArray,
	handleOpenModelClick,
	ModelTitle,
	setTitleModel,
	setProgressSteps,
	progressSteps,
}) => {
	return (
		<>
			{titlesArray.map((title: string, index) => {
				return (
					<div key={title}
						className="w-full p-6 rounded bg-white flex flex-col  h-fit"
						style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
					>
						<div className="w-full flex justify-between">
							<div className="font-inter font-semibold text-lg" style={{ color: '#111827' }}>
								{title}
							</div>
							<button
								onClick={() => handleOpenModelClick(title)}
								disabled={
									title !== ModelTitle &&
									progressSteps.length < 2 &&
									index > titlesArray.findIndex((item) => item === ModelTitle)
								}
								className={
									title !== ModelTitle &&
									progressSteps.length < 2 &&
									index > titlesArray.findIndex((item) => item === ModelTitle)
										? 'cursor-not-allowed'
										: ''
								}
							>
								<svg
									className={title !== ModelTitle ? '' : 'hidden'}
									width="26"
									height="26"
									viewBox="0 0 26 26"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#F3F4F6" />
									<path
										d="M20 10L13 17L6 10"
										stroke="#2563EB"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#F3F4F6" />
								</svg>
								<svg
									className={title === ModelTitle ? '' : 'hidden'}
									width="26"
									height="26"
									viewBox="0 0 26 26"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="25.5"
										y="25.5"
										width="25"
										height="25"
										rx="12.5"
										transform="rotate(-180 25.5 25.5)"
										fill="#F3F4F6"
									/>
									<path
										d="M6 16L13 9L20 16"
										stroke="#2563EB"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<rect
										x="25.5"
										y="25.5"
										width="25"
										height="25"
										rx="12.5"
										transform="rotate(-180 25.5 25.5)"
										stroke="#F3F4F6"
									/>
								</svg>
							</button>
						</div>
						<div className={title === 'Customize the subject line' ? '' : 'hidden'}>
							<div className={ModelTitle === 'Customize the subject line' ? ' flex flex-col gap-y-3' : 'hidden'}>
								<CustomizeSubjectLineContainer title={ModelTitle} />
							</div>
						</div>
						<div className={title === 'Add some recipients' ? '' : 'hidden'}>
							<div className={ModelTitle === 'Add some recipients' ? '' : 'hidden'}>
								<AddRecipientContainer
									setTitleModel={setTitleModel}
									setProgressSteps={setProgressSteps}
									progressSteps={progressSteps}
								/>
							</div>
						</div>
						<div className={title === 'Connect your email account' ? '' : 'hidden'}>
							<div className={ModelTitle === 'Connect your email account' ? 'mt-4' : 'hidden'}>
								<SenderDetail
									setTitleModel={setTitleModel}
									setProgressSteps={setProgressSteps}
									progressSteps={progressSteps}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Operations;
