import React from 'react';
import { CardProspectsData, CardProspectStatusEnum } from '../../../../../../../contexts/CardProspects/interfaces';

interface Props {
	cardProspectsData: CardProspectsData;
	computeTableColumns: (prospectObj: any) => [string, unknown][];
	computerHeaderColumns: () => Array<string>;
	openModalWithRecipientInfo: (edit: boolean, recipient: any) => void;
	handleProspectStatusChange: (prospect: any) => void;
	handleSelectAll: (event: any) => void;
	infiniteRef: any;
	prospectsLoading: boolean;
}
const RecipientScheduleTable: React.FC<Props> = ({
	cardProspectsData,
	computerHeaderColumns,
	computeTableColumns,
	openModalWithRecipientInfo,
	handleProspectStatusChange,
	handleSelectAll,
	infiniteRef,
	prospectsLoading,
}) => {
	return (
		<div className="border-2 border-gray200 h-64  sm:overflow-y-scroll overflow-scroll  bg-lightgray rounded-md">
			<table className="min-w-full divide-y divide-lightgray">
				<thead className="bg-lightgray border-b-2 border-gray200">
					<tr>
            <th className='px-6 py-3'></th>
						<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
							<input
								onChange={handleSelectAll}
								type="checkbox"
								className="h-4 w-4 text-indigo focus:ring-indigo border-gray-300 rounded"
								title="Select all"
							/>
						</th>
						{computerHeaderColumns()?.map((item, index) => (
							<th key={index}
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
							>
								{item || 'Un-named'}
							</th>
						))}
						<th scope="col" className="relative px-6 py-3 text-red">
							<span className="sr-only ">Edit</span>
						</th>
					</tr>
				</thead>
				<tbody className="border divide-y-2 divide-gray200">
					{cardProspectsData?.cardProspects?.length ? (
						cardProspectsData?.cardProspects?.map((prospect,index) => (
							<tr
								title={prospect.message}
								key={prospect._id}
								className={
									[CardProspectStatusEnum.INVALID_EMAIL, CardProspectStatusEnum.ERROR].includes(prospect.status)
										? 'bg-successBgColor'
										: 'bg-white'
								}
							>
                <td className="pl-4">{index+1}</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
									<input
										checked={prospect.status === 'SELECTED'}
										onChange={() => handleProspectStatusChange(prospect)}
										type="checkbox"
										className="h-4 w-4 text-indigo focus:ring-indigo border-gray-300 rounded"
										disabled={[CardProspectStatusEnum.INVALID_EMAIL, CardProspectStatusEnum.ERROR].includes(
											prospect.status
										)}
									/>
								</td>
								{computeTableColumns(prospect).map((item, idx) => (
									<td
										key={item[0]}
										className={`px-6 py-4 whitespace-nowrap text-sm ${
											idx ? 'text-mediumGray' : 'font-semibold text-blackText'
										}`}
									>
										{typeof item[1] === 'string' && !!item[1] ? item[1] : '-'}
									</td>
								))}
								<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										onClick={() => openModalWithRecipientInfo(true, prospect)}
										className="text-indigo hover:text-indigo "
									>
										Edit
									</button>
								</td>
							</tr>
						))
					) : (
						<tr className={prospectsLoading ? 'hidden' : 'flex justify-center'}><td>No recipients found.</td></tr>
					)}
				</tbody>
			</table>
			<div ref={infiniteRef} className="p-2 bg-white">
				<p className={prospectsLoading ? 'animate-pulse rounded-lg h-5 text-indigo text-center' : 'hidden'}>
					Loading ...
				</p>
			</div>
		</div>
	);
};

export default RecipientScheduleTable;
