import Papa from 'papaparse';
import React, { useContext, useEffect, useState } from 'react';
import swal from 'sweetalert';
import { CardProspectsContext } from '../../../../../../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../../../../../../contexts/DesignTool/DesignToolContext';
import MergeTagList from './MergeTagList';

interface Props {
	addNewMergeTag: boolean;
	toggleAddMergeTag: () => void;
	parsedProspects: Array<any> | null;
	setparsedProspects: React.Dispatch<React.SetStateAction<any[] | null>>;
	setdisableNext: React.Dispatch<React.SetStateAction<boolean>>;
}

const MergeTagListContainer: React.FC<Props> = ({
	addNewMergeTag,
	toggleAddMergeTag,
	setparsedProspects,
	setdisableNext,
}) => {
	const { setMergeTags, mergeTags, csvFields, setcsvFields, prospectListEdit } = useContext(DesignToolContext);
	const { csvFile } = useContext(CardProspectsContext);
	const [newMergeTagName, setnewMergeTagName] = useState<string>('');
	const [newMergeTagColName, setNewMergeTagColName] = useState<string>('Select value');

	const parseCsvFile = () => {
		if (csvFile) {
			Papa.parse(csvFile, {
				skipEmptyLines: true,
				header: true,
				worker: true,
				complete: (results) => {
					if (results?.meta?.fields) {
						if (results.data[0] != null && typeof results.data[0] === 'object') {
							const cleanProspects = results.data.map((prospect: any) => {
								let cleanProspect = {};
								Object.keys(prospect).forEach((item) => {
									const key = `${JSON.parse(JSON.stringify(item).split('.').join(' '))}`.trim();
									cleanProspect = {
										...cleanProspect,
										[key]: prospect[item].trim(),
									};
								});
								return cleanProspect;
							});
							setcsvFields(Object.keys(cleanProspects[0]));
							setparsedProspects(cleanProspects);
						}
					}
				},
			});
		}
	};

	useEffect(() => {
		if (!prospectListEdit) {
			if (csvFile) {
				parseCsvFile();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [csvFile]);

	useEffect(() => {
		/**
		 * This loop basically verifies whether the Email or Name merge tag has no col value mapped to it
		 */
		const result = mergeTags?.filter((item: any) => {
			return ['name', 'email'].includes(item.tagName) && String(item.colName).toLocaleLowerCase() === 'select value';
		});
		if (result?.length) {
			setdisableNext(true);
		} else {
			setdisableNext(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mergeTags]);

	const handleDropdownValueChange = (tagName: string, colName: string) => {
		setMergeTags((prev: any) => {
			return prev.map((item: any) =>
				item.tagName === tagName ? { ...item, colName: !!colName ? colName : 'Select value' } : item
			);
		});
	};

	/**
	 * Responisble for adding merge Tags other than [name,email]
	 * @param tagName
	 * @param colName
	 * @returns
	 */
	const handleAddMergeTag = (tagName: string, colName: string) => {
		const result = mergeTags.find((item: any) => {
			return item.tagName === tagName;
		});

		if (result) {
			swal('Error', 'Cannot add merge tag with the same name.', 'error');
			return;
		} else {
			setMergeTags((prev: any) => {
				prev.push({ tagName, colName });
				setnewMergeTagName('');
				setNewMergeTagColName('Select value');
				toggleAddMergeTag();
			});
		}
	};

	return (
		<MergeTagList
			addNewMergeTag={addNewMergeTag}
			toggleAddMergeTag={toggleAddMergeTag}
			csvFields={csvFields}
			mergeTags={mergeTags}
			handleDropdownValueChange={handleDropdownValueChange}
			handleAddMergeTag={handleAddMergeTag}
			setnewMergeTagName={setnewMergeTagName}
			newMergeTagName={newMergeTagName}
			setNewMergeTagColName={setNewMergeTagColName}
			newMergeTagColName={newMergeTagColName}
		/>
	);
};

export default MergeTagListContainer;
