import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { DesignCategory, UserUploads } from './designCategories.interface';

const getDesginCategories = (): Promise<DesignCategory[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/outer-design',
			};
			const resp = await axios(config);
			const designCategoriesData = resp.data;
			resolve(designCategoriesData.data);
		} catch (error: any) {
			reject(error);
		}
	});
};

const getUserUploadsCategory = (workspaceId: string, userId: string, type: string): Promise<UserUploads> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/myUploads?workspaceId=${workspaceId}&userId=${userId}&type=${type}`,
			};
			const resp = await axios(config);
			const designCategoriesData = resp.data;
			resolve(designCategoriesData.data);
		} catch (error: any) {
			reject(error);
		}
	});
};


export const design_categories_services = {
	getDesginCategories,
	getUserUploadsCategory,
};
