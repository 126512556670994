import I18n from 'i18n-js';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import DesignToolActions from '../contexts/DesignTool/DesignToolActions';
import { DesignToolContext } from '../contexts/DesignTool/DesignToolContext';
import LeftSideBarActions from '../contexts/DesignTool/LeftSideBarActions';
import { amazonUploadingServices } from '../services/request/s3ImageUpload';
import { defaultBackgroundProps, stageDimensions } from '../utils/defaults';
import Resizer from '../utils/imageResizer';
import useCardNavigation from './useCardNavigation';
const useCardToolFunctions = () => {
	const {
		designToolState: { selectedShapeName, activePageIndex, right_side_bar_selection },
		setCardPagesX,
		cardPages,
		setColorPickerShowModal,
		setRefetch,
		copiedData,
	} = useContext(DesignToolContext);

	const { unSelect } = useCardNavigation();
	const { setSelectedShapeName, resetStateMachine } = DesignToolActions();
	const { selectElement } = LeftSideBarActions();
	const userProfile = useSelector((state: any) => state.userProfile);
	const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
	/**
	 *
	 * This function is used to add text on stage with defaults styling.
	 */

	const isBackgroundImage = () =>
		right_side_bar_selection.element === 'image' && right_side_bar_selection.type === 'background';

	const handleText = () => {
		// `addedby` will be undefined in case of collaborator because we do not save firebase Id
		let addedby = userProfile._id; 

		setCardPagesX((prev: any) => {
			const newId = 'textBoxes_' + new Date().getTime().toString();
			const stageDimension: any = { ...stageDimensions };
			const updatedShape = {
				id: newId,
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				lineHeight: 1,
				letterSpacing: 0,
				verticalAlign: 'top',
				fill: '#000000',
				fontSize: 25,
				align: 'left',
				addedby,
				text: 'Click to add text',
				name: 'object',
				x: (stageDimension.width - 180) / 2,
				y: (stageDimension.height - 120) / 2,
				isNew: true,
				draggable: true,
				width: 200, 
				height:50,
				effectType: 'none',
			};
			prev[activePageIndex]?.textBoxes.push(updatedShape);
			setSelectedShapeName({
				shape_id: newId,
				shapeType: { element: 'text', type: 'fontTool' },
			});
			selectElement({ element: 'text', type: 'fontTool' });
		});
	};
	const handleTextPaste = () => {
		const shapeType = copiedData?.id.split('_'); 
		if (shapeType[0] === 'textBoxes') {
			setCardPagesX((prev: any) => {
				const newId = 'textBoxes_' + new Date().getTime().toString();
				const updatedShape = {
					id: newId,
					fontFamily: copiedData.fontFamily,
					fontStyle: copiedData.fontStyle,
					fill: copiedData.fill,
					fontSize: copiedData.fontSize,
					align: copiedData.align,
					text: copiedData.text,
					name: copiedData.name,
					x: copiedData.x + 20,
					y: copiedData.y + 50,
					isNew: copiedData.isNew,
					draggable: copiedData.draggable,
					width: copiedData.width,
					height:copiedData.height
				};
				prev[activePageIndex]?.textBoxes.push(updatedShape);
				setSelectedShapeName({
					shape_id: newId,
					shapeType: { element: 'text', type: 'fontTool' },
				});
				selectElement({ element: 'text', type: 'fontTool' });
			});
		} else if (shapeType[0] === 'images') {
			const pageIndexForDownloadUrl = activePageIndex;
			let imageId: any = new Date().getTime();
			imageId = 'images_' + imageId.toString();
			setCardPagesX((prev: any) => {
				prev[pageIndexForDownloadUrl].images.push({
					draggable: copiedData.draggable,
					name: copiedData.name,
					id: imageId,
					downloadUrl: copiedData.downloadUrl,
					x: copiedData.x + 20,
					y: copiedData.y + 50,
					scaleX: copiedData.scaleX,
					scaleY: copiedData.scaleY,
					width: copiedData.width,
					height: copiedData.height,
				});
				setSelectedShapeName({
					shape_id: imageId,
					shapeType: { element: 'image', type: null },
				});
			});
		} else if (shapeType[0] === 'gifs') {
			const pageIndexForDownloadUrl = activePageIndex;
			let gifId: any = new Date().getTime();
			gifId = 'gifs_' + gifId.toString();
			setCardPagesX((prev: any) => {
				const updatedGif = {
					draggable: copiedData.draggable,
					name: copiedData.name,
					id: gifId,
					src: copiedData.src,
					x: copiedData.x + 40,
					y: copiedData.y + 40,
					scaleX: copiedData.scaleX,
					scaleY: copiedData.scaleY,
					width: copiedData.width,
					height: copiedData.height,
				};
				if (prev[pageIndexForDownloadUrl]?.gifs) {
					prev[pageIndexForDownloadUrl].gifs.push(updatedGif);
				} else {
					prev[activePageIndex] = {
						...prev[activePageIndex],
						gifs: [updatedGif],
					};
				}
				setSelectedShapeName({
					shape_id: gifId,
					shapeType: { element: 'gif', type: null },
				});
			});
		} else if (shapeType[0] === 'ctas') {
			setCardPagesX((prev: any) => {
				const newId = 'ctas_' + new Date().getTime().toString();
				const updatedShape = {
					id: newId,
					name: copiedData.name,
					x: copiedData.x + 20,
					y: copiedData.y + 50,
					draggable: copiedData.draggable,
					width: copiedData.width,
					height: copiedData.height,
					urlLink: copiedData.urlLink,
					text: copiedData.text,
					btnColor: copiedData.btnColor,
					textColor: copiedData.textColor,
					borderRadius: copiedData.borderRadius,
				};
				if (prev[activePageIndex]?.ctas) {
					prev[activePageIndex]?.ctas.push(updatedShape);
				} else {
					prev[activePageIndex] = {
						...prev[activePageIndex],
						ctas: [updatedShape],
					};
				}
				setSelectedShapeName({
					shape_id: newId,
					shapeType: { element: 'cta', type: null },
				});
				selectElement({ element: 'cta', type: null });
			});
		}
	};

	const handleAddCta = () => {
		// `addedby` will be undefined in case of collaborator because we do not save firebase Id
		let addedby = userProfile._id;

		setCardPagesX((prev: any) => {
			const newId = 'ctas_' + new Date().getTime().toString();
			const stageDimension: any = { ...stageDimensions };
			const updatedShape = {
				id: newId,
				addedby,
				name: 'object',
				x: (stageDimension.width - 180) / 2,
				y: (stageDimension.height - 120) / 2,
				draggable: true,
				width: 150,
				height: 50,
				urlLink: 'https://example.com',
				text: 'Click Me!',
				btnColor: '#171717',
				textColor: '#FFFFFF',
				borderRadius: 'rounded',
			};
			if (prev[activePageIndex]?.ctas) {
				prev[activePageIndex]?.ctas.push(updatedShape);
			} else {
				prev[activePageIndex] = {
					...prev[activePageIndex],
					ctas: [updatedShape],
				};
			}
			setSelectedShapeName({
				shape_id: newId,
				shapeType: { element: 'cta', type: null },
			});
			selectElement({ element: 'cta', type: null });
		});
	};
	/**
	 * This function is getting the selected element ( Text and images ) data.
	 *
	 */
	const getSelectedElementData = () => {
		if (selectedShapeName) {
			const shapeType = selectedShapeName?.split('_')[0];
			const selectedShape = cardPages[activePageIndex][shapeType]?.find((item: any) => item?.id === selectedShapeName);
			return selectedShape;
		} else {
			return null;
		}
	};
	const selectedShapeData = getSelectedElementData();
	/**
	 * This function is used to change text styling of selected text on stage. example alignment, font Style, font family.
	 */
	const handleTextProps = (type: string, value: any) => { 
		setCardPagesX((prev: any) => {
			prev[activePageIndex]?.textBoxes?.find((item: any, index: any) => {
				if (item.id === selectedShapeName) {
					prev[activePageIndex].textBoxes[index][type] = value;
					if (type !== 'fill') {
						setColorPickerShowModal(false);
					}
					return true;
				}
				return false;
			});
		});
	};
	/**
	 * This function is used to change cta styling of selected cta on stage. example url, color, text color, etc.
	 */
	const handleCtaProps = (type: string, value: any) => {
		setCardPagesX((prev: any) => {
			prev[activePageIndex].ctas.find((item: any, index: any) => {
				if (item.id === selectedShapeName) {
					prev[activePageIndex].ctas[index][type] = value;
					return true;
				}
				return false;
			});
		});
	};
	/**
	 * This function is used to change selected text effects (glitch, neon, lift, splice ).
	 * reason for creating this function separately is that we need to set multiple attributes of text here.
	 */
	const handleTextEffect = (textObj: any) => {
		setCardPagesX((prev: any) => {
			prev[activePageIndex].textBoxes.find((item: any, index: any) => {
				if (item.id === selectedShapeName) {
					prev[activePageIndex].textBoxes[index] = textObj;
					return true;
				}
				return false;
			});
		});
	};
	const handleAddBackgroundColor = (color: string) => {
		const pageIndexForBgColor = activePageIndex;
		let rectId: any = new Date().getTime();
		rectId = 'rects_' + rectId.toString();
		let addedby = userProfile._id;
		setCardPagesX((prev: any) => {
			const updatedBgColor = {
				draggable: false,
				name: 'object',
				id: rectId,
				addedby,
				x: 0,
				y: 0,
				width: stageDimensions.width,
				height: stageDimensions.height,
				fill: color,
			};
			prev[pageIndexForBgColor] = {
				...prev[pageIndexForBgColor],
				rects: [updatedBgColor],
			};
		});
	};
	/**
	 *
	 * This function is used to add image and gif on stage with defaults styling.
	 */
	const handleImage = async (value: any, width?: any, height?: any) => {
		if (value.file.type === 'image/gif') {
			try {
				toast.info('Uploading gif!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					pauseOnFocusLoss: false,
				});
				const downloadUrl = await amazonUploadingServices.uploadUserImage(
					value.file,
					'GIF',
					currentWorkspace?._id,
					userProfile?._id
				);

				handleAddGif(downloadUrl, width, height);
			} catch (error: any) {
				toast.error(error.message, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					pauseOnFocusLoss: false,
				});
			}
		} else {
			const pageIndexForDownloadUrl = activePageIndex;

			try {
				toast.info('Uploading image!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					pauseOnFocusLoss: false,
				});
				let downloadUrl = await amazonUploadingServices.uploadUserImage(
					value.file,
					'IMAGE',
					currentWorkspace?._id,
					userProfile?._id
				);

				if (downloadUrl) {
					let imageId: any = new Date().getTime();
					imageId = 'images_' + imageId.toString();
					// `addedby` will be undefined in case of collaborator because we do not save firebase Id
					let addedby = userProfile._id;

					setCardPagesX((prev: any) => {
						if (isBackgroundImage()) {
							// const smartCroppedImage = `https://image.cardclan.io/api/imageproxy/320x458,sc/${downloadUrl}`;
							const smartCroppedImage = downloadUrl;
							if (!cardPages[activePageIndex]?.images[0]) {
								prev[pageIndexForDownloadUrl].images[0] = {
									...defaultBackgroundProps,
									downloadUrl: smartCroppedImage,
								};
							}
							if (!!cardPages[activePageIndex].images[0] && cardPages[activePageIndex].images[0]?.name === 'object') {
								prev[pageIndexForDownloadUrl].images.splice(0, 0, {
									...defaultBackgroundProps,
									downloadUrl: smartCroppedImage,
								});
							} else {
								prev[pageIndexForDownloadUrl].images[0] = {
									...defaultBackgroundProps,
									downloadUrl: smartCroppedImage,
								};
							}
						} else {
							const displayDimensions = {
								width: stageDimensions.width * 0.75,
								height: stageDimensions.height * 0.75,
							};
							let imageWidth = displayDimensions.width,
								imageHeight = displayDimensions.height;
							let imageX = 0,
								imageY = 0;
							let ratio = value.width / displayDimensions.width;
							if (value.width < displayDimensions.width) {
								imageWidth = value.width;
								ratio = 1;
							}
							imageHeight = value.height / ratio;
							imageX = (stageDimensions.width - imageWidth) / 2;
							imageY = (stageDimensions.height - imageHeight) / 2;

							prev[pageIndexForDownloadUrl].images.push({
								draggable: true,
								name: 'object',
								id: imageId,
								addedby,
								downloadUrl,
								x: imageX,
								y: imageY,
								width: imageWidth,
								height: imageHeight,
							});

							setSelectedShapeName({
								shape_id: imageId,
								shapeType: { element: 'image', type: null },
							});
						}
					});
				} else {
					throw new Error('There was a problem while uploading image.');
				}
			} catch (error: any) {
				toast.error(error.message, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					pauseOnFocusLoss: false,
				});
			}
		}
		setRefetch(true);
	};

	const handleAddGif = (src: string, width: number, height: number) => {
		const pageIndexForDownloadUrl = activePageIndex;
		// let downloadUrl = "https://media.giphy.com/media/vVzH2XY3Y0Ar6/giphy.gif";
		let gifId: any = new Date().getTime();
		gifId = 'gifs_' + gifId.toString();
		// `addedby` will be undefined in case of collaborator because we do not save firebase Id
		let addedby = userProfile._id;

		setCardPagesX((prev: any) => {
			const displayDimensions = {
				width: stageDimensions.width * 0.75,
				height: stageDimensions.height * 0.75,
			};
			let gifWidth = displayDimensions.width,
				gifHeight = displayDimensions.height;
			let gifX = 0,
				gifY = 0;
			let ratio = width / displayDimensions.width;
			if (width < displayDimensions.width) {
				gifWidth = width;
				ratio = 1;
			}
			gifHeight = height / ratio;
			gifX = (stageDimensions.width - gifWidth) / 2;
			gifY = (stageDimensions.height - gifHeight) / 2;

			const updatedGif = {
				draggable: true,
				name: 'object',
				id: gifId,
				addedby,
				src,
				x: gifX,
				y: gifY,
				width: gifWidth,
				height: gifHeight,
			};

			if (prev[pageIndexForDownloadUrl]?.gifs) {
				prev[pageIndexForDownloadUrl].gifs.push(updatedGif);
			} else {
				prev[activePageIndex] = {
					...prev[activePageIndex],
					gifs: [updatedGif],
				};
			}

			setSelectedShapeName({
				shape_id: gifId,
				shapeType: { element: 'gif', type: null },
			});
		});
	};
	const handleUserImageUploads = (downloadUrl: string, imgWidth: number, imgHeight: number) => {
		const pageIndexForDownloadUrl = activePageIndex;
		let imageId: any = new Date().getTime();
		imageId = 'images_' + imageId.toString();
		// `addedby` will be undefined in case of collaborator because we do not save firebase Id
		let addedby = userProfile._id;

		setCardPagesX((prev: any) => {
			if (isBackgroundImage()) {
				const smartCroppedImage = `https://image.cardclan.io/api/imageproxy/320x458,sc/${downloadUrl}`;
				if (!cardPages[activePageIndex]?.images[0]) {
					prev[pageIndexForDownloadUrl].images[0] = {
						...defaultBackgroundProps,
						downloadUrl: smartCroppedImage,
					};
				}
				if (!!cardPages[activePageIndex].images[0] && cardPages[activePageIndex].images[0]?.name === 'object') {
					prev[pageIndexForDownloadUrl].images.splice(0, 0, {
						...defaultBackgroundProps,
						downloadUrl: smartCroppedImage,
					});
				} else {
					prev[pageIndexForDownloadUrl].images[0] = {
						...defaultBackgroundProps,
						downloadUrl: smartCroppedImage,
					};
				}
			} else {
				const displayDimensions = {
					width: stageDimensions.width * 0.75,
					height: stageDimensions.height * 0.75,
				};
				let imageWidth = displayDimensions.width,
					imageHeight = displayDimensions.height;
				let imageX = 0,
					imageY = 0;
				let ratio = imgWidth / displayDimensions.width;
				if (imgWidth < displayDimensions.width) {
					imageWidth = imgWidth;
					ratio = 1;
				}
				imageHeight = imgHeight / ratio;
				imageX = (stageDimensions.width - imageWidth) / 2;
				imageY = (stageDimensions.height - imageHeight) / 2;

				prev[pageIndexForDownloadUrl].images.push({
					draggable: true,
					name: 'object',
					id: imageId,
					addedby,
					downloadUrl,
					x: imageX,
					y: imageY,
					width: imageWidth,
					height: imageHeight,
				});

				setSelectedShapeName({
					shape_id: imageId,
					shapeType: { element: 'image', type: null },
				});
			}
		});
	};

	/**
	 *
	 * This function is used to add image from user device on stage with defaults styling.
	 */
	const handleImageUpload = (e: any) => {
		const img = e.target.files[0];
		const size = img?.size;
		resizeAndUpload({ size, file: img });
		// handleImage({ file: img })
	};
	/**
	 *
	 * This function is used to resize user device upload images or image collection images before adding it on stage.
	 */
	const resizeAndUpload = ({ size, file }: { size: number; file: any }) => {
		if (size > 2097152) {
			swal('Image too large!', 'Please make sure that image size is less than 2MB', 'error');
			return;
		}
		if (file) {
			const stageDims = { ...stageDimensions };
			Resizer.imageFileResizer(
				file,
				stageDims.width,
				stageDims.height,
				'png',
				100,
				0,
				(uri: any, width: number, height: number) => handleImage({ file, name: uri, width, height }, width, height),
				'base64'
			);
		} else {
			swal(I18n.t('not_a_valid_file'));
		}
	};
	/**
	 *
	 * This function is used to add image from Image collection like pexels, unsplash, pixabay on stage with defaults styling.
	 */
	const handleExternalImage = async (url: any) => {
		let file = await fetch(url)
			.then((r) => r.blob())
			.then((blobFile) => new File([blobFile], 'canva_image', { type: 'image/png' }))
			.catch((err) => console.log(err));
		resizeAndUpload({ size: 100, file: file });
	};
	/**
	 *
	 * This function is used to delete selected element from the stage.
	 */
	const handleDeleteSelectedItem = ({ selectedShapeName }: { selectedShapeName: string | null }) => {
		if (selectedShapeName) {
			const shapeType = selectedShapeName?.split('_')[0];
			setCardPagesX((prev: any) => {
				prev[activePageIndex][shapeType] = cardPages[activePageIndex][shapeType]?.filter(
					(item: any) => item.id !== selectedShapeName
				);
			});
			unSelect();
		}
	};
	const handleDeleteBackgroundImage = () => {
		setCardPagesX((prev: any) => {
			prev[activePageIndex].images = cardPages[activePageIndex].images?.filter(
				(item: any) => item.id !== 'images_background'
			);
		});
		unSelect();
	};

	/**
	 *
	 * This function is used to bring back the selected image relative to other images from the stage.
	 */
	const onBringtoBack = () => {
		setCardPagesX((prev: any) => {
			const imageIndex = prev[activePageIndex].images.findIndex((item: any) => item.id === selectedShapeName);
			if (imageIndex > 0) {
				const temp = prev[activePageIndex].images[imageIndex - 1];
				prev[activePageIndex].images[imageIndex - 1] = prev[activePageIndex].images[imageIndex];
				prev[activePageIndex].images[imageIndex] = temp;
			} else {
			}
		});
	};
	/**
	 *
	 * This function is used to bring back to front the selected image relative to other images from the stage.
	 */
	const onBringtoFront = () => {
		setCardPagesX((prev: any) => {
			const imageIndex = prev[activePageIndex].images.findIndex((item: any) => item.id === selectedShapeName);
			if (imageIndex < prev[activePageIndex].images.length - 1) {
				const temp = prev[activePageIndex].images[imageIndex + 1];
				prev[activePageIndex].images[imageIndex + 1] = prev[activePageIndex].images[imageIndex];
				prev[activePageIndex].images[imageIndex] = temp;
			} else {
			}
		});
	};
	/**
	 *
	 * This function is used to change and save element modifications.
	 */

	const handleElementChanges = (e: any) => {
		let rectAttrs = JSON.parse(JSON.stringify(e.target.attrs));
		const { image, ...rect } = rectAttrs;
		const shapeType: any = rect?.id?.split('_')[0];
		setCardPagesX((prev: any) => {
			prev[activePageIndex][shapeType]?.find((item: any, index: any) => {
				if (item?.id === rect?.id) {
					prev[activePageIndex][shapeType][index] = {
						...prev[activePageIndex][shapeType][index],
						...rect,
					};
					return true;
				}
				return false;
			});
		});
	};
	const handleFitToPage = () => {
		const stageDimension: any = { ...stageDimensions };
		setCardPagesX((prev: any) => {
			prev[activePageIndex].images.find((item: any, index: any) => {
				if (item.id === selectedShapeName) {
					const isFitToPage = !!selectedShapeData.fitToPage?.status;
					console.log({ isFitToPageisFitToPageisFitToPage: isFitToPage });
					let fitToPage = {
						status: !isFitToPage,
						originalAttrs: {
							x: !!isFitToPage ? selectedShapeData.fitToPage.originalAttrs.x : selectedShapeData.x,
							y: !!isFitToPage ? selectedShapeData.fitToPage.originalAttrs.y : selectedShapeData.y,
							width: !!isFitToPage ? selectedShapeData.fitToPage.originalAttrs.width : selectedShapeData.width,
							height: !!isFitToPage ? selectedShapeData.fitToPage.originalAttrs.height : selectedShapeData.height,
						},
					};

					const updatedShape = {
						...selectedShapeData,
						fitToPage,
						scaleX: 1,
						scaleY: 1,
						x: !!isFitToPage ? selectedShapeData.fitToPage?.originalAttrs.x : 0,
						y: !!isFitToPage ? selectedShapeData.fitToPage?.originalAttrs.y : 0,
						width: !!isFitToPage ? selectedShapeData.fitToPage?.originalAttrs.width : stageDimension.width,
						height: !!isFitToPage ? selectedShapeData.fitToPage?.originalAttrs.height : stageDimension.height,
						rotation: 0,
					};
					prev[activePageIndex].images[index] = updatedShape;
					return true;
				}
				return false;
			});
		}, false);
	};
	const resetDesignToolState = () => {
		resetStateMachine();
	};

	const resetCardPages = () => {
		setCardPagesX([{ textBoxes: [], images: [] }]);
	};

	return {
		isBackgroundImage,
		handleImage,
		handleAddGif,
		handleText,
		handleTextPaste,
		handleAddCta,
		handleTextProps,
		handleCtaProps,
		handleImageUpload,
		handleExternalImage,
		handleUserImageUploads,
		getSelectedElementData,
		handleDeleteSelectedItem,
		onBringtoBack,
		onBringtoFront,
		handleElementChanges,
		handleFitToPage,
		handleTextEffect,
		resetDesignToolState,
		resetCardPages,
		handleDeleteBackgroundImage,
		handleAddBackgroundColor,
	};
};

export default useCardToolFunctions;
