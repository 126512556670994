export const ROUTE_NAMES = {
	landing: '/',
	login: '/auth/login', //Login | APP_NAME
	register: '/auth/register', //Register | APP_NAME
	registermember: '/auth/registermember', // Add team member | APP_NAME
	appSumoLogin: '/auth/appsumologin', // Welcome Sumolings | APP_NAME
	forgotpassword: '/auth/forgotpassword', // Forgot Password | APP_NAME
	selectdesign: '/app/selectdesign', // Select Cover Design | APP_NAME
	createcard: '/createcard', // Create card | APP_NAME
	howitworks: '/howitworks',
	emailverified: '/emailverified', // Email Verified | APP_NAME
	scheduledelivery: '/schedulecard/scheduledelivery', // Schedule Card | APP_NAME
	addRecipient: '/schedulecard/addRecipient',
	createWorkflow: '/createWorkflow',
	senderdetail: '/schedulecard/senderdetail',
	cardScheduled: '/schedulecard/cardScheduled',
	cardSchedule: '/schedulecard',
	sharecard: '/schedulecard/sharecard', // Share Card | APP_NAME
	success: '/schedulecard/success', // Card Scheduled | APP_NAME
	pricing: '/pricing', // Pricing | APP_NAME
	onboarding: '/onboarding', // Onboarding | APP_NAME
	dashboard: '/app/dashboard', // Dashboard | APP_NAME
	mycards: '/app/mycards', // Manage Cards | APP_NAME
	activities: '/app/activities',
	templates: '/app/templates',
	settings: '/app/settings', // Settings | APP_NAME
	profile: '/app/settings/profile', // Manage Profile | APP_NAME
	billing: '/app/settings/billing', // Billing | APP_NAME
	emailaccounts: '/app/settings/emailaccounts', // Manage Email Accounts | APP_NAME
	manageworkspace: '/app/settings/manageworkspace', //Manage Workspace | APP_NAME
	generatezapierkey: '/app/settings/generatezapierkey', //Manage Workspace | APP_NAME
	refer: '/app/settings/refer', //Refer | APP_NAME
	preview: '/app/settings/preview', //Refer | APP_NAME
	workspacesettings: '/app/settings/workspacesettings', //Workspace Settings | APP_NAME
	whitelabelcustomsettings: '/app/settings/whitelabelcustomsettings', // Custom Domain Settings | APP_NAME
	team: '/app/settings/team', // Manage Team | APP_NAME
	whitelabelbasicsettings: '/app/settings/whitelabelbasicsettings', // White Label Personalization | APP_NAME
	viewCard: '/viewCard/:id', //View Card | APP_NAME
	viewcard: '/viewcard/:id', //View Card | APP_NAME
	cardDelivered: '/cardDelivered', // Card Delivered | APP_NAME
	PageNotFound: '/pagenotfound', // Page not found | APP_NAME
};

export const ROUTE_TITLES: {
	[key: string]: string;
} = {
	landing: 'Home',
	login: 'Login',
	register: 'Register',
	signup: 'Sign up',
	registermember: 'Register',
	appsumologin: 'Welcome Sumolings',
	forgotpassword: 'Forgot password',
	selectdesign: 'Select Cover Design',
	createcard: 'Create card',
	howitworks: 'How it works',
	emailverified: 'Email verified',
	schedulecard: 'Schedule Card',
	scheduledelivery: 'Schedule Card',
	addRecipient: 'Add Recipients',
	senderdetail: 'Sender Detail',
	cardScheduled: 'Card Scheduled',
	createWorkflow: 'Create Workflow',
	sharecard: 'Share Card',
	success: 'Card Scheduled',
	pricing: 'Pricing',
	onboarding: 'Onboarding',
	dashboard: 'Dashboard',
	mycards: 'Manage Cards',
	activities: 'Activities',
	templates: 'Templates',
	settings: 'Settings',
	profile: 'Manage Profile',
	billing: 'Billing',
	generatezapierkey: 'Generate Zapier key',
	refer: 'Refer & Earn',
	preview: 'Preview card setting',
	emailaccounts: 'Manage Email Accounts',
	manageworkspace: 'Manage Workspace',
	workspacesettings: 'Workspace Settings',
	whitelabelcustomsettings: 'Custom Domain Settings',
	team: 'Manage Team',
	whitelabelbasicsettings: 'White Label Personalization',
	viewCard: 'View Card',
	viewcard: 'View Card',
	cardDelivered: 'Card Delivered',
	pagenotfound: 'Page not found',
};
