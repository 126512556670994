import React from 'react';

interface Props {
	text: string;
	textFontSize?: string;
	isPreviewModal?: boolean;
}

const ToolTipSwipeCard: React.FC<Props> = ({ text, textFontSize, isPreviewModal }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				padding: '0px',
				position: 'absolute',
				width: '100%',
				opacity: 0.9,
				left: '0px',
				top: '0px',
				background: '#4F46E5',
				boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
				color: 'white',
				borderRadius: isPreviewModal ? '0.5rem 0.5rem 0rem 0rem' : '0px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '24px',
					position: 'static',
					left: '0px',
					top: '0px',
					flex: 'none',
					order: '0',
					alignSelf: 'stretch',
					flexGrow: '0',
					margin: '0px 0px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						padding: '0px',
						position: 'static',
						left: '24px',
						top: '24px',
						flex: 'none',
						order: '0',
						alignSelf: 'stretch',
						flexGrow: '0',
						margin: '0px 0px',
					}}
				>
					<p
						style={{
							position: 'static',
							left: '0px',
							top: '32px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: textFontSize || '16px',
							lineHeight: '20px',
							color: '#F3F4F6',
							flex: 'none',
							order: '1',
							alignSelf: 'stretch',
							flexGrow: '0',
						}}
					>
						{text}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ToolTipSwipeCard;
