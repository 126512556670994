import React, { useContext } from 'react';
import { CardProspectsContext } from '../../../../../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../../../../../contexts/DesignTool/DesignToolContext';
import UploadCsvModal from './UploadCsvModal';
import './style.css';

const UploadCsvModalContainer: React.FC = () => {
	const { setMergeTags, mergeTags } = useContext(DesignToolContext);
	const { setCsvFile, csvFile, csvModalOpen, setCsvModalOpen, setContactListModalOpen } =
		useContext(CardProspectsContext);

	const handleCloseCsvModal = () => {
		setCsvModalOpen(false);
		setTimeout(() => {
			const html : any = document.body.parentNode as HTMLElement | null;
			html.classList.add('modal_close');
		}, 50);
	};
	const handleFileUpload = (e: any) => {
		if (e?.target?.files?.length) {
			setCsvFile(e.target.files[0]);
		}
	};

	const handleDeleteFile = () => {
		setCsvFile(null);
	};

	const handleOpenContactList = () => {
		setMergeTags(mergeTags.map((item) => ({ ...item, colName: 'Select Value' })));
		setContactListModalOpen(true);
		setCsvModalOpen(false);
    setTimeout(() => {
			const html : any = document.body.parentNode as HTMLElement | null;
			html.classList.add('modal_close');
		}, 50);
	};

	return (
		<UploadCsvModal
			open={csvModalOpen}
			csvFile={csvFile}
			handleCloseModal={handleCloseCsvModal}
			handleFileUpload={handleFileUpload}
			handleDeleteFile={handleDeleteFile}
			handleOpenContactList={handleOpenContactList}
		/>
	);
};

export default UploadCsvModalContainer;
