import React from 'react';
import './style.css';

const uploadImg = 'https://res.cloudinary.com/cardclan-io/image/upload/v1616675350/upload_xtfk5f.png';

interface Props {
	handleImageUpload: (event: any) => void;
}

const ImagePicker: React.FC<Props> = ({ handleImageUpload }) => {
	return (
		<div className="flex flex-col">
			<button
				style={{ height: '206px', width: '146px' }}
				className="rounded-lg shadow-md hover:opacity-80 hover:scale-105 transform transition duration-300"
			>
				<div className="m-auto ">
					<img className="text-grey block m-auto " height={30} width={30} src={uploadImg} alt="logo" />
					<p className=" text-sm text-black">Upload Cover</p>
					<div>
						<p className="text-mineShaft capitalize text-xs">{'( 320x458 pixels )'}</p>
					</div>
					<input
						accept="image/*"
						onChange={handleImageUpload}
						type="file"
						className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer    "
					/>
				</div>
			</button>
		</div>
	);
};

export default ImagePicker;
