import { AxiosRequestConfig } from 'axios';
import { TMergeTag } from '../../contexts/DesignTool/types';
import axios from './axios';
import {
	CreateProspectList,
	ProspectListsInterface,
	ProspectListWithProspectsInterface,
} from './prospectList.interface';

export const prospect_list_services = {
	createProspectList,
	getProspectList,
	getProspectListWithoutProspects,
};

function createProspectList(
	workspaceId: string,
	ownerId: string,
	fileName: string,
	prospectList: Array<any>,
	cardId: string,
	mergeTags: TMergeTag[]
): Promise<CreateProspectList> {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'post',
				url: '/prospectList',
				data: { workspaceId, ownerId, fileName, prospectList, cardId, mergeTags },
			};

			const resp = await axios(config);
			const response = resp.data.data;

			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

function getProspectList(
	workspaceId: string,
	fileName: string,
	limit: number,
	skip: number,
	cardId: string,
	searchValue: string
): Promise<ProspectListWithProspectsInterface> {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'get',
				url: `/prospectList/${fileName}`,
				params: {
					workspaceId,
					skip,
					limit,
					cardId,
					searchValue,
				},
			};
			const resp = await axios(config);
			const response = resp.data.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

function getProspectListWithoutProspects(workspaceId: string): Promise<ProspectListsInterface[]> {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'get',
				url: `/prospectList`,
				params: {
					workspaceId,
					prospects: false,
				},
			};
			const resp = await axios(config);
			const response = resp?.data?.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}
