import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { CardProspectsContext } from '../../../contexts/CardProspects/CardProspectsContext';
import { EmailAccountContext } from '../../../contexts/CardProspects/emailAccountContext';
import { FromNameContext } from '../../../contexts/CardProspects/fromName';
import { DesignToolContext } from '../../../contexts/DesignTool/DesignToolContext';
import useCardToolFunctions from '../../../hooks/useCardToolFunctions';
import useMergeTagsFunctions from '../../../hooks/useMergeTagsFunctions';
import { setCurrentCardData, setCurrentCardId } from '../../../redux/actions';
import { ROUTE_NAMES } from '../../../Routes/routeNames';
import { billing_limit_services } from '../../../services/request/billingLimits';
import { BillingLimits } from '../../../services/request/billingLimits.interface';
import { card_services } from '../../../services/request/card';
import { card_prospect_services } from '../../../services/request/cardProspect';
import { addStringInString } from '../../../utils/stringUtils';
import CardScheduled from './CardScheduled';
import { workspace_services } from '../../../services/request/workspace';
declare const window: any;
interface Props {
	title: string;
}
const CardScheduledContainer: React.FC<Props> = ({ title }) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [ctaLink, setCTALink] = useState<string>('www.example.com');
	const [ctaText, setCTAText] = useState<string>('Click here');
	const inputRef: any = useRef(null);
	const card = useSelector((state: any) => state.card);
	const navigate = useNavigate();
	const [initialLoading, setInitialLoading] = useState(true);
	const [recipientListLoading, setRecipientListLoading] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [skip, setSkip] = useState(0);
	const [saveCardLoading, setSaveCardLoading] = useState(false);
	const [billingLimits, setBillingLimits] = useState<BillingLimits | null>(null);
	const [updateLoading, setUpdateLoading] = useState(false);
	const { prospectLists, selectedProspectList, mergeTags, setShowConfetti, setSelectedCardId } =
		useContext(DesignToolContext);
	const { setCardScheduledProspectData } = useContext(CardProspectsContext);
	const {
		setFromName,
		fromName,
		setSubjectLine,
		subjectLine,
		cardPreviewHeader,
		setCardPreviewHeader,
		cardPreviewFooter,
		setCardPreviewFooter,
		viewCardButtonColor,
		setViewCardButtonColor,
	} = useContext(FromNameContext);
	const { selectedEmailAccount } = useContext(EmailAccountContext);
	const { resetCardPages, resetDesignToolState } = useCardToolFunctions();
	const { resetMergeTagsState } = useMergeTagsFunctions();
	const dispatch = useDispatch();
	const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
	const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
	const [inputFieldOpenAndClose, setInputField] = useState(false);
	const [openConfetti, setOpenConfetti] = useState(false);
	const [isCTA, setCTA] = useState(false);
	const [isConfettiOn, setConfetti] = useState(true);
	const fetchBillingLimits = useCallback(() => {
		const prospectlistId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
		if (prospectlistId) {
			billing_limit_services.getBillingLimits(currentWorkspace?.owner_id).then((res) => {
				setBillingLimits(res);
			});
		} else {
			console.log('No prospect list selected');
		}
	}, [currentWorkspace?.owner_id, prospectLists, selectedProspectList]);
	useLayoutEffect(() => {
		fetchBillingLimits();
	}, [fetchBillingLimits]);

	useEffect(() => {
		if (card.currentCardId) {
			setRecipientListLoading(false);
			handleLoadMore(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [card.currentCardId, title]);

	const handleSearchRecipients = (value: string) => {
		setSearchValue(value.trim());
	};

	const onBackHandler = () => {
		navigate(ROUTE_NAMES.senderdetail);
	};

	const handleScheduleCard = async () => {
		setSaveCardLoading(true);
		let currentCardData = card.currentCardData;
		currentCardData.subjectLine = subjectLine;
		currentCardData.emailPreviewData = {
			cardPreviewHeader: cardPreviewHeader,
			cardPreviewFooter: cardPreviewFooter,
			viewCardButtonColor: viewCardButtonColor,
			isCTA: isCTA,
			isConfettiOn: isConfettiOn,
			ctaLink: ctaLink,
			ctaText: ctaText,
		};
		try {
			await card_services.updateCardById(currentCardData);
		} catch (error) {
			console.log(' Card Updation failed ', error);
		}
		if (!currentWorkspace?.previewSetting) {
			try {
				await workspace_services.updateWorkspace({
					...currentWorkspace,
					previewSetting: {
						solid: false,
						confetti: true,
						buttonRounded: true,
						buttonColor: '#FF3C69',
						cardClanLogo: true,
						buttonText: 'View card',
						solidColor: '#fafafa',
						startColor: '#1D468B',
						endColor: '#9DF292',
						companyLogo:
							'https://res.cloudinary.com/interstellus/image/upload/v1675421128/Cardclan-Logo-final_t6l4ie.png',
					},
				});
			} catch (error) {
				console.log('@jawad  error:', error);
			}
		}
		let CardPages = card?.currentCardData?.cardPages.length;
		let Images = 0;
		let Ctas = 0;
		let Gifs = 0;
		let Textbox = 0;
		let MergeTagName = [];
		let MergeTags = card?.currentCardData?.mergeTags?.length;
		for (let i = 0; i < CardPages; i++) {
			Images = Images + card?.currentCardData?.cardPages[i]?.images?.length;
			Ctas = Ctas + card?.currentCardData?.cardPages[i]?.ctas?.length;
			Gifs = Gifs + card?.currentCardData?.cardPages[i]?.gifs?.length;
			Textbox = Textbox + card?.currentCardData?.cardPages[i]?.textBoxes?.length;
		}
		for (let i = 0; i < MergeTags; i++) {
			MergeTagName[i] = card?.currentCardData?.mergeTags[i]?.tagName;
		}
		if (!whiteLabelSettings) {
			if (window.analytics) {
				window.analytics.track('Card Scheduled', {
					Images,
					CardPages,
					Ctas,
					Gifs,
					Textbox,
					MergeTags,
				});
			}
			if (window.hyperengage) {
				window.hyperengage('track', 'Card Scheduled', {
					properties: {
						Images: Images,
						CardPages: CardPages,
						Ctas: Ctas,
						Gifs: Gifs,
						Textbox: MergeTags,
					},
				});
			}
		}
		setSelectedCardId('');
		try {
			const prospectlistId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
			if (prospectlistId) {
				const updatedCardData = {
					...card.currentCardData,
					deliveryInfo: {
						selectedEmailAccount: selectedEmailAccount,
						fromName: fromName ? fromName : selectedEmailAccount?.fromName,
					},
					hostName: window.location.hostname,
				};
				const response = await card_services.scheduleCard(card.currentCardId, updatedCardData, prospectlistId);
				setShowConfetti(true);
				if (response) {
					swal({
						icon: 'success',
						title: 'Card Scheduled!',
					}).then(() => {
						dispatch(setCurrentCardData(null));
						dispatch(setCurrentCardId(null));
						resetCardPages();
						resetDesignToolState();
						resetMergeTagsState();
						navigate(ROUTE_NAMES.dashboard);
						setShowConfetti(false);
					});
					setSubjectLine('');
				} else {
					toast.error('There was a problem while saving your card. Please try again.', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						pauseOnFocusLoss: false,
					});
				}
			} else {
				console.log('No prospect list found.');
			}
		} catch (error: any) {
			console.log({ error });
			toast.error(error.message, {
				position: 'top-right',
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				pauseOnFocusLoss: false,
			});
			setSaveCardLoading(false);
		}

		setOpenConfetti(true);
	};

	const handleLoadMore = (loadMore: boolean, searchTerm?: string) => {
		if (recipientListLoading) return;
		setRecipientListLoading(true);
		const prospectlistId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
		if (prospectlistId) {
			card_prospect_services
				.getCardProspects(card.currentCardId, prospectlistId, loadMore ? skip : 0, 10, searchTerm || '', true)
				.then((response) => {
					if (loadMore) {
						setCardScheduledProspectData((prev) => {
							if (prev) {
								prev.cardProspects = [...prev.cardProspects, ...response.cardProspects];
							}
						});
					} else {
						setCardScheduledProspectData(response);
					}
					setSkip((prev) => prev + 10);
					setHasNextPage(response.hasNextEntries);
				})
				.catch((err) => console.log({ err }))
				.then(() => {
					setRecipientListLoading(false);
					initialLoading && setInitialLoading(false);
				});
		} else {
			console.log('No prospect list selected');
		}
	};

	const onKeyDown = (e: any) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setSkip(0);
			setCardScheduledProspectData((prev) => {
				prev.cardProspects = [];
			});
			handleLoadMore(false, searchValue);
		}
		if (e.key === 'Backspace' && searchValue === '') {
			e.preventDefault();
			setSkip(0);
			setCardScheduledProspectData((prev) => {
				prev.cardProspects = [];
			});
			handleLoadMore(false);
		}
	};

	const onLoadMore = () => {
		handleLoadMore(true);
	};
	const handleInputFeildClick = () => {
		setInputField(!inputFieldOpenAndClose);
	};

	const [infiniteRef] = useInfiniteScroll({
		loading: recipientListLoading,
		hasNextPage: hasNextPage,
		onLoadMore,
	});

	const handleAddMergeTag = (mergeTag: string) => {
		const sliceInd = inputRef.current?.selectionStart;
		let updatedText: string;
		if (sliceInd) {
			updatedText = addStringInString(subjectLine, sliceInd, `{{${mergeTag}}}`);
		} else {
			updatedText = `{{${mergeTag}}}${subjectLine || ''}`;
		}
		setSubjectLine(updatedText);
		inputRef.current?.focus();
	};

	return (
		<CardScheduled
			handleSearchRecipients={handleSearchRecipients}
			onBackHandler={onBackHandler}
			infiniteRef={infiniteRef}
			inputFieldOpenAndClose={inputFieldOpenAndClose}
			handleInputFeildClick={handleInputFeildClick}
			recipientListLoading={recipientListLoading}
			initialLoading={initialLoading}
			handleScheduleCard={handleScheduleCard}
			saveCardLoading={saveCardLoading}
			billingLimits={billingLimits}
			setFromName={setFromName}
			setSubjectLine={setSubjectLine}
			fromName={fromName}
			subjectLine={subjectLine}
			cardPreviewHeader={cardPreviewHeader}
			setCardPreviewHeader={setCardPreviewHeader}
			cardPreviewFooter={cardPreviewFooter}
			setCardPreviewFooter={setCardPreviewFooter}
			viewCardButtonColor={viewCardButtonColor}
			setViewCardButtonColor={setViewCardButtonColor}
			selectedEmailAccount={selectedEmailAccount}
			fetchProspectsAndProspectlist={() => handleLoadMore(false)}
			setUpdateLoading={setUpdateLoading}
			updateLoading={updateLoading}
			onKeyDown={onKeyDown}
			mergeTags={mergeTags}
			handleAddMergeTag={handleAddMergeTag}
			inputRef={inputRef}
			openConfetti={openConfetti}
			card={card}
			setCTA={setCTA}
			isCta={isCTA}
			setCTALink={setCTALink}
			ctaLink={ctaLink}
			setCTAText={setCTAText}
			ctaText={ctaText}
			currentWorkspace={currentWorkspace}
			isConfettiOn={isConfettiOn}
			setConfetti={setConfetti}
		/>
	);
};

export default CardScheduledContainer;
