import React, { useContext } from 'react';
import { useState } from 'react';
import CustomLoader from '../../../components/CustomLoader';
import Modal from '../../../components/Modal';
import NewModal from '../../../components/NewModal';
import { defaultDesignToolState } from '../../../contexts/DesignTool/defaultStates';
import { DesignToolContext } from '../../../contexts/DesignTool/DesignToolContext';
import { TemplateInterface } from '../../../services/request/template.interface';
import { CloseIcon_SVG } from '../../../utils/defaults';
import useSvg from '../../../utils/useSvg';
import ViewCardLayout from '../../ViewCardLayout';
import PreBuiltTemplates from '../Dashboards/Components/PreBuiltTemplates';
import SelectTemplate from '../Dashboards/Components/SelectTemplate';

const svgPath = '<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />';
const TemplatesPriBuilt = () => {
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateInterface | null>(null);
	const { previewCardOptions, setPreviewCardOptions } = useContext(DesignToolContext);
	const [selectTemplate, setSelectTemplate] = useState(false);
	const closeIcon = useSvg('http://www.w3.org/2000/svg', '0 0 24 24', 'white', '#808080', svgPath);
	const handleClosePreviewModal = () => {
		setSelectedTemplate(null);
		setPreviewCardOptions(defaultDesignToolState.previewCardOptions);
	};
	const handleClose = () => {
		setSelectTemplate(false);
	};

	return (
		<>
			<div className="flex flex-col ml-8 mr-16 gap-y-4 p-6">
				<PreBuiltTemplates setSelectedTemplate={setSelectedTemplate} />
			</div>
			<NewModal
				open={selectTemplate}
				closeIcon={closeIcon}
				onClose={handleClose}
				className="w-5/6 relative rounded-lg "
			>
				<SelectTemplate handleClose={handleClose} />
			</NewModal>
			<Modal
				closeIcon={CloseIcon_SVG}
				className={
					previewCardOptions.modalOpen
						? ' md:w-5/6 md:h-5/6 h-full  m-2 z-50 relative w-full flex flex-wrap justify-center md:mx-0 bg-mediumIndigo blur-bg'
						: 'hidden'
				}
				open={previewCardOptions.modalOpen}
				onClose={handleClosePreviewModal}
				iconClassName="w-7 bg-mediumIndigo"
			>
				<React.Suspense fallback={<CustomLoader />}>
					<ViewCardLayout selectedTemplate={selectedTemplate} isPreviewModal={true} />
				</React.Suspense>
			</Modal>
		</>
	);
};

export default TemplatesPriBuilt;
