import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { UserPlanInterface } from './userPlan.interface';

export const user_plan_services = {
	getUserPlan,
};

function getUserPlan(userId: string): Promise<UserPlanInterface> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/user-plan',
				params: { userId },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('getUserPlan error: ', error);
			reject(error);
		}
	});
}
