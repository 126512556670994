import { useImmerState } from '@shrugsy/use-immer-state';
import React, { createContext, useState } from 'react';
import { cardProspectsDefault } from './defaults';
import { CardProspectsData, ICardProspects } from './interfaces';

export const CardProspectsContext = createContext<ICardProspects>(cardProspectsDefault);

export const CardProspectsProvider: React.FC = ({ children }) => {
	const [cardProspectsData, setCardProspectsData] = useImmerState<CardProspectsData>(
		cardProspectsDefault.cardProspectsData
	);

	const [csvFile, setCsvFile] = useState<any>(cardProspectsDefault.csvFile);

	const [csvModalOpen, setCsvModalOpen] = useImmerState<boolean>(cardProspectsDefault.csvModalOpen);

	const [contactListModalOpen, setContactListModalOpen] = useImmerState<boolean>(false);

	const [csvUploadFinalized, setCsvUploadFinalized] = useImmerState<boolean>(false);

	const [cardScheduledProspectData, setCardScheduledProspectData] = useImmerState<CardProspectsData>(
		cardProspectsDefault.cardProspectsData
	);

	return (
		<CardProspectsContext.Provider
			value={{
				cardProspectsData,
				setCardProspectsData,
				csvFile,
				setCsvFile,
				csvModalOpen,
				setCsvModalOpen,
				contactListModalOpen,
				setContactListModalOpen,
				csvUploadFinalized,
				setCsvUploadFinalized,
				setCardScheduledProspectData,
				cardScheduledProspectData,
			}}
		>
			{children}
		</CardProspectsContext.Provider>
	);
};
