import { Dialog } from '@headlessui/react';
import React from 'react';
import HeadlessModal from '../../../../../../../components/HeadlessModal/HeadlessModal';
import LoadingButton from '../../../../../../../components/LoadingButton';
import CsvUploadedContainer from './CsvUploaded/CsvUploadedContainer';
import UploadButtonContainer from './UploadButton/UploadButtonContainer';
import useWhitelabelSettings from '../../../../../../../hooks/useWhitelabelSettings';

interface Props {
	open: boolean;
	csvFile: File | null;
	handleCloseModal: () => void;
	handleFileUpload: (e: any) => void;
	handleDeleteFile: () => void;
	handleOpenContactList: () => void;
}

const UploadCsvModal: React.FC<Props> = ({
	open,
	csvFile,
	handleCloseModal,
	handleFileUpload,
	handleDeleteFile,
	handleOpenContactList,
}) => {
	const { isWhiteLabel, whiteLabelSettings } = useWhitelabelSettings();
	return (
		<HeadlessModal open={open} handleCloseModal={handleCloseModal}>
			<div>
				<div className="mt-3 text-center sm:mt-5">
					<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-blackText">
						Upload your CSV file here
					</Dialog.Title>
					<div className="mt-2">
						<p className="text-sm text-mediumGray">
							{`Once you upload your CSV, ${isWhiteLabel ? whiteLabelSettings?.app_title : 'CardClan'} will map your data with the available merge tags along with custom tags
							for you to personalize each card and send cards to as many contacts you want`}
						</p>
					</div>
				</div>
			</div>
			<div className="mt-4">
				<div className={csvFile ? '' : 'hidden'}>
					<CsvUploadedContainer
						csvFile={csvFile}
						handleFileUpload={handleFileUpload}
						handleDeleteFile={handleDeleteFile}
					/>
				</div>
				<div className={csvFile ? 'hidden' : ''}>
					<UploadButtonContainer handleFileUpload={handleFileUpload} />
				</div>
			</div>
			<div className="flex justify-end mt-4 space-x-2">
				<LoadingButton
					className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo"
					onClick={handleCloseModal}
				>
					Cancel
				</LoadingButton>
				<LoadingButton
					disabled={!csvFile}
					className={
						csvFile
							? 'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo hover:bg-indigobtnhovr focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo'
							: 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo400 cursor-not-allowed'
					}
					onClick={handleOpenContactList}
				>
					Next
				</LoadingButton>
			</div>
		</HeadlessModal>
	);
};

export default UploadCsvModal;
