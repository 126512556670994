import { initializeApp } from 'firebase/app';
import { Timestamp } from '@firebase/firestore';
import {
	EmailAuthProvider,
	getAuth,
	updatePassword,
	reauthenticateWithCredential,
	onAuthStateChanged,
	onIdTokenChanged,
	getIdToken,
} from '@firebase/auth';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { doc, getFirestore, onSnapshot } from '@firebase/firestore';

const firebaseApp = initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export {
	Timestamp,
	EmailAuthProvider,
	getAuth,
	reauthenticateWithCredential,
	getDatabase,
	ref,
	onValue,
	off,
	doc,
	getFirestore,
	onSnapshot,
	updatePassword,
	onAuthStateChanged,
	onIdTokenChanged,
	getIdToken,
};
export default firebaseApp;
