import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Fab, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { ChatIcon, DocumentIcon, LightBulbIcon } from '@heroicons/react/outline';
import { Crisp } from 'crisp-sdk-web';

const ChatBox = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    Crisp.chat.hide();
  };
  console.log('here');
  const ref = React.useRef<any>(null);
  return (
    <div ref={ref} className='fixed right-12 bottom-[40px] z-50'>
      <Fab
        color='primary'
        size='medium'
      >
        {open ? <XCircleIcon className='hover:text-blue-200' onClick={handleClose} /> : <QuestionMarkCircleIcon className='hover:text-blue-200' onClick={handleClick} />}
      </Fab>
      {open && <div
        className='fixed bg-white border-2 shadow-md rounded-lg right-[80px] bottom-[100px] z-50'
        id="basic-menu"
      >
      <MenuList>
        <MenuItem onClick={() => {
          window.open('https://app.loopedin.io/cardclan#/ideas', '_blank');
          setOpen(false);
        }}>
          <ListItemIcon>
            <LightBulbIcon className='w-6 h-6' />
          </ListItemIcon>
          <ListItemText>Request a Feature</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          window.open('https://cardclan.helpscoutdocs.com/', '_blank');
          setOpen(false);
        }}>
          <ListItemIcon>
            <DocumentIcon className='w-6 h-6' />
          </ListItemIcon>
          <ListItemText>Help Docs</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          Crisp.chat.show();
          Crisp.chat.open();
        }}>
          <ListItemIcon>
          <ChatIcon className='w-6 h-6' />
          </ListItemIcon>
          <ListItemText>Contact Support</ListItemText>
        </MenuItem>
       </MenuList>
      </div>}
    </div>
  );
};

export default ChatBox;
