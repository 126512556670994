import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

interface Props {
	imageObj: any;
	ind: number;
}

const ViewImage: React.FC<Props> = ({ imageObj, ind }) => {
	const [image] = useImage(imageObj.imageUrl || imageObj.downloadUrl);

	return (
		<Image
			key={ind}
			listening={false}
			perfectDrawEnabled={false}
			image={image}
			width={imageObj.width}
			height={imageObj.height}
			rotation={imageObj.rotation}
			scaleX={imageObj.scaleX}
			scaleY={imageObj.scaleY}
			skewX={imageObj.skewX}
			skewY={imageObj.skewY}
			x={imageObj.x}
			y={imageObj.y}
			ref={(ref) => ref?.listening(false)}
		/>
	);
};

export default React.memo(ViewImage);
