import React, { memo } from 'react';
const CustomLoader = ({ isLoading, className, spinnerClassName = 'h-10 w-10', height = '100%' }) => {
	return (
		<>
			<div
				className={isLoading ? `flex justify-center items-center ${className}` : 'hidden'}
				style={{ height: height }}
			>
				<img width="30" src="https://s3.us-east-2.amazonaws.com/cardclan.io-assets/loader-cardclan-nm.svg" alt=" " />
			</div>
		</>
	);
};
export default memo(CustomLoader);
