import React, { useRef } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import clamp from 'lodash.clamp';
import './styles.css';
import { stageDimensions } from '../../utils/defaults';

interface Props {
	pages: (() => JSX.Element)[];
	hideTooltip: () => void;
}

const Viewpager: React.FC<Props> = ({ pages, hideTooltip }) => {
	const index = useRef(0);
	const width = window.innerWidth;

	const [props, api] = useSprings(pages.length, (i) => ({
		x: i * width,
		scale: 1,
		display: 'block',
	}));
	const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
		hideTooltip();
		if (active && Math.abs(mx) > 20) {
			index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 1);
			cancel();
		}
		api.start((i) => {
			if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
			const x = (i - index.current) * width + (active ? mx : 0);
			const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
			return { x, scale, display: 'block' };
		});
	});
	return (
		<div className="overflow-hidden h-screen relative" style={{ ...stageDimensions }}>
			{props.map(({ x, display, scale }, i) => (
				<animated.div className="page" {...bind()} key={i} style={{ display, x }}>
					<animated.div style={{ scale }}>{pages[i]()}</animated.div>
				</animated.div>
			))}
		</div>
	);
};

export default Viewpager;
