import React, { useContext } from 'react';
import { DesignToolContext } from '../../../../../../../../contexts/DesignTool/DesignToolContext';
import Title from './Title';

interface Props {
	csvFile: File | null;
	handleFileUpload: (e: any) => void;
}

const TitleContainer: React.FC<Props> = ({ csvFile, handleFileUpload }) => {
	const { prospectListEdit, currentFile } = useContext(DesignToolContext);
	return (
		<Title
			csvFile={csvFile}
			handleFileUpload={handleFileUpload}
			currentFile={currentFile}
			prospectListEdit={prospectListEdit}
		/>
	);
};

export default TitleContainer;
