import React from 'react';
import LoadingButton from '../../../../../../../../components/LoadingButton';

interface Props {
	closeEditModalHandler: () => void;
	updateRecipientHandler?: (e: any) => void;
	recipientEdit: boolean;
	recipientOnEdit?: any;
	computeMergeTags: () => Array<any>;
	recipientValueChangeHandler: (inputLabel: string, value: string) => void;
	isRecipientEditLoading: boolean;
}

const UserForm: React.FC<Props> = ({
	updateRecipientHandler,
	recipientOnEdit,
	closeEditModalHandler,
	recipientEdit,
	computeMergeTags,
	recipientValueChangeHandler,
	isRecipientEditLoading,
}) => {
	return (
		<form className="space-y-4" onSubmit={updateRecipientHandler}>
			<div className="text-xs font-normal text-mediumGray"></div>
			<div className="text-xs font-semibold text-mediumGray">BASIC INFO</div>
			<div>
				<label htmlFor="name" className="block text-sm font-medium text-blackTextlight">
					name
				</label>
				<div className="mt-1">
					<input
						id="name"
						name="name"
						type="name"
						autoComplete="current-password"
						placeholder="Name of the recipient"
						onChange={(e) => recipientValueChangeHandler(e.target.name, e.target.value)}
						required
						value={recipientOnEdit ? recipientOnEdit['name'] : ''}
						className="appearance-none block w-full px-3 py-2 border border-bordergray rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					/>
				</div>
			</div>
			<div>
				<label htmlFor="email" className="block text-sm font-medium text-blackTextlight">
					email
				</label>
				<div className="mt-1">
					<input
						id="email"
						name="email"
						onChange={(e) => recipientValueChangeHandler(e.target.name, e.target.value)}
						type="email"
						autoComplete="email"
						placeholder="johndoe@gmail.com"
						pattern="^\S+@\S+\.\S+$"
						required
						title="johndoe@example.com"
						value={recipientOnEdit ? recipientOnEdit['email'] : ''}
						className="appearance-none block w-full px-3 py-2 border border-bordergray rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					/>
				</div>
			</div>
			<div className={computeMergeTags().length ? "text-mediumGray font-semibold text-xs gap-x-1 flex justify-start items-center": "hidden " }>
				<p className="mb-3">MERGE TAGS</p>
			</div>
			{computeMergeTags().map((item: any) => {
				return (
					<div key={item.tagName}>
						<label htmlFor="phoneNumber" className="block text-sm font-medium text-blackTextlight">
							{item.tagName}
						</label>
						<div className="mt-1">
							<input
								id={item.tagName}
								name={item.tagName}
								onChange={(e) => recipientValueChangeHandler(e.target.name, e.target.value)}
								required
								value={recipientOnEdit ? recipientOnEdit[item.tagName] : ''}
								className="appearance-none block w-full px-3 py-2 border border-bordergray rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							/>
						</div>
					</div>
				);
			})}
			<div className="mt-4 flex justify-end gap-2 " >
				<button
					type="button"
					className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-white border border-transparent rounded-md "
					onClick={closeEditModalHandler}
				>
					Cancel
				</button>
				<LoadingButton
					isLoading={isRecipientEditLoading}
					disabled={isRecipientEditLoading}
					type="submit"
					className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo border border-transparent rounded-md "
				>
					{recipientEdit ? 'Update' : 'Add'}
				</LoadingButton>
			</div>
		</form>
	);
};

export default UserForm;
