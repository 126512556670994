import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { TemplateInterface } from './template.interface';

const getAllTemplates = (): Promise<TemplateInterface[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/template',
			};
			const resp = await axios(config);
			const templates = resp.data;
			resolve(templates.data);
		} catch (error: any) {
			reject(error);
		}
	});
};


export const user_templates = {
	getAllTemplates	
};
