import React, { memo } from 'react';
import './style.css';

/* 
	this is a converted component from js to ts
	will be updated with talwind css v3 update
*/
const CustomTextField: React.FC<any> = ({
	onClick,
	startIcon,
	endIcon,
	error,
	helperText,
	className,
	label,
	width,
	requiredInDomainName,
	whiteLable,
	isEndiconVisible,
	...rest
}: any) => {
	return (
		<>
			<div className={`relative ${width}`}>
				<label className="text-blackTextlight text-base " style={{ fontSize: whiteLable ? 14 : '' }}>
					{label}
				</label>
				<div className="relative">
					<input
						id="inputoutline"
						{...rest}
						className={className ? className : 'textFiexld text-base bg-white'}
						style={{
							outline: 'none',
							textIndent: startIcon ? '15px' : '',
							marginTop: requiredInDomainName ? 0 : 5,
							backgroundColor: 'white',
						}}
					/>
					{/* //? start icon */}
					{/* <img src={startIcon} alt="error" width="18" className={startIcon ? ' absolute left-0 top-0 mt-9 ml-2' : "hidden"} /> */}

					{/* //? end Icon */}
					<img
						onClick={onClick}
						draggable={false}
						src={endIcon}
						alt="error"
						width="18"
						className={endIcon ? 'absolute right-0 bottom-3 mr-4  cursor-pointer ' : 'hidden'}
					/>
				</div>
				{/* //? Helper Text */}
				<p className={helperText ? 'text-textFieldOutline text-sm  transition duration-500' : 'hidden'}>
					{helperText}
				</p>
			</div>
		</>
	);
};

export default memo(CustomTextField);
