import { useContext } from 'react';
import { DesignToolContext } from '../contexts/DesignTool/DesignToolContext';
import DesignToolActions from '../contexts/DesignTool/DesignToolActions';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
// import { defaultBackgroundProps } from "../utils/defaults";

const useCardNavigation = () => {
	const {
		designToolState: { activePageIndex, cardCursor },
		cardPages,
		goBack,
		goForward,
		setCardPagesX,
		stepNum,
		history
	} = useContext(DesignToolContext);
	const { setSelectedPageIndex, setCardCursor, setSelectedShapeName } = DesignToolActions();
	const userProfile = useSelector((state: any) => state.userProfile);
	const { page1, page2 } = cardCursor;

	const unSelect = () => {
		setSelectedShapeName({
			shapeType: { element: null, type: null },
			shape_id: null,
		});
	};

	const handleSelectPage = (pageIndex: number) => {
		setSelectedPageIndex(pageIndex);
	};

  const handleCardpageShift = (pageIndex: number , shift:string) => {
    unSelect()
    const newArr = [...cardPages];
    if(shift==='right'){
      [newArr[pageIndex], newArr[pageIndex+1]] = [newArr[pageIndex+1], newArr[pageIndex]];
    }else{
      [newArr[pageIndex], newArr[pageIndex-1]] = [newArr[pageIndex-1], newArr[pageIndex]];
    }
    setCardPagesX(newArr);
  }
	const handleAddNewPage = (indexToInsert: number, pageData?: any) => {
		setCardPagesX((prev: any) => {
			unSelect();

			if (pageData) {
				/* 
					we need to change the addedBy field to new user's uid 
					and give elements new id's so our transformer can differentiate between them
				*/
				// `addedby` will be undefined in case of collaborator because we do not save firebase Id
				const addedby = userProfile._id;
				const clonedPage = {
					textBoxes: pageData?.textBoxes?.map((txtBox: any, txtIndex: number) => {
						const newId = new Date().getTime();
						return {
							...txtBox,
							id: `textBoxes_${newId.toString()}${txtIndex}`,
							addedby,
						};
					}),
					images: pageData?.images?.map((img: any, imgIndex: number) => {
						const newId = new Date().getTime();
						return {
							...img,
							id: `images_${newId.toString()}${imgIndex}`,
							addedby,
						};
					}),
					ctas: pageData?.ctas?.map((cta: any, ctaIndex: number) => {
						const newId = new Date().getTime();
						return {
							...cta,
							id: `ctas_${newId.toString()}${ctaIndex}`,
							addedby,
						};
					}),
					gifs: pageData?.gifs?.map((gif: any, gifIndex: number) => {
						const newId = new Date().getTime();
						return {
							...gif,
							id: `gifs_${newId.toString()}${gifIndex}`,
							addedby,
						};
					}),
					rects: pageData?.rects?.map((rect: any, rectIndex: number) => {
						const newId = new Date().getTime();
						return {
							...rect,
							id: `rects_${newId.toString()}${rectIndex}`,
							addedby,
						};
					}),
				};
				prev.splice(indexToInsert, 0, clonedPage);
			} else {
				prev.splice(indexToInsert, 0, {
					textBoxes: [],
					images: [],
					ctas: [],
					gifs: [],
          rects:[],
				});
			}

			if (page2 === null) {
				setCardCursor({
					...cardCursor,
					page2: page1 + 1,
				});
				setSelectedPageIndex(page1 + 1);
			} else {
				setCardCursor({
					page1: page2,
					page2: page2 + 1,
				});
				setSelectedPageIndex(page2 + 1);
			}
		});
	};


	const handelUndo = () => {
		if (stepNum < 2) return;

		if (history[stepNum].length !== history[stepNum - 1].length) {
			if (history[stepNum].length > history[stepNum - 1].length) {
				// page delete
				goBack()
				if (page2 === (history[stepNum].length - 1)) {
					setCardCursor({
						...cardCursor,
						page2: null,
					});
					setSelectedPageIndex(page1);
				}

			} else {
				// page add
				goBack()
				if (page2 === null) {
					setCardCursor({
						...cardCursor,
						page2: page1 + 1,
					});
					console.log(history)
					setSelectedPageIndex(page1 + 1);
				}
			}
			unSelect();
		}
		else {
			goBack()
			unSelect();
		}
	}


	const handelRedo = () => {

		if (stepNum === history.length - 1) return;
		if (history[stepNum + 1].length !== history[stepNum].length) {

			if (history[stepNum].length < history[stepNum + 1].length) {
				// page delete
				goForward()
				if (page2 === null) {
					setCardCursor({
						...cardCursor,
						page2: page1 + 1,
					});
					setSelectedPageIndex(page1 + 1);
				}



			} else {

				goBack()
				if (page2 === (history[stepNum].length - 1)) {
					setCardCursor({
						...cardCursor,
						page2: null,
					});
					setSelectedPageIndex(page1);
				}

			}

			unSelect();
		}
		else {
			goForward()
			unSelect();
		}
	}

	const handleDeletePage = (indexToRemove: number) => {
		swal({
			title: 'Are you sure?',
			text: 'Do you want to remove page from your card.',
			icon: 'warning',
			buttons: ['No', 'Yes'],
			dangerMode: true,
		}).then((res) => {
			if (!res) return;
			setCardPagesX((prev: any) => {
				if (indexToRemove > 0) {
					unSelect();
					prev.splice(indexToRemove, 1);
					if (indexToRemove === page1) {
						const updatedCursor = {
							// page1: indexToRemove === 1 ? page1 : page1 - 1,
							page1: page1 > 0 ? page1 - 1 : page1,
							// page2: indexToRemove === 1 ? null : prev[page1] ? page1 : null,
							page2: page2 === null ? null : page2 - 1,
						};
						setCardCursor(updatedCursor);
						setSelectedPageIndex(updatedCursor.page1);
					}
					if (indexToRemove === page2) {
						const updatedCursor = {
							// page1: indexToRemove === 1 ? page1 : page1 - 1,
							page1: page1,
							// page2: indexToRemove === 1 ? null : prev[page1] ? page1 : null,
							page2: prev[page1 + 1] ? page1 + 1 : null,
						};
						// console.log("updatedCursor", updatedCursor)
						setCardCursor(updatedCursor);
						setSelectedPageIndex(updatedCursor.page1);
					}
				}
			});
		});
	};

	const handlePrevOrNext = (type: 'prev' | 'next') => {
		unSelect();
		switch (type) {
			case 'prev':
				if (page1 > 0) {
					setSelectedPageIndex(page1 - 1);
					setCardCursor({
						page2: page1,
						page1: page1 - 1,
					});
				}
				break;

			case 'next':
				if (page2 !== null && page2 + 1 === cardPages.length) {
					setSelectedPageIndex(page2);
					setCardCursor({
						page1: page2,
						page2: null,
					});
				}
				if (page2 !== null && page2 + 1 < cardPages.length) {
					setSelectedPageIndex(page2 + 1);
					setCardCursor({
						page1: page2,
						page2: page2 + 1,
					});
				}
				break;

			default:
				break;
		}
	};

	return {
		activePageIndex,
		cardCursor,
		handleAddNewPage,
		handleDeletePage,
		handleSelectPage,
		handlePrevOrNext,
		unSelect,
		handelUndo,
		handelRedo,
    handleCardpageShift
	};
};

export default useCardNavigation;
