import React from 'react';
import { Navigate } from 'react-router-dom';
import LoggedInLayout from '../layouts/LoggedInLayout/index';
import TemplatesPriBuilt from '../layouts/LoggedInLayout/Templates';
//Layouts
import LoggedOutLayout from '../layouts/LoggedOutLayout/index';
import ScheduleCardLayout from '../layouts/ScheduleCardLayout';
import ViewCardLayout from '../layouts/ViewCardLayout';
import componentLoader from '../utils/componentLoader';
//routes
import PrivateRoute from './privateRoute';
import { ROUTE_NAMES } from './routeNames';

// LoggedOutLayout -> SubLayouts
const AuthLayout = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'AuthLayout' */ '../layouts/LoggedOutLayout/AuthLayout/index'), 3)
);
const PricingLayout = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'PricingLayout' */ '../layouts/LoggedOutLayout/PricingLayout'), 3)
);
const CardDelivered = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'CardDelivered' */ '../layouts/LoggedOutLayout/CardDelivered'), 3)
);
// AuthLayout -> Sublayouts
const Login = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Login' */ '../layouts/LoggedOutLayout/AuthLayout/SignIn'), 3)
);
const SignUp = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'SignUp' */ '../layouts/LoggedOutLayout/AuthLayout/SignUp'), 3)
);
const RegisterMember = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'RegisterMember' */ '../layouts/LoggedOutLayout/AuthLayout/RegisterMember'),
		3
	)
);
const AppsumoLogin = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'AppsumoLogin' */ '../layouts/LoggedOutLayout/AuthLayout/AppsumoLogin'),
		3
	)
);
const ForgotPassword = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'ForgotPassword' */ '../layouts/LoggedOutLayout/AuthLayout/ForgotPassword'),
		3
	)
);

// LoggedInLayout -> SubLayouts
const MyCardsLayout = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'MyCardsLayout' */ '../layouts/LoggedInLayout/MyCardsLayout'), 3)
);
// const CreateCardLayout = React.lazy(() => componentLoader(() => import(/* webpackChunkName: 'CreateCardLayout' */ "../layouts/LoggedInLayout/CreateCardLayout"), 3))
const CreateCardLayout = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'CreateCardLayout' */ '../layouts/CreateCardLayout'), 3)
);
const Activities = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Activities' */ '../layouts/LoggedInLayout/Activities'), 3)
);

const EmailVerified = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'EmailVerified' */ '../layouts/LoggedInLayout/EmailVerified'), 3)
);
const CreateWorkflow = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'CreateWorkflow' */ '../layouts/ZapierWorkflowLayout/index'), 3)
);
const EmailAccounts = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'EmailAccounts' */ '../layouts/LoggedInLayout/Settings/EmailAccounts'),
		3
	)
);
const Dashboard = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Dashboard' */ '../layouts/LoggedInLayout/Dashboards'), 3)
);
const Onboarding = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Onboarding' */ '../layouts/LoggedInLayout/Onboarding'), 3)
);
const Settings = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Settings' */ '../layouts/LoggedInLayout/Settings'), 3)
);
const ProfileLayout = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'ProfileLayout' */ '../layouts/LoggedInLayout/Settings/ProfileLayout'),
		3
	)
);
const Team = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Team' */ '../layouts/LoggedInLayout/Settings/Team'), 3)
);
const ManageWorkSpace = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'ManageWorkSpace' */ '../layouts/LoggedInLayout/Settings/ManageWorkSpace'),
		3
	)
);
const WhiteLabelSettings = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'WhiteLabelSettings' */ '../layouts/LoggedInLayout/Settings/WhiteLabelSettings'),
		3
	)
);
const BasicSetting = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'BasicSetting' */ '../layouts/LoggedInLayout/Settings/BasicSetting'),
		3
	)
);
const CustomSetting = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'CustomSetting' */ '../layouts/LoggedInLayout/Settings/CustomSetting'),
		3
	)
);
const Billing = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'Billing' */ '../layouts/LoggedInLayout/Settings/Billing'), 3)
);
const GenerateZapierKey = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'GenerateZapierKey' */ '../layouts/LoggedInLayout/Settings/GenerateZapierKey'),
		3
	)
);
const Referral = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'Refer' */ '../layouts/LoggedInLayout/Settings/Referral'),
		3
	)
);
const PreviewCardSetting = React.lazy(() =>
	componentLoader(
		() => import(/* webpackChunkName: 'Preview' */ '../layouts/LoggedInLayout/Settings/PreviewCardSetting'),
		3
	)
);

// Pages
const PageNotFound = React.lazy(() =>
	componentLoader(() => import(/* webpackChunkName: 'PageNotFound' */ '../pages/PageNotFound'), 3)
);

const routes = [
	{
		path: '/',
		element: <LoggedOutLayout />,
		children: [
			{
				path: '/auth',
				element: <AuthLayout />,
				children: [
					{
						path: '/login',
						element: <Login />,
					},
					{
						path: '/register',
						element: <SignUp />,
					},
					{
						path: '/registermember',
						element: <RegisterMember />,
					},
					{
						path: '/appsumologin',
						element: <AppsumoLogin />,
					},
					{
						path: '/forgotpassword',
						element: <ForgotPassword />,
					},
					{ path: '', element: <Navigate to={ROUTE_NAMES.login} /> },
					{ path: '/', element: <Navigate to={ROUTE_NAMES.login} /> },
				],
			},
			// NOTE - <<redirects for old routes>>
			{ path: '/login', element: <Navigate to={ROUTE_NAMES.login} /> },
			{ path: '/register', element: <Navigate to={ROUTE_NAMES.register} /> },
			{
				path: '/appsumologin',
				element: <Navigate to={ROUTE_NAMES.appSumoLogin} />,
			},
			{
				path: '/selectdesign',
				element: <Navigate to={ROUTE_NAMES.selectdesign} />,
			},
			{ path: '/mycards', element: <Navigate to={ROUTE_NAMES.mycards} /> },
			// NOTE - <<redirects end>>
			{ path: 'pagenotfound', element: <PageNotFound /> },
			{ path: '*', element: <Navigate to={ROUTE_NAMES.PageNotFound} /> },
			{ path: '', element: <Navigate to={ROUTE_NAMES.login} /> },
			{ path: '/', element: <Navigate to={ROUTE_NAMES.login} /> },
		],
	},
	{
		path: '/pricing',
		element: <PrivateRoute component={PricingLayout} />,
	},
	{
		path: '/onboarding',
		element: <PrivateRoute component={Onboarding} />,
	},
	{
		path: '/app',
		element: <PrivateRoute component={LoggedInLayout} />,
		children: [
			{
				path: '/dashboard',
				element: <Dashboard />,
			},
			{
				path: '/mycards',
				element: <MyCardsLayout />,
			},
			{
				path: '/templates',
				element: <TemplatesPriBuilt />,
			},
			{
				path: '/activities',
				element: <Activities />,
			},
			{
				path: '/settings',
				element: <Settings />,
				children: [
					{
						path: '/profile',
						element: <ProfileLayout />,
					},
					{
						path: '/billing',
						element: <Billing />,
					},
					{
						path: '/generatezapierkey',
						element: <GenerateZapierKey />,
					},
					{
						path: '/refer',
						element: <Referral />,
					},
					{
						path: '/preview',
						element: <PreviewCardSetting />,
					},
					{
						path: '/manageworkspace',
						element: <ManageWorkSpace />,
					},
					{
						path: '/workspacesettings',
						element: <BasicSetting />,
					},
					{
						path: '/team',
						element: <Team />,
					},
					{
						path: '/emailaccounts',
						element: <EmailAccounts />,
					},
					{
						path: '/whitelabelbasicsettings',
						element: <WhiteLabelSettings />,
					},
					{
						path: '/whitelabelcustomsettings',
						element: <CustomSetting />,
					},
					{ path: '/', element: <Navigate to={ROUTE_NAMES.profile} /> },

					// ----redirect---
					{ path: '/', element: <Navigate to={ROUTE_NAMES.dashboard} /> },
				],
			},
			{ path: 'pagenotfound', element: <PageNotFound /> },
			{ path: '*', element: <Navigate to={ROUTE_NAMES.PageNotFound} /> },
			{ path: '', element: <Navigate to={ROUTE_NAMES.mycards} /> },
			{ path: '/', element: <Navigate to={ROUTE_NAMES.mycards} /> },
		],
	},
	{
		path: '/schedulecard',
		element: <PrivateRoute component={ScheduleCardLayout} />,
	},
	{
		path: '/createWorkflow',
		element: <CreateWorkflow />,
	},
	{
		path: '/emailverified',
		element: <EmailVerified />,
	},
	{
		path: '/createcard',
		element: <CreateCardLayout />,
	},
	{
		path: '/cardDelivered',
		element: <CardDelivered />,
	},
	{
		path: '/viewcard/:id',
		element: <ViewCardLayout />,
	},
];
export default routes;
