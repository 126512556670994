import React from 'react';
import CopyCardURLModal from './CopyCardURLModal';

interface Props {
	openCopyCardURLHandler: () => void;
	closeCopyCardURLHandler: () => void;
	openCopyCardURL: boolean;
	fetchProspectsAndProspectlist: () => void;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
	handleDownloadCsv: () => void;
}

const CopyCardURLModalContainer: React.FC<Props> = ({
	openCopyCardURL,
	closeCopyCardURLHandler,
	openCopyCardURLHandler,
	fetchProspectsAndProspectlist,
	infiniteRefCopyUrl,
	prospectsLoading,
	initialLoading,
	handleDownloadCsv,
}) => {
	return (
		<CopyCardURLModal
			closeCopyCardURLHandler={closeCopyCardURLHandler}
			openCopyCardURLHandler={openCopyCardURLHandler}
			openCopyCardURL={openCopyCardURL}
			fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
			infiniteRefCopyUrl={infiniteRefCopyUrl}
			prospectsLoading={prospectsLoading}
			initialLoading={initialLoading}
			handleDownloadCsv={handleDownloadCsv}
		/>
	);
};

export default CopyCardURLModalContainer;
