import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useContext } from 'react';
import { CardProspectsContext } from '../../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../../contexts/DesignTool/DesignToolContext';
import { TMergeTag } from '../../../../contexts/DesignTool/types';
import { card_prospect_services } from '../../../../services/request/cardProspect';
import ScheduledRecipientsTable from './ScheduledRecipientsTable';

interface Props {
	infiniteRef: any;
	recipientListLoading: boolean;
	fetchProspectsAndProspectlist: () => void;
	setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduledRecipientsTableContainer: React.FC<Props> = ({
	infiniteRef,
	recipientListLoading,
	fetchProspectsAndProspectlist,
	setUpdateLoading,
}) => {
	const { cardScheduledProspectData } = useContext(CardProspectsContext);
	const { mergeTags } = useContext(DesignToolContext);

	const computeTableColumns = (prospectObj: any): [string, unknown][] => {
		const { _id, prospectId, status, date, dateAndTimeOffset, saveNow, message, ...restAttrs } =
			cardScheduledProspectData?.cardProspects[0];
		let attribs = Object.keys(restAttrs);
		const tableColumns = Object.entries(prospectObj).filter((item) => {
			return attribs.includes(item[0]);
		});
		return tableColumns;
	};

	const computerHeaderColumns = (): Array<string> => {
		const newAttribs: any = [];
		mergeTags.forEach((item: TMergeTag) => {
			newAttribs.push(item.tagName);
		});
		return newAttribs;
	};

	const handleUpdateProspectDate = async (prospectData: any, date: MaterialUiPickersDate) => {
		try {
			setUpdateLoading(true);
			if (date) {
				let updatedRecipient: any = {};
				if (mergeTags.length) {
					mergeTags.forEach((item) => {
						updatedRecipient[item.colName] = prospectData[item.tagName];
					});
				}
				await card_prospect_services.updateCardProspect(prospectData._id, {
					...updatedRecipient,
					date: new Date(date).getTime(),
					saveNow: false,
					prospectId: prospectData._id,
				});
				setUpdateLoading(false);
				fetchProspectsAndProspectlist();
			}
		} catch (error) {
			console.log({ error });
			setUpdateLoading(false);
		}
	};

	return (
		<ScheduledRecipientsTable
			cardProspectsData={cardScheduledProspectData}
			computeTableColumns={computeTableColumns}
			computerHeaderColumns={computerHeaderColumns}
			infiniteRef={infiniteRef}
			recipientListLoading={recipientListLoading}
			handleUpdateProspectDate={handleUpdateProspectDate}
		/>
	);
};

export default ScheduledRecipientsTableContainer;
