import { AxiosRequestConfig } from 'axios';
import { getOptions } from '../../utils/defaults';
import axios from './axios';
import { Member } from './userOnboard.interface';
import { WorkspaceInterface } from './workspace.interface';

export const workspace_services = {
	createWorkspace,
	getWorkspacesById,
	updateWorkspace,
  getWorkspaceByID,
	deleteWorkspaceByID,
	addNewMember,
	updateMember,
	deleteMember,
};

function createWorkspace(owner_id: string, data: WorkspaceInterface) {
	return new Promise(async (resolve, reject) => {
		try {
			const clientTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '(GMT+0:00) UTC';

			const config: AxiosRequestConfig = {
				method: 'post',
				url: '/workspace',
				data: {
					...data,
					owner_id,
					timezone: getOptions?.find((item: any) => item.value === `${clientTimezone}`)?.label || '(GMT+0:00) UTC',
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function getWorkspacesById(userId: string) {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'get',
				url: `/workspace/user/${userId}`,
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
}

function updateWorkspace(data: WorkspaceInterface) {
	console.log('update workspace', data);
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'put',
				url: '/workspace',
				data: {
					...data,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
}

function deleteWorkspaceByID(workspaceId: string) {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'delete',
				url: `/workspace/${workspaceId}`,
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('Axios catch exec : ', { error });
			reject(error);
		}
	});
}
function getWorkspaceByID(workspaceId: string) {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/workspace/${workspaceId}`,
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('Axios catch exec : ', { error });
			reject(error);
		}
	});
}

function addNewMember(workspaceId: string, userId: string, memberData: Member[]) { 
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: '/team-member',
				data: { workspaceId, memberData, userId },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('error while adding team member');
			reject(error);
		}
	});
}
function updateMember(workspaceId: string, memberData: Member) {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: '/team-member',
				data: { workspaceId, memberData },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}
function deleteMember(workspaceId: string, memberEmail: string, userId: string) {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'delete',
				url: '/team-member',
				params: { workspaceId, email: memberEmail, userId },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('Axios catch exec : ', { error });
			reject(error);
		}
	});
}
