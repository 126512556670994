import { CalendarIcon, DuplicateIcon, PaperAirplaneIcon, PlusSmIcon, SearchIcon } from '@heroicons/react/solid';
import React from 'react';
import LoadingButton from '../../../../../components/LoadingButton';
import { CardProspectsData } from '../../../../../contexts/CardProspects/interfaces';
import RecipientScheduleTableContainer from './components/RecipientScheduleTable/RecipientScheduleTableContainer';

interface Props {
	openCopyCardURLHandler: () => void;
	openSceduleLaterModalHandler: () => void;
	openEditModalHandler: (edit: boolean) => void;
	handleSearchRecipients: (value: string) => void;
	selectedProspectList: string;
	cardProspectsData: CardProspectsData;
	handleEditRecipient: (recipient: any) => void;
	openModalWithRecipientInfo: (edit: boolean, recipient: any) => void;
	fetchProspectsAndProspectlist: () => void;
	infiniteRef: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
	handleSendNow: (title: string) => Promise<void>;
	onKeyDown: (e: any) => void;
	isLoading: boolean;
	setSkip: React.Dispatch<React.SetStateAction<number>>;
}

const Recipients: React.FC<Props> = ({
	openCopyCardURLHandler,
	openSceduleLaterModalHandler,
	handleSearchRecipients,
	selectedProspectList,
	cardProspectsData,
	onKeyDown,
	openModalWithRecipientInfo,
	fetchProspectsAndProspectlist,
	infiniteRef,
	prospectsLoading,
	initialLoading,
	handleSendNow,
	isLoading,
	setSkip,
}) => {
	return (
		<div className="">
			<div className="flex  justify-center pb-6">
				<div className="flex flex-col w-full">
					<div className=" overflow-x-auto ">
						<div className="py-2 align-middle block sm:inline-block w-full ">
							<div className="flex  mt-6 mb-4 w-full">
								<div className="flex items-center text-mediumGray text-xs font-medium w-1/2">
									RECIPIENTS
									<span
										title="Missing fields"
										className={
											cardProspectsData?.errors.errorCount
												? 'inline-flex items-center px-3 py-0.5 rounded text-xs font-medium bg-successBgColor text-errorTextColor ml-2'
												: 'hidden'
										}
									>
										{cardProspectsData?.errors.errorCount} error(s)
									</span>
									<span
										title="Invalid recipient emails"
										className={
											cardProspectsData?.errors.invalidEmailCount
												? 'inline-flex items-center px-3 py-0.5 rounded text-xs font-medium bg-successBgColor text-errorTextColor ml-2'
												: 'hidden'
										}
									>
										{cardProspectsData?.errors.invalidEmailCount} invalid email(s)
									</span>
								</div>
								<div title={selectedProspectList} className="text-mediumGray text-xs font-medium text-ellipsis w-1/2">
									From <span className="font-semibold text-ellipsis overflow-hidden">{selectedProspectList}</span> list
								</div>
							</div>
							<div className="relative mb-4 py-2 flex items-center flex-grow focus-within:z-10 border-gray200 border-2 rounded-md px-4">
								<SearchIcon className="w-6 h-6 text-mediumGray" />
								<input
									type="searchimage"
									name="searchimage"
									id="searchimage"
									onChange={(e) => handleSearchRecipients(e.target.value)}
									onKeyDown={onKeyDown}
									className="block w-11/12 relative placeholder:text-mediumGray text-gray-800 rounded-none focus:outline-none rounded-l-md sm:text-md border-shadowColor mr-2 pl-4 placeholder:opacity-60"
									placeholder="Search recipients"
								/>
							</div>
							<div
								className={
									initialLoading
										? 'flex flex-col animate-pulse border-2 border-gray200 h-64 sm:overflow-y-scroll overflow-scroll  rounded-md'
										: 'hidden'
								}
							>
								<div className="bg-alto flex-none h-10 my-3 rounded mx-2"></div>
								<div className="bg-alto flex-1 my-3 rounded mx-2"></div>
							</div>
							<div className={initialLoading ? 'hidden' : ''}>
								<RecipientScheduleTableContainer
									openModalWithRecipientInfo={openModalWithRecipientInfo}
									fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
									infiniteRef={infiniteRef}
									prospectsLoading={prospectsLoading}
									setSkip={setSkip}
								/>
							</div>

							<div className=" flex flex-row justify-start mt-4 text-toolBarBgBlue">
								<LoadingButton
									onClick={() => openModalWithRecipientInfo(false, null)}
									className="w-4/12 flex items-center"
								>
									<PlusSmIcon className="w-8 h-8  " />
									Add new recipient
								</LoadingButton>
							</div>
							<div className="mt-12 flex justify-between ">
								<div className="flex items-center text-blackTextlight ml-6 ">
									<LoadingButton onClick={openCopyCardURLHandler}>
										<DuplicateIcon className="w-6 h-6 text-mediumGray" />
										<div className="ml-2 text-blackTextlight  font-medium">Copy card URL</div>
									</LoadingButton>
								</div>
								<div className="flex gap-2">
									<div className="flex items-cente text-blackText">
										<LoadingButton
											onClick={openSceduleLaterModalHandler}
											className="gap-2 flex items-center py-2  px-3 border-2 border-bordergray rounded-md "
										>
											<CalendarIcon className="w-5 h-5 text-mediumGray" />
											<span>Schedule for later </span>
										</LoadingButton>
									</div>
									<div className="text-white border-2 rounded-md bg-indigo flex items-center px-3 py-2">
										<LoadingButton
											isLoading={isLoading}
											onClick={() => handleSendNow('Connect your email account')}
											className="gap-2 flex items-center w-36 justify-center"
										>
											<PaperAirplaneIcon className="w-5 h-5" />
											<span>Save and Next</span>
										</LoadingButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Recipients;
