import { AxiosRequestConfig } from 'axios';
import axios from './axios';

const getRecipientsByCardId = (cardId: string, skip: number, limit: number): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'get',
				url: `/recipient`,
				params: { cardId, skip, limit },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};

const resendEmailToRecipient = (recipientId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'post',
				url: `/recipient/retryEmail`,
				data: { id: recipientId },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};

const getRecipientById = (recipientId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'get',
				url: `/recipient/${recipientId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};
const updateRecipient= (recipientId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'put',
				url: `/recipient/${recipientId}`,
        data: { deliveryStatus: 'CANCELED' },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};

const downloadAsCsv = (cardId: string, cardTitle: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'post',
				url: `/recipient/downloadcsv`,
				data: { cardId },
				responseType: 'blob',
			};
			const response = await axios(config);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${cardTitle || 'untitled-' + new Date().toLocaleString()}.csv`);
			document.body.appendChild(link);
			link.click();
			resolve(true);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};

export const recipients_service = {
	getRecipientsByCardId,
	resendEmailToRecipient,
	getRecipientById,
	downloadAsCsv,
  updateRecipient,
};
