import { PaperClipIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';

interface Props {
	csvFile: File | null;
	handleFileUpload: (e: any) => void;
	handleDeleteFile: () => void;
}

const CsvUploaded: React.FC<Props> = ({ csvFile, handleFileUpload, handleDeleteFile }) => {
	return (
		<div className="w-full rounded-md border border-gray200 p-2 flex justify-between">
			<div className="flex gap-1">
				<PaperClipIcon className="w-5 h-5 text-textGrayesh" />
				<p className="text-sm font-medium w-56 truncate">{csvFile?.name || 'untitled.csv'}</p>
			</div>
			<div className="flex gap-1">
				<label className="cursor-pointer inline-flex items-center text-center justify-center bg-white rounded-md w-full">
					<span className="text-base leading-normal flex items-center">
						<p className="text-indigo hover:text-indigobtnhovr hover:underline hover:decoration-indigobtnhovr font-medium">
							Change file
						</p>
					</span>
					<input className="hidden" type="file" accept=".csv" onChange={handleFileUpload} />
				</label>
				<TrashIcon className="w-6 h-6 text-textGrayesh cursor-pointer  hover:text-indigo" onClick={handleDeleteFile} />
			</div>
		</div>
	);
};

export default CsvUploaded;
