import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CardProspectsContext } from '../../../../../../../contexts/CardProspects/CardProspectsContext';
import { CardProspectStatusEnum } from '../../../../../../../contexts/CardProspects/interfaces';
import { DesignToolContext } from '../../../../../../../contexts/DesignTool/DesignToolContext';
import { TMergeTag } from '../../../../../../../contexts/DesignTool/types';
import { card_prospect_services } from '../../../../../../../services/request/cardProspect';
import RecipientScheduleTable from './RecipientScheduleTable';

interface Props {
	openModalWithRecipientInfo: (edit: boolean, recipient: any) => void;
	fetchProspectsAndProspectlist: () => void;
	infiniteRef: any;
	prospectsLoading: boolean;
  setSkip: React.Dispatch<React.SetStateAction<number>>
}

const RecipientScheduleTableContainer: React.FC<Props> = ({
	openModalWithRecipientInfo,
	fetchProspectsAndProspectlist,
	infiniteRef,
	prospectsLoading,
  setSkip
}) => {
	const { mergeTags, selectedProspectList, prospectLists } = useContext(DesignToolContext);
	const { cardProspectsData } = useContext(CardProspectsContext);
	const card = useSelector((state: any) => state.card);

	const computeTableColumns = (prospectObj: any): [string, unknown][] => {
		const { _id, prospectId, status, date, dateAndTimeOffset, saveNow, message, ...restAttrs } =
			cardProspectsData?.cardProspects[0];
		let attribs = Object.keys(restAttrs);
		const tableColumns = Object.entries(prospectObj).filter((item) => {
			return attribs.includes(item[0]);
		});
		return tableColumns;
	};

	const computerHeaderColumns = (): Array<string> => {
		const newAttribs: any = [];
		mergeTags.forEach((item: TMergeTag) => {
			newAttribs.push(item.tagName);
		});
		return newAttribs;
	};

	const handleProspectStatusChange = async (prospect: any) => {
		const updatedStatus =
			prospect.status === CardProspectStatusEnum.SELECTED
				? CardProspectStatusEnum.UNSELECTED
				: CardProspectStatusEnum.SELECTED;

		await card_prospect_services.updateCardProspectStatus(prospect._id, updatedStatus);
		fetchProspectsAndProspectlist();
	};

	const handleSelectAll = async (event: any) => {
		try {
			const prospectListId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
      // setting skip to zero so that next time complete list can be loaded
      setSkip(0)
			if (prospectListId) {
				await card_prospect_services.updateAllCardProspectsStatus(
					prospectListId,
					card.currentCardId,
					event.target.checked
				);
				fetchProspectsAndProspectlist();
			}
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<RecipientScheduleTable
			cardProspectsData={cardProspectsData}
			computerHeaderColumns={computerHeaderColumns}
			computeTableColumns={computeTableColumns}
			openModalWithRecipientInfo={openModalWithRecipientInfo}
			handleProspectStatusChange={handleProspectStatusChange}
			handleSelectAll={handleSelectAll}
			infiniteRef={infiniteRef}
			prospectsLoading={prospectsLoading}
		/>
	);
};

export default RecipientScheduleTableContainer;
