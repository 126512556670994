import CustomDropDownWithSelection from '../../../../../../components/CustomDropdown/index';
import { TMergeTag } from '../../../../../../contexts/DesignTool/types';
import { EmailAccountInterface } from '../../../../../../services/request/emailAccounts.interface';
import CardScheduledContainer from '../../../../CardScheduled/CardScheduledContainer';

interface Props {
	handleAddMergeTag: (mergeTag: string) => void;
	selectedEmailAccount: EmailAccountInterface | null;
	mergeTags: TMergeTag[];
	fromName: string;
	inputRef: any;
	subjectLine: string;
	title: string;
	setSubjectLine: any;
}
const CustomizeSubjectLine: React.FC<Props> = ({
	handleAddMergeTag,
	selectedEmailAccount,
	mergeTags,
	fromName,
	inputRef,
	subjectLine,
	setSubjectLine,
	title,
}) => {
	return (
		<>
			<div className="flex flex-col w-full gap-y-5">
				<CardScheduledContainer title={title} />
				<div className="w-full  justify-between pt-6 gap-x-6 hidden">
					<div className="max-w-400 font-inter font-normal text-sm tracking-wide">
						Add a preferred sender name and Subject line. You can also add Merge tags in subject line as well
					</div>
					<div className="flex flex-row-reverse gap-x-2 w-full">
						<div>
							<CustomDropDownWithSelection mergeTags={mergeTags} handleAddMergeTag={handleAddMergeTag} />
						</div>
						<input
							className="w-full rounded-md"
							style={{ border: '1px solid #D1D5DB', height: '34px' }}
							type="text"
							maxLength={70}
							ref={inputRef}
							id="companyName"
							name="companyName"
							placeholder={`Hey ${mergeTags[0].tagName}, from ${
								fromName ? fromName : selectedEmailAccount?.fromName
							} created a digital card for you.`}
							value={subjectLine ? subjectLine : subjectLine}
							onChange={(e: any) => setSubjectLine(e.target.value)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomizeSubjectLine;
