const defaultState: any = {
	firstName: '',
	lastName: '',
};

const userProfileReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'SET_USER_PROFILE':
			return action.payload;

		default:
			return state;
	}
};

export default userProfileReducer;
