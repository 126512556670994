import { useImmerState } from '@shrugsy/use-immer-state';
import React, { createContext } from 'react';
import { fromNameDefault } from './defaults';
import { IfromName } from './interfaces';

export const FromNameContext = createContext<IfromName>(fromNameDefault);

export const FromNameProvider: React.FC = ({ children }) => {
	const [fromName, setFromName] = useImmerState<string>(fromNameDefault.fromName);
	const [subjectLine, setSubjectLine] = useImmerState<string>(fromNameDefault.subjectLine);
	const [cardPreviewHeader, setCardPreviewHeader]= useImmerState<string>(fromNameDefault.cardPreviewHeader);
	const [cardPreviewFooter, setCardPreviewFooter]= useImmerState<string>(fromNameDefault.cardPreviewFooter);
	const [viewCardButtonColor, setViewCardButtonColor ]= useImmerState<string>(fromNameDefault.viewCardButtonColor);

	return (
		<FromNameContext.Provider value={{ fromName, setFromName, subjectLine, setSubjectLine ,
		 cardPreviewHeader,setCardPreviewHeader,cardPreviewFooter,setCardPreviewFooter,viewCardButtonColor,setViewCardButtonColor}}>
			{children}
		</FromNameContext.Provider>
	);
};
