const defaultState: any = [];

const orderReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'SET_ORDERS':
			return action.payload;

		default:
			return state;
	}
};

export default orderReducer;
