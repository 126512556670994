import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { setcurrentWorkspace } from '../../redux/actions';
import { user_profile_service } from '../../services/request/userProfile';
import useComponentVisible from '../../utils/useComponentVisible';
import DropDown from './components/DropDown';
import NewMenuDrop from './components/NewMenuDrop';
import { Helmet } from 'react-helmet';

const NewHeader = ({handleClick}) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const currentWorkspace = useSelector((state) => state.currentWorkspace);
  const workspaces = useSelector((state) => state.workspaces);
  const { setIsComponentVisible: setSelect } = useComponentVisible(false);

  const handleSetCurrentWorkspace = (currentWorkspace) => {
    setSelect(false);

    user_profile_service
      .updateProfile(userProfile._id, {
        selectedWorkspace: currentWorkspace?._id,
      })
      .then(() => {
        dispatch(setcurrentWorkspace(currentWorkspace));
      })
      .catch((error) => {
        console.log(`update profile error`, error.message);
        swal({
          title: 'Oops!',
          text: 'Encountered an error while changing workspace',
        });
      });
  };

  return (
    <div className="h-20 px-6 py-2 border-b bg-white border-veryLightGray flex flex-col justify-center">
      <Helmet>
        <script id="loopedIn" type="text/javascript">
          {`
        var ps_popover = {
          workspace_id : "f3ce381c-da50-4bf6-ad6e-31ee2f1d8e77",
          selector: "#widgetButton"
        };
      `}
        </script>
        <script type="text/javascript" src="https://cdn.productstash.io/js/popover.min.js?v=0.1" defer="defer"></script>
      </Helmet>
      <div className="flex justify-between items-center">
        <div>
          <div className="flex md:ml-0 ld:ml-0 xl:ml-0 2xl:ml-0 4xl:ml-0 6xl:ml-0 ">
            <div className="flex flex-row md:w-full relative rounded-md cursor-pointer">
              <div className="w-full">
                <DropDown
                  handleSetCurrentWorkspace={handleSetCurrentWorkspace}
                  workspaces={workspaces}
                  currentWorkspace={currentWorkspace}
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
        <div>
          <div className="md:block ">
            <div className="flex justify-end items-center gap-x-4 ">
              <div className=" flex items-center  self-center mr-10"></div>
              <button onClick={handleClick} className="w-52 h-10 justify-center items-center rounded-md py-2 px-4 flex gap-x-2 bg-blue-700" >
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.1665 16C12.5848 16 16.1665 12.4183 16.1665 8C16.1665 3.58172 12.5848 0 8.1665 0C3.74823 0 0.166504 3.58172 0.166504 8C0.166504 12.4183 3.74823 16 8.1665 16ZM9.1665 5C9.1665 4.44772 8.71879 4 8.1665 4C7.61422 4 7.1665 4.44772 7.1665 5V7H5.1665C4.61422 7 4.1665 7.44771 4.1665 8C4.1665 8.55228 4.61422 9 5.1665 9H7.1665V11C7.1665 11.5523 7.61422 12 8.1665 12C8.71879 12 9.1665 11.5523 9.1665 11V9H11.1665C11.7188 9 12.1665 8.55228 12.1665 8C12.1665 7.44772 11.7188 7 11.1665 7H9.1665V5Z"
                    fill="white"
                  />
                </svg>
                <p className=" font-inter font-medium text-md pt-0.5 leading-5 text-white ">Create new card</p>
              </button>
              <div>
                <NewMenuDrop />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
