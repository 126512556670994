import { Dialog, Transition } from '@headlessui/react';
// import { DownloadIcon } from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react';
// import LoadingButton from '../../../../../../../../components/LoadingButton'
import ModalDialogBoxContainer from './components/ModalDialogBoxContainer';

interface Props {
	openScheduleSkipContinueModalHandler: () => void;
	closeScheduleSkipContinueModalHandler: () => void;
	openScheduleSkipContinueModal: boolean;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const ScheduleSkipContinueModal: React.FC<Props> = ({
	openScheduleSkipContinueModal,
	closeScheduleSkipContinueModalHandler,
	setTitleModel,
	setProgressSteps,
	progressSteps,
}) => {
	const [dateDialog, setdateDialog] = useState<boolean>(false);

	return (
		<Transition appear show={openScheduleSkipContinueModal} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={dateDialog ? () => null : closeScheduleSkipContinueModalHandler}
			>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md ">
							<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-blackText">
								Schedule card
							</Dialog.Title>
							<ModalDialogBoxContainer
								setdateDialog={setdateDialog}
								closeScheduleSkipContinueModalHandler={closeScheduleSkipContinueModalHandler}
								setTitleModel={setTitleModel}
								setProgressSteps={setProgressSteps}
								progressSteps={progressSteps}
							/>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ScheduleSkipContinueModal;
