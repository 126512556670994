import React from 'react';
import NoRecipientsContainer from './components/NoRecipients/NoRecipientContainer';
import RecipientsContainer from './components/Recipients/RecipientsContainer';
import { ProspectListWithProspectsInterface } from '../../../services/request/prospectList.interface';

interface Props {
	selectedProspectList: string;
	prospectListWithProspectData: ProspectListWithProspectsInterface | null;
	handleSearchRecipients: (value: string) => void;
	fetchProspectsAndProspectlist: () => void;
	infiniteRef: any;
	onKeyDown: (e: any) => void;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
	setSkip: React.Dispatch<React.SetStateAction<number>>;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const AddRecipient: React.FC<Props> = ({
	selectedProspectList,
	handleSearchRecipients,
	fetchProspectsAndProspectlist,
	onKeyDown,
	infiniteRef,
	infiniteRefCopyUrl,
	prospectsLoading,
	initialLoading,
	setSkip,
	setTitleModel,
	setProgressSteps,
	progressSteps,
}) => {
	return (
		<div>
			<div className="w-full">
				<div className="py-6">
					<p className="text-sm  font-normal " style={{ color: '#6B7280' }}>
						You can add recipients individually or import a contact list by uploading a Bulk CSV
					</p>
				</div>
				<div className={!selectedProspectList ? '' : 'hidden'}>
					<NoRecipientsContainer />
				</div>
				<div className={selectedProspectList ? '' : 'hidden'}>
					<RecipientsContainer
						handleSearchRecipients={handleSearchRecipients}
						fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
						onKeyDown={onKeyDown}
						infiniteRef={infiniteRef}
						infiniteRefCopyUrl={infiniteRefCopyUrl}
						prospectsLoading={prospectsLoading}
						initialLoading={initialLoading}
						setSkip={setSkip}
						setTitleModel={setTitleModel}
						setProgressSteps={setProgressSteps}
						progressSteps={progressSteps}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddRecipient;
