import { Dialog, Transition } from '@headlessui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import LoadingButton from '../../../../../../../components/LoadingButton';
import RecipientTableContainer from './components/RecipientTableContainer';

interface Props {
	openCopyCardURLHandler: () => void;
	closeCopyCardURLHandler: () => void;
	openCopyCardURL: boolean;
	fetchProspectsAndProspectlist: () => void;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
	handleDownloadCsv: () => void;
}

const CopyCardURLModal: React.FC<Props> = ({
	openCopyCardURL,
	closeCopyCardURLHandler,
	fetchProspectsAndProspectlist,
	infiniteRefCopyUrl,
	prospectsLoading,
	initialLoading,
	handleDownloadCsv,
}) => {
	return (
		<Transition appear show={openCopyCardURL} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => null}>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
							<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-blackText text-sm">
								Get card URLs
							</Dialog.Title>
							<div className="py-2">
								<p className="text-sm text-mediumGray">
									You can copy URL of recipients and send them directly via any medium e.g. messenger, whatsapp etc.
								</p>
							</div>
							<RecipientTableContainer
								fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
								infiniteRefCopyUrl={infiniteRefCopyUrl}
								prospectsLoading={prospectsLoading}
								initialLoading={initialLoading}
							/>
							<div className="mt-4 flex justify-between">
								<div className="flex justify-start">
									<LoadingButton onClick={handleDownloadCsv} className="flex justify-center text-sm items-center gap-2">
										<DownloadIcon className="w-4 h-4" />
										Download as CSV
									</LoadingButton>
								</div>
								<div className="">
									<button
										type="button"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blackTextlight bg-white border border-transparent rounded-md focus:outline-none"
										onClick={closeCopyCardURLHandler}
									>
										Cancel
									</button>
									<button
										onClick={closeCopyCardURLHandler}
										type="submit"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo bg-lightIndigo border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
									>
										Continue
									</button>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default CopyCardURLModal;
