import { Action, DesignToolState } from './types';

export const designToolInit: DesignToolState = {
	activePageIndex: 0,
	cardCursor: { page1: 0, page2: null },
	right_side_bar_selection: { element: null, type: null },
	selectedShapeName: null,
	$stage1: { current: null },
	$stage2: { current: null },
	$layer1: { current: null },
	$layer2: { current: null },
	$tr1: { current: null },
	$tr2: { current: null },
	$selectionRect1: { current: null },
	$selectionRect2: { current: null },
};

export const designToolReducer = (designToolState: DesignToolState, action: Action) => {
	switch (action.type) {
		case 'SET_ACTIVE_PAGE_INDEX': {
			return {
				...designToolState,
				activePageIndex: action.payload,
			};
		}
		case 'SET_CARD_CURSOR': {
			return {
				...designToolState,
				cardCursor: action.payload,
			};
		}
		case 'SELECTED_ELEMENT': {
			return {
				...designToolState,
				right_side_bar_selection: action.payload,
			};
		}

		case 'SET_SELECTED_SHAPE_NAME': {
			return {
				...designToolState,
				selectedShapeName: action.payload.shape_id,
				right_side_bar_selection: action.payload.shapeType,
			};
		}

		case 'RESET_STATE_MACHINE': {
			return {
				...designToolInit,
			};
		}
		default:
			throw new Error('cannot update reducer');
	}
};
