import i18n from 'i18n-js';
import { Crisp } from "crisp-sdk-web";
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStore } from 'redux';
import WebFont from 'webfontloader';
import { AppLanguages } from './assets/languages';
import CustomLoader from './components/CustomLoader';
import { CanvaButtonProvider } from './contexts/CanvaButton/CanvaButtonContext';
import { DesignToolProvider } from './contexts/DesignTool/DesignToolContext';
import { CardProspectsProvider } from './contexts/CardProspects/CardProspectsContext';
import { FromNameProvider } from './contexts/CardProspects/fromName';
import { EmailAccountProvider } from './contexts/CardProspects/emailAccountContext';
import allReducers from './redux/reducers';
import routes from './Routes/routes';
// import './styles/index.css';
import { AuthProvider } from './utils/auth';
import { WhiteLabelHead } from './whiteLabelhead';

declare const window: any;

Crisp.configure('ea677e40-72c6-4850-a9ad-d914581fe411', {
  autoload: false,
  lockMaximized: true
});

const saveToLocalStorage = (state: any) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (e) {
		console.log('Error saving to local storage.', e);
	}
};

const loadFromLocalStorage = () => {
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		console.log('Error fetching from local storage.');
		return undefined;
	}
};

const persistedState = loadFromLocalStorage();
const store =
	process.env.NODE_ENV === 'development'
		? createStore(
			allReducers,
			persistedState,
			window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
		: createStore(allReducers, persistedState);

const App: React.FC = () => {
	useEffect(() => {
		store.subscribe(() => saveToLocalStorage(store.getState()));
		i18n.fallbacks = true;
		i18n.translations = AppLanguages;
		i18n.locale = 'en';
		WebFont.load({
			google: {
				families: [
					'Comfortaa',
					'Josefin Sans',
					'Euphoria Script',
					'Oswald',
					'Alfa Slab One',
          'UnifrakturMaguntia',
					'Bangers',
					'Caveat',
					'DM Sans',
					'East Sea Dokdo',
					'Homemade Apple',
					'Kristi',
					'Lobster Two',
					'Maven Pro',
					'Monoton',
					'Montserrat',
					'Playfair Display',
					'Press Start 2P',
					'Raleway',
					'Roboto Slab',
					'Shadows Into Light',
					'Swanky and Moo Moo',
          'Bebas Neue',
          'Pacifico',
          'Secular One',
          'Teko',
          'Crimson Text',
          'Righteous',
          'Satisfy',
          'Kavoon',
          'Fredoka One',
          'IBM Plex Mono',
          'Questrial',
          'Barlow Semi Condensed',
          'Exo',
          'Play',
          'Lilita One',
          'Acme',
          'Chakra Petch',
          'Permanent Marker',
          'Orbitron',
          'Ubuntu Condensed',
          'Cinzel',
          'Spectral',
          'Covered By Your Grace',
          'Kalam',
          'Changa',
          'Space Mono',
          'Courgette',
          'Patua One',
          'Great Vibes',
          'Kaushan Script',
          'Cormorant',
          'Yeseva One',
          'Sora',
          'Oleo Script',
          'Outfit',
          'Yellowtail',
          'Montserrat Alternates',
          'Cookie',
          'Michroma',
          'Philosopher',
          'Lexend',
          'Urbanist',
          'Sawarabi Mincho',
          'Patrick Hand',
          'Concert One',
          'Gloria Hallelujah',
          'Sacramento',
          'Press Start 2P',
          'Poppins',
          'Inter',
          'Nunito',
          'Rubik',
          'Kanit',
          'Mulish',
          'Quicksand',
          'Inconsolata',
          'Titillium Web',
          'Dosis',
          'Libre Baskerville',
          'Prompt',
          'Irish Grover',
          'Sniglet',
          'Merienda One',
          'Rubik Moonrocks',
          'Seaweed Script',
          'Gabriela',
          'Averia Libre',
          'Viaoda Libre',
          'Grandstander',
          'Caladea',
          'Tillana',
          'Podkova',
          'Spinnaker',
          'Paprika',
          'Hanuman',
          'Kelly Slab',
          'Mirza',
          'Copse',
          'Marvel',
          'Calligraffitti',
          'Vollkorn SC',
          'Lemon',
          'Gugi',
          'Rakkas',
          'Bungee Shade',
          'Limelight',
          'Grenze Gotisch',
          'Cormorant Upright',
          'McLaren',
          'Turret Road',
          'Fondamento',
          'Oleo Script Swash Caps',
          'Sansita Swashed',
          'Rouge Script',
          'Fanwood Text',
          'Montez',
          'Mansalva',
          'Vast Shadow',
          'Mountains of Christmas',
          'Ruslan Display',
          'Charmonman',
          'Walter Turncoat',
          'Life Savers',
          'Yeon Sung',
          'Sail',
          'Gemunu Libre',
          'Vibur',
          'Cherry Cream Soda',
          'Sulphur Point',
          'Redressed',
          'Engagement',
          'Lovers Quarrel',
          'Monofett',
          'Chicle',
          'Sarina',
          'Croissant One',
          'Text Me One',
          'Wellfleet',
          'Margarine',
          'Mogra',
          'Mogra',
          'Zen Tokyo Zoo',
          'Praise',
          'Moulpali',
          'Trochut',
          'Taprom',
          'Sunshiney',
          'Vibes',
          'Nova Script',
          'Gwendolyn',
          'Kenia',
          'Tourney',
          'Warnes',
          'Ole',
          'Nerko One',
          'Goldman',
          'Germania One',
          'Supermercado One',
				],
			},
		});
	}, []);

	const routing = useRoutes(routes);
	return (
		<Provider store={store}>
			<AuthProvider>
				<CanvaButtonProvider>
					<DesignToolProvider>
						<CardProspectsProvider>
							<FromNameProvider>
								<EmailAccountProvider>
									<Suspense fallback={<CustomLoader />}>
										{/* Notification for image uploading */}
										<ToastContainer />
										<WhiteLabelHead>{routing}</WhiteLabelHead>
									</Suspense>
								</EmailAccountProvider>
							</FromNameProvider>
						</CardProspectsProvider>
					</DesignToolProvider>
				</CanvaButtonProvider>
			</AuthProvider>
		</Provider>
	);
};

export default App;
