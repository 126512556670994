import React, { memo } from 'react';
import useWhitelabelSettings from '../../hooks/useWhitelabelSettings';
import './style.css';
const CustomLoader = () => {
	const { isWhiteLabel, whiteLabelSettings } = useWhitelabelSettings();
	return (
		<>
			<div className={isWhiteLabel ? 'hidden' : 'flex place-items-center justify-center h-screen'}>
				<img width="50" src="https://s3.us-east-2.amazonaws.com/cardclan.io-assets/loader-cardclan-nm.svg" alt="loadingSvg" />
			</div>
			<div className={isWhiteLabel ? 'flex justify-center items-center h-screen' : 'hidden'}>
				<div
					className="animate-spin rounded-full h-10 w-10 border-b-8 "
					style={{
						borderColor: isWhiteLabel ? whiteLabelSettings?.brandColors?.['Sidebar Color']: 'black',
					}}
				></div>
			</div>
		</>
	);
};
export default memo(CustomLoader);
