import { AxiosRequestConfig } from 'axios';
import axios from './axios';

const uploadImageToS3 = (imageFile: File, type: 'workspace' | 'profile' | 'whitelabel'): Promise<string> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: '/composite/upload-on-s3',
				data: {
					fileName: imageFile.name,
					type,
				},
			};
			const resp = await axios(config);
			const uploadURL = resp.data.data;

			const imageUploadResp = await fetch(uploadURL, {
				method: 'PUT',
				headers: {
					'Content-Type': 'image/jpg',
					'Access-Control-Allow-Origin': '*',
				},
				body: imageFile,
			});

			if (imageUploadResp.ok) {
				const imageUrl = uploadURL.split('?')[0];
				if (imageUrl) {
					// successful upload of image file
					resolve(imageUrl);
				} else {
					throw new Error('There was a problem uploading your image');
				}
			} else {
				throw new Error('There was a problem uploading your image');
			}
		} catch (error: any) {
			console.log('Uplaod image error', error);
			reject(error);
		}
	});
};

const uploadUserImage = (
	imageFile: File,
	type: 'IMAGE' | 'BGIMAGE' | 'COVER' | 'PUBLIC' | 'GIF',
	workspaceId: string,
	userId: string
): Promise<string> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: '/composite/upload-on-s3',
				data: {
					fileName: imageFile.name,
					type: 'card',
				},
			};
			const resp = await axios(config);
			const uploadURL = resp.data.data;

			const imageUploadResp = await fetch(uploadURL, {
				method: 'PUT',
				headers: {
					'Content-Type': 'image/jpg',
					'Access-Control-Allow-Origin': '*',
				},
				body: imageFile,
			});

			if (imageUploadResp.ok) {
				const imageUrl = uploadURL.split('?')[0];
				if (imageUrl) {
					const userUploadConfig: AxiosRequestConfig = {
						method: 'post',
						url: '/myUploads',
						data: {
							location: imageUrl,
							workspaceId,
							userId,
							type,
						},
					};
					await axios(userUploadConfig);
          // successful upload of image file
					resolve(imageUrl);
				} else {
					throw new Error('There was a problem uploading your image');
				}
			} else {
				throw new Error('There was a problem uploading your image');
			}
		} catch (error: any) {
			console.log('Uplaod image error', error);
			reject(error);
		}
	});
};

export const amazonUploadingServices = {
	uploadImageToS3,
	uploadUserImage,
};
