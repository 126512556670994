import I18n from 'i18n-js';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import CustomSpinnerLoader from '../../../../../components/CustomSpinnerLoader';
import CustomSwal from '../../../../../components/CustomSwal/CustomSwalContainer';
import ProgressiveImage from '../../../../../components/ProgressiveImage';
import { CanvaButtonContext } from '../../../../../contexts/CanvaButton/CanvaButtonContext';
import { DesignToolContext } from '../../../../../contexts/DesignTool/DesignToolContext';
import useWhitelabelSettings from '../../../../../hooks/useWhitelabelSettings';
import {
  setCategories,
  setCurrentCardData,
  setCurrentCardId,
  setcurrentWorkspace,
  setUploadedImageData,
} from '../../../../../redux/actions';
import { ROUTE_NAMES } from '../../../../../Routes/routeNames';
import { firebaseAuthServices } from '../../../../../services/firebaseAuth';
import { card_services } from '../../../../../services/request/card';
import { design_categories_services } from '../../../../../services/request/designCategories';
import { amazonUploadingServices } from '../../../../../services/request/s3ImageUpload';
import { stageDimensions } from '../../../../../utils/defaults';
import Resizer from '../../../../../utils/imageResizer';
import ImagePicker from './ImagePicker';

const SelectTemplate = ({ handleClose }) => {
  let navigate = useNavigate();
  const card = useSelector((state) => state.card);
  const currentWorkspace = useSelector((state) => state.currentWorkspace);
  const whiteLabelSettings = useSelector((state) => state.whiteLabelSettings);
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [imagesList, setImagesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [swalOptions, setSwalOptions] = useState({
    visible: false,
    title: '',
    message: '',
    swalType: '',
    runHandler: false,
  });

  const categories = useSelector((state) => state.categories);
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  let category = '';
  const [userUploadsCategory, setUserUploadsCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardId, setCardId] = useState('');

	const [selectedCategoriesList, setSelectedCategoriesList] = useState([]);
	const [filteredCategoriesList, setFilteredCategoriesList] = useState([]);
  const [searchValue, setsearchValue] = useState('');

  const imageProxy = 'https://image.cardclan.io/api/imageproxy/150x,q150/';
  const imageProxyBlur = 'https://image.cardclan.io/api/imageproxy/10x,q10/';
  const { isWhiteLabel } = useWhitelabelSettings();
  const [seeMore, setseeMore] = useState(false);
  const { setCardPagesX } = useContext(DesignToolContext);
  const { apiObjectCanva } = useContext(CanvaButtonContext);
  const currentUser = firebaseAuthServices.getCurrentUser();

  const loadScreenData = () => {
    setIsLoading(true);
    design_categories_services
      .getDesginCategories()
      .then((categoriesRes = []) => {
        if (categoriesRes.length) {
          setCategoriesList([...categoriesRes]);
          setSelectedCategoriesList([...categoriesRes])
          setActiveCategory(categoriesRes[0]);
          dispatch(setCategories([...categories, ...categoriesRes]));
          getImagesByCategory(categoriesRes[0]);
        } else {
          setSwalOptions({
            visible: true,
            title: 'Warning',
            message: 'No design categories found!',
            swalType: 'errorWarning',
          });
        }
      })
      .catch((error) =>
        swal({
          title: 'Oops!',
          text: `${error.message}`,
        })
      )
      .then(() => {
        setIsLoading(false);
      });
  };
  const getUserUploads = () => {
    setIsLoading(true);
    design_categories_services
      .getUserUploadsCategory(currentWorkspace?._id, userProfile?._id, 'COVER')
      .then((userCategory) => {
        if (userCategory?.length && userCategory[0]?.designImages?.length && userCategory[0]) {
          setUserUploadsCategory(userCategory[0]);
        } else {
          console.log('No user uploaded images found');
          setUserUploadsCategory({ designImages: [], name: 'My uploads', updatedAt: 1, _id: 'my_uploads' });
        }
      })
      .catch((error) => console.log({ error }))
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let windowUrl = window.location.href;
    let cardIdFromUrl = windowUrl.split('id=')[1];
    setCardId(cardIdFromUrl);

    loadScreenData();
    if (currentUser?.isAnonymous === false) {
      getUserUploads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.isAnonymous]);

  const getImagesByCategory = (cat) => {
    setImagesList([...cat?.designImages]);
  };
  const onCategoryChange = (cat) => {
    setActiveCategory(cat);
    setIsLoading(true);
    getImagesByCategory(cat);
    setIsLoading(false);
  };

  const CustomSwalResponseHandler = () => {
    closeSwal();
    if (swalOptions.runHandler) navigate(ROUTE_NAMES.mycards);
  };

  const initializeCardData = async (myCard, downloadUrl) => {
    if (!!currentWorkspace?._id) {
      if (!cardId) {
        try {
          let cardRef = await card_services.createNewCard(userProfile._id, {
            ...myCard,
            workspace_id: currentWorkspace._id,
            cardPages: [
              {
                images: [
                  {
                    draggable: false,
                    imageUrl: downloadUrl,
                    x: 0,
                    y: 0,
                    ...stageDimensions,
                  },
                ],
              },
            ],
            stage: stageDimensions,
            status: 'DRAFT',
            title: activeCategory?.name
          });
          if (!whiteLabelSettings) {
            if (category && window.analytics) {
              window.analytics.track('Card_Created', {
                category,
              });
            }
            if ( window.hyperengage) {
              window.hyperengage(
                'track',
                'Card_Created', {
                  properties: {
                    category: category
                  }
                }
              );
              
            }
          }

          dispatch(setCurrentCardId(cardRef._id));
          dispatch(setCurrentCardData({ ...cardRef.data }));
          dispatch(
            setcurrentWorkspace({
              ...currentWorkspace,
              cards: [...(currentWorkspace.cards || []), cardRef._id],
            })
          );
          navigate(ROUTE_NAMES.createcard + '?id=' + cardRef._id);
        } catch (error) {
          swal({
            icon: 'error',
            title: error.message,
          });
          console.log(error);
          setIsLoading(false);
        }
      }
      if (cardId) {
        try {
          const updatedCardData = await card_services.changeCardCoverUrl(cardId, downloadUrl);
          dispatch(setCurrentCardData(updatedCardData));
          setCardPagesX(updatedCardData.cardPages);
          navigate(ROUTE_NAMES.createcard + '?id=' + cardId);
        } catch (error) {
          setSwalOptions({
            visible: true,
            title: '',
            message: error.message,
            swalType: 'errorWarning',
            runHandler: true,
          });
        }
      }
      setIsLoading(false);
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setSwalOptions({
        visible: true,
        title: 'Oops',
        message: "Looks like you don't have any workspaces yet.",
        swalType: 'errorWarning',
      });
      // swal("Oops", "Looks like you don't have any workspaces yet.", "warning")
    }
  };
  const copyrightImage = async (value) => {
    try {
      setIsLoading(true);
      let myCard = {};
      myCard.designUrl = value;

      initializeCardData(myCard, value);
    } catch (error) {
      setIsLoading(false);

      console.log(error.message);
    }
  };
  const uploadedImage = async (value) => {
    try {
      setIsLoading(true);
      let myCard = {};
      let downloadUrl;
      if (currentUser?.isAnonymous) {
        downloadUrl = await amazonUploadingServices.uploadImageToS3(value.file, 'card');
      } else {
        const ws_id = currentWorkspace?._id || card?.currentCardData?.workspace_id;
        downloadUrl = await amazonUploadingServices.uploadUserImage(value.file, 'COVER', ws_id, userProfile?._id);
      }
      myCard.designUrl = downloadUrl;
      dispatch(setUploadedImageData({ ...value, downloadUrl }));

      initializeCardData(myCard, downloadUrl);
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };
  const handleImageClick = (event, value) => {
    setLoading(true);
    category = activeCategory?.name;
    if (typeof value === 'string') {
      copyrightImage(value);
    } else {
      uploadedImage(value);
    }
  };
  const handleChange = (val) => {
    if (val === userUploadsCategory?.name) {
      onCategoryChange(userUploadsCategory);
      return;
    }
    onCategoryChange(categoriesList.find(({ name }) => name === val));
  };
  const closeSwal = () => {
    setSwalOptions({
      visible: false,
      title: '',
      message: '',
      swalType: '',
      runHandler: false,
    });
  };
  const onCanvaPress = (document, url) => {
    apiObjectCanva.createDesign({
      design: {
        type: 'Poster',
      },
      onDesignPublish: ({ exportUrl, designId }) => {
        handleCanvaImage(exportUrl);
      },
    });
  };
  const handleCanvaImage = async (url) => {
    category = 'Design on Canva';
    try {
      setLoading(true);
      let file = await fetch(url)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], 'canva_image', { type: 'image/png' }))
        .catch((err) => console.log(err));
      const value = {
        file: file,
        id: `image_${file.lastModified}`,
        name: file.name,
        width: 419,
        height: 595,
      };
      let myCard = {};

      let downloadUrl;
      if (currentUser?.isAnonymous) {
        downloadUrl = await amazonUploadingServices.uploadImageToS3(value.file, 'card');
      } else {
        const ws_id = currentWorkspace?._id || card?.currentCardData?.workspace_id;
        downloadUrl = await amazonUploadingServices.uploadUserImage(value.file, 'IMAGE', ws_id, userProfile?._id);
      }
      myCard.designUrl = downloadUrl;
      dispatch(setUploadedImageData({ ...value, downloadUrl }));

      initializeCardData(myCard, downloadUrl);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleImageUpload = (e) => {
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    let files = { ...e.target.files };
    if (fileInput) {
      let newId = new Date().getTime();
      Resizer.imageFileResizer(
        e.target.files[0],
        419,
        595,
        'png',
        100,
        0,
        (uri, width, height) => {
          handleImageClick(e, {
            id: 'image_' + newId.toString(),
            file: files[0],
            name: uri,
            width,
            height,
          });
        },
        'base64'
      );
    } else {
      setSwalOptions({
        visible: true,
        title: 'Error',
        message: I18n.t('not_a_valid_file'),
        swalType: 'error',
      });
      // swal(i18n.t("not_a_valid_file"));
    }
  };
  const searchChangeHandler = (e) => {
		setsearchValue(e.target.value);
	};
  const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 0), []);


  useEffect(() => {
		if (searchValue === '') {
			setCategoriesList(selectedCategoriesList);
		}
	}, [searchValue, selectedCategoriesList]);

	useEffect(() => {
		if (searchValue === '') {
			setCategoriesList(selectedCategoriesList);
			setFilteredCategoriesList(selectedCategoriesList);
			return;
		}
		const re = /([a-zA-Z0-9])\w*/g;
		let words = searchValue.match(re);
		words = words?.map((word) => word.toLowerCase());

		let tempCards = selectedCategoriesList.filter((card) => {
			let currCard = card;
			let tempwords = words?.filter((word) => {
				let match = currCard?.name?.toLowerCase().includes(word);
				return match;
			});
			return !!tempwords?.length;
		});
		setFilteredCategoriesList(tempCards);
		setCategoriesList(tempCards);
	}, [searchValue, selectedCategoriesList]);

	useEffect(() => {
		setCategoriesList(filteredCategoriesList);
	}, [filteredCategoriesList]);
  return (
    <div className="rounded-lg my-6 p-4 overflow-y-scroll ">
      <div className=" px-4 flex justify-between items-center">
        <h1 className=" text-blackTextlight text-3xl leading-9 font-bold ">Select Template</h1>
        <div className={isWhiteLabel ? 'hidden' : 'flex flex-row items-center'}>
          <div className="border-t-2 border-shadowColor w-24 mr-3"></div>

          <p className="text-sm text-mediumGray to-jacksonsPurpleleading-5 font-normal">OR</p>
          <div className="border-t-2 border-shadowColor w-24 ml-3"></div>
        </div>
        <button
          className={
            isWhiteLabel
              ? 'hidden'
              : ' text-white transition duration-300  hover:opacity-50 h-9 bg-gradient-to-r from-indigoodark to-jacksonsPurple rounded-md px-6 py-1  text-smleading-5 font-medium'
          }
          onClick={() => onCanvaPress()}
        >
          Design on canva
        </button>
      </div>
      <div className='pr-4  pl-6 my-2'>

      <input
        className=" border-gray-300 bg-white h-10 px-5 pr-6 pl-4 rounded-lg focus:outline-none placeholder:font-inter font-normal text-base leading-6 placeholder:text-C6B7280 w-full"
        style={{ borderWidth: '1px' }}
        type="search"
        placeholder="Enter category name"
        onChange={debouncedChangeHandler}
      />
      </div>
      <div className=" overflow-y-scroll" style={{ height: '80vh' }}>
        <div className="px-6 mt-3 flex gap-x-3 gap-y-2 flex-wrap text-center justify-start items-center ">
          <button
            className={
              currentUser?.isAnonymous === true
                ? 'hidden'
                : activeCategory?.name === userUploadsCategory?.name
                  ? 'px-4 py-2  bg-darkIndigo transition duration-500 text-lightIndigo rounded-md flex-shrink-0 hover:opacity-80'
                  : ' transition duration-500 hover:scale-105 transform px-4 py-2 bg-lightIndigo text-darkIndigo  bg-lightBlueHover rounded-md flex-shrink-0'
            }
            onClick={() => handleChange(userUploadsCategory?.name)}
          >
            {`${userUploadsCategory?.name} (${userUploadsCategory?.designImages?.length})`}
          </button>
          {categoriesList
            .slice(0, seeMore ? categoriesList.length : window.innerWidth > 1502 ? 8 : 6)
            .map((item, index, arr) => {
              return (
                <span key={item._id}>
                  <button
                    className={
                      item.name === activeCategory?.name
                        ? 'px-4 py-2  bg-darkIndigo transition duration-500 text-lightIndigo rounded-md flex-shrink-0 hover:opacity-80'
                        : ' transition duration-500 hover:scale-105 transform px-4 py-2 bg-lightIndigo text-darkIndigo  bg-lightBlueHover rounded-md flex-shrink-0'
                    }
                    onClick={() => handleChange(item.name)}
                  >
                    {' '}
                    {`${item.name} (${item?.designImages?.length})`}
                  </button>
                  <button
                    className="text-indigo text-md text-base hover:opacity-60 leading-5 font-medium ml-3 transition duration-300"
                    onClick={() => setseeMore(!seeMore)}
                    style={{ display: index === arr.length - 1 ? '' : 'none' }}
                  >
                    {!seeMore ? `+${categoriesList.length - arr.length} more` : 'See less'}
                  </button>
                </span>
              );
            })}
        </div>
        <CustomSpinnerLoader isLoading={loading} className="mt-6" height="55vh" />
        <div
          className={
            imagesList?.length || loading
              ? 'hidden'
              : 'w-full border-2 border-gray-300  border-dashed rounded-lg p-12 flex items-center justify-center mt-4 mb-4 '
          }
        // style={{ height: '50vh' }}
        >
          <ImagePicker handleImageUpload={handleImageUpload} />
        </div>
        <div
          className={
            loading || !imagesList?.length
              ? 'hidden'
              : 'flex flex-row pl-6 flex-wrap w-full gap-x-4 mt-4 gap-y-3 mb-4 justify-center '
          }
        // style={{ height: '50vh' }}
        >
          <ImagePicker handleImageUpload={handleImageUpload} />
          {!isLoading &&
            !!imagesList?.length &&
            imagesList?.map((value, index, array) => {
              // check if user uploaded images category covers
              let imageLocation; //store url of image
              if (value.location) {
                value = value.location
                imageLocation = imagesList[array.length - 1 - index].location
              }
              else {
                imageLocation = imagesList[array.length - 1 - index]
              }
              return (

                <div
                  onClick={(event) => handleImageClick(event, imageLocation)}
                  className="cursor-pointer"
                  key={`${value}_${index}`}
                >
                  {/* <ProgressiveImage
                    placeholder={imageProxyBlur + imageLocation}
                    uri={imageProxy + imageLocation}
                    render={(src, style) => (
                      <img
                        src={src}
                        style={{ height: '206px', width: '146px' }}
                        className="rounded-lg hover:opacity-80 hover:scale-105 transform transition duration-300"
                        alt="Design Images"
                      />
                    )}
                  /> */}
                  <img
                        src={imageLocation}
                        style={{ height: '206px', width: '146px' }}
                        className="rounded-lg hover:opacity-80 hover:scale-105 transform transition duration-300"
                        alt="Design Images"
                      />
                </div>
              );
            })}
        </div>
      </div>
      <CustomSwal closeSwal={CustomSwalResponseHandler} swalOptions={swalOptions} />
    </div>
  );
};
export default SelectTemplate;