import React from 'react';
import CardScheduledContainer from './Operations/OperationContainer';

interface Props {
	setTitleModel: React.Dispatch<React.SetStateAction<string>>;
	ModelTitle: string;
	progressSteps: any;
	setProgressSteps: React.Dispatch<any>;
}
const RightSideBar: React.FC<Props> = ({ ModelTitle, setTitleModel, setProgressSteps, progressSteps }) => {
	return (
		<>
			<div
				className=" rounded-md w-full p-6 flex flex-col gap-y-6 mb-4"
				style={{
					background: '#F9FAFB',
					boxShadow: '(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))',
				}}
			>
				<div className="font-inter font-extrabold text-3xl " style={{ color: '#111827' }}>
					Let’s send your card
				</div>
				<div className="w-full flex flex-col gap-y-6 ">
					<CardScheduledContainer
						ModelTitle={ModelTitle}
						setTitleModel={setTitleModel}
						setProgressSteps={setProgressSteps}
						progressSteps={progressSteps}
					/>
				</div>
			</div>
		</>
	);
};

export default RightSideBar;
