import React, { useContext } from 'react';
import { DesignToolContext } from '../../../../../../../../contexts/DesignTool/DesignToolContext';
import UserForm from './UserForm';

interface Props {
	closeEditModalHandler: () => void;
	updateRecipientHandler?: (e: any) => void;
	recipientEdit: boolean;
	recipientOnEdit?: any;
	recipientValueChangeHandler: (inputLabel: string, value: string) => void;
	isRecipientEditLoading: boolean;
}

const UserFormContainer: React.FC<Props> = ({
	updateRecipientHandler,
	recipientEdit,
	closeEditModalHandler,
	recipientOnEdit,
	recipientValueChangeHandler,
	isRecipientEditLoading,
}) => {
	const { mergeTags } = useContext(DesignToolContext);

	const computeMergeTags = (): Array<any> => {
		const modifiedMergeTags: any[] = [];
		if (mergeTags?.length) {
			[...mergeTags].forEach((item: any) => {
				if (!(item.tagName === 'name' || item.tagName === 'email')) {
					modifiedMergeTags.push(item);
				}
			});
		}
		return modifiedMergeTags;
	};

	return (
		<UserForm
			updateRecipientHandler={updateRecipientHandler}
			recipientEdit={recipientEdit}
			closeEditModalHandler={closeEditModalHandler}
			recipientOnEdit={recipientOnEdit}
			computeMergeTags={computeMergeTags}
			recipientValueChangeHandler={recipientValueChangeHandler}
			isRecipientEditLoading={isRecipientEditLoading}
		/>
	);
};

export default UserFormContainer;
