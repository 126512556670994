import React, { ReactNode } from 'react';
interface Props {
	children: ReactNode;
	onClose: () => void;
	open: boolean;
	className: string;
	modalContainer?: string;
	closeIcon: string;
	iconClassName?: string;
	stepper?: number;
	setStepper?(type: number): () => void;
	isWorkSpaceModal?: boolean;
  style?: any;
}

const NewModal: React.FC<Props> = ({
	children,
	onClose,
	open,
	className,
	modalContainer,
	// closeIcon,
	// iconClassName,
	stepper,
	setStepper,
	isWorkSpaceModal,
  style
}) => {
	return (
		<div className={open ? 'rounded-lg' : 'hidden'}>
			<div className="fixed z-50 inset-0 shadow-2xl bg-black bg-opacity-40 transition-opacity">
				<div className={modalContainer + ' flex flex-wrap place-items-center justify-center  mx-auto h-full'}>
					<div style={style}
						className={className + ' rounded-lg text-left shadow-xl transition-all duration-500 ease-in-out transform '}
					>
						<svg
							onClick={() => setStepper && setStepper(1)}
							className={
								stepper === 2 && isWorkSpaceModal
									? 'p-1  top-2 h-7 w-7 rounded-full absolute left-2 cursor-pointer hover:opacity-70'
									: 'hidden'
							}
							width="20"
							height="10"
							viewBox="0 0 20 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5 9L1 5M1 5L5 1M1 5L19 5"
								stroke="#6B7280"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<button onClick={onClose}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="p-1 top-2 h-7 w-7 rounded-full absolute right-2 cursor-pointer hover:opacity-40"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};
export default NewModal;
