import { SearchIcon } from '@heroicons/react/solid';
import { SetState } from '@shrugsy/use-immer-state';
import React from 'react';
import { useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import { BillingLimits } from '../../../services/request/billingLimits.interface';
import { EmailAccountInterface } from '../../../services/request/emailAccounts.interface';
import ScheduledRecipientsTableContainer from './components/ScheduledRecipientsTableContainer';
import CustomDropDownWithSelection from '../../../components/CustomDropdown/index';
import { TMergeTag } from '../../../contexts/DesignTool/types';
import NewModal from '../../../components/NewModal';
import useSvg from '../../../utils/useSvg';
import EmailPreview from './components/EmailPreview';
import ViewCardPreview from '../../../components/ViewCardPreview';
interface Props {
  handleSearchRecipients: (value: string) => void;
  onBackHandler: () => void;
  infiniteRef: any;
  recipientListLoading: boolean;
  initialLoading: boolean;
  handleScheduleCard: () => void;
  saveCardLoading: boolean;
  billingLimits: BillingLimits | null;
  setFromName: SetState<string>;
  setSubjectLine: SetState<string>;
  fromName: string;
  subjectLine: string;
  cardPreviewHeader: string;
  setCardPreviewHeader: SetState<string>;
  cardPreviewFooter: string;
  setCardPreviewFooter: SetState<string>;
  viewCardButtonColor: string;
  setViewCardButtonColor: SetState<string>;
  selectedEmailAccount: EmailAccountInterface | null;
  fetchProspectsAndProspectlist: () => void;
  updateLoading: boolean;
  setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onKeyDown: (e: any) => void;
  inputFieldOpenAndClose: boolean;
  handleInputFeildClick: (e: any) => void;
  mergeTags: TMergeTag[];
  handleAddMergeTag: (mergeTag: string) => void;
  inputRef: React.MutableRefObject<null>;
  openConfetti: boolean;
  card: any;
  setCTA: React.Dispatch<React.SetStateAction<boolean>>
  isCta: boolean
  setCTALink: React.Dispatch<React.SetStateAction<string>>
  ctaLink: string
  setCTAText: React.Dispatch<React.SetStateAction<string>>
  ctaText: string
  currentWorkspace: any
  isConfettiOn: boolean
  setConfetti: React.Dispatch<React.SetStateAction<boolean>>
}

const CardScheduled: React.FC<Props> = ({
  handleSearchRecipients,
  handleInputFeildClick,
  infiniteRef,
  recipientListLoading,
  initialLoading,
  handleScheduleCard,
  saveCardLoading,
  billingLimits,
  fromName,
  selectedEmailAccount,
  setFromName,
  fetchProspectsAndProspectlist,
  updateLoading,
  onKeyDown,
  setUpdateLoading,
  subjectLine,
  setSubjectLine,
  cardPreviewHeader,
  cardPreviewFooter,
  setCardPreviewHeader,
  setCardPreviewFooter,
  mergeTags,
  handleAddMergeTag,
  inputRef,
  card,
  setCTA,
  isCta,
  setCTALink,
  ctaLink,
  setCTAText,
  ctaText,
  currentWorkspace,
  setConfetti,
  isConfettiOn

}) => {
  const svgPath = '<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />';
  const closeIcon = useSvg('http://www.w3.org/2000/svg', '0 0 24 24', 'white', '#808080', svgPath);
  const [previewEmailModal, setpreviewEmailModal] = useState(false);
  const previewEmail = () => {
    setpreviewEmailModal(true);
  }
  const handleClose = () => {
    setpreviewEmailModal(false);
  };
  const [viewCardModal, setViewCardModal] = useState(false);
  const handleOpenViewCardModal = () => {
    setViewCardModal(true);
  }
  const handleCloseViewCard = () => {
    setViewCardModal(false);
  };
  const subjectLine1 = `Hey ${mergeTags[0].tagName}, ${fromName ? fromName : selectedEmailAccount?.fromName
    } created a digital card for you.`
  return (
    <>
      <div>
        <div className="flex justify-center w-full  ">
          <div
            className={
              initialLoading
                ? 'flex flex-col w-full border-2 animate-pulse border-gray200 h-22 sm:overflow-hidden sm:overflow-y-scroll overflow-y-scroll bg-white rounded-md mt-3'
                : 'hidden'
            }
          >
            <div className="bg-alto flex-none h-10 my-3 rounded mx-2"></div>
          </div>
          <div className={!initialLoading ? ' rounded bg-white  w-full flex items-center mt-3' : 'hidden'}>
            <div className="py-3 flex flex-col  md:flex-row w-full justify-between  ">
              <div className=' flex-col'>
                <label className="text-lg leading-6 font-medium text-blackText" htmlFor="email1">
                  {' '}
                  Who is this card from
                </label>

                <p className={!(Number(billingLimits?.email?.userLimit) >= 9999) ? 'text-sm text-gray' : 'hidden'}>
                  {`You have ${billingLimits?.email?.userLimit} recipient`}
                  {Number(billingLimits?.email?.userLimit) === 1 ? '' : 's'} left
                </p>
                <p className={Number(billingLimits?.email?.userLimit) >= 9999 ? 'text-sm text-gray' : 'hidden'}>
                  You have unlimited email recipient
                  {Number(billingLimits?.email?.userLimit) === 1 ? '' : 's'}
                </p>
                <div className="mt-4">
                  <div onClick={handleInputFeildClick}>Customize the subject of the email.</div>
                </div>
                <div className="mt-3 mb-3">
                  <div >Customize the Card Preview Header</div>
                </div>
                <div className="mt-4">
                  <div >Customize the Card Preview Footer</div>
                </div>
                <div className='mt-4'>
                  <div>Add CTA in Preview Card</div>
                </div>
                <div className='mt-4'>
                  <div>Toggle confetti</div>
                </div>
                <button className='mt-4 gap-2   hover:opacity-75 transition duration-300  text-indigo' onClick={() => {
                  previewEmail();

                }}>
                  How card looks in Email?
                </button>
                <div>
                  <button onClick={handleOpenViewCardModal} className='mt-4 gap-2   hover:opacity-75 transition duration-300  text-indigo'>How card to looks in View card screen?</button>
                </div>
              </div>
              <div className="flex flex-col  w-11/12 md:w-7/12 gap-y-2 ">
                <input
                  maxLength={25}
                  className="border border-shadowColor bg-white h-9 md:ml-8 pl-3 outline-none rounded-md text-sm leading-5 font-medium text-disabletext"
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Enter sender's name"
                  value={fromName ? fromName : selectedEmailAccount?.fromName}
                  onChange={(e: any) => setFromName(e.target.value)}
                />
                <div className="flex flex-row-reverse gap-x-2 ">
                  <div className="text-gray-700 text-sm leading-5 font-medium ">
                    <CustomDropDownWithSelection mergeTags={mergeTags} handleAddMergeTag={handleAddMergeTag} />
                  </div>
                  <input
                    className="border border-shadowColor placeholder:opacity-70 bg-white md:ml-8 pl-3 outline-none rounded-md text-sm leading-5  font-medium text-black ml-0 mr-auto w-full"
                    style={{ height: '34px' }}
                    type="text"
                    maxLength={70}
                    ref={inputRef}
                    id="companyName"
                    name="companyName"
                    placeholder={`Hey ${mergeTags[0].tagName}, ${fromName ? fromName : selectedEmailAccount?.fromName
                      } created a digital card for you.`}
                    value={subjectLine ? subjectLine : subjectLine}
                    onChange={(e: any) => setSubjectLine(e.target.value)}
                  />
                </div>
                <input
                  maxLength={100}
                  className="border border-shadowColor placeholder:opacity-70 bg-white h-9 md:ml-8 pl-3 outline-none rounded-md text-sm leading-5 font-medium text-disabletext"
                  type="text"
                  id=""
                  name="Card Preview Header"
                  placeholder="Card Preview Header"
                  value={cardPreviewHeader ? cardPreviewHeader : cardPreviewHeader}
                  onChange={(e: any) => setCardPreviewHeader(e.target.value)}
                />
                <input
                  maxLength={100}
                  className="border border-shadowColor placeholder:opacity-70 bg-white h-9 md:ml-8 pl-3 outline-none rounded-md text-sm leading-5 font-medium text-disabletext"
                  type="text"
                  id=""
                  name="Card Preview Footer"
                  placeholder="Card Preview Footer"
                  value={cardPreviewFooter ? cardPreviewFooter : cardPreviewFooter}
                  onChange={(e: any) => setCardPreviewFooter(e.target.value)}
                />
                <div className='flex gap-x-3 pl-8 w-full '>
                  <div className=" w-fit flex rounded-md text-center " >
                    <button className={isCta ? "rounded-l-md  border-l border-t border-b px-2 text-white py-1  font-medium text-xs transition duration-500 bg-green" : " rounded-l-md  px-2 text-indigoText py-1 font-medium border-l border-t border-b text-xs transition duration-500 bg-white"} onClick={() => setCTA(true)}>
                      Yes
                    </button>
                    <button className={isCta ? "rounded-r-md border-r border-t border-b px-3 text-indigoText py-1 font-medium text-xs transition duration-500  bg-white" : "rounded-r-md  px-3 text-white py-1  font-medium text-xs transition border-r  border-t border-b duration-500  bg-red"} onClick={() => setCTA(false)}>

                      No

                    </button>
                  </div>
                  <div className='w-full'>

                    <div className="w-full flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        https://
                      </span>
                      <input
                        type="text"
                        name="company-website"
                        id="company-website"
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="www.example.com"
                        value={ctaLink ? ctaLink : ctaLink}
                        onChange={(e: any) => setCTALink(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='w-full'>

                    <div className="w-full flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        Text
                      </span>
                      <input
                        type="text"
                        name="cta-text"
                        id="cta-text"
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Click here"
                        value={ctaText ? ctaText : ctaText}
                        onChange={(e: any) => setCTAText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="pl-8 h-9 flex rounded-md text-center " >
                    <button className={isConfettiOn ? "rounded-l-md  border-l border-t border-b px-2 text-white py-1  font-medium text-xs transition duration-500 bg-green" : " rounded-l-md  px-2 text-indigoText py-1 font-medium border-l border-t border-b text-xs transition duration-500 bg-white"} onClick={() => setConfetti(true)}>
                      Yes
                    </button>
                    <button className={isConfettiOn ? "rounded-r-md border-r border-t border-b px-3 text-indigoText py-1 font-medium text-xs transition duration-500  bg-white" : "rounded-r-md  px-3 text-white py-1  font-medium text-xs transition border-r  border-t border-b duration-500  bg-red"} onClick={() => setConfetti(false)}>

                      No

                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-center">
          <div className="flex w-full justify-center ">
            <div className="flex flex-col w-full bg-white ">
              <div className=" overflow-x-auto ">
                <div className="align-middle block sm:inline-block w-full ">
                  <div className="mt-6 mb-4 ">
                    <div className="flex items-center text-blackText text-md font-semibold gap-x-2">
                      <h3 className="mb-3">Card(s) ready to schedule</h3>
                    </div>
                    <div className="flex items-center text-mediumGray text-sm font-medium">
                      You can manually change the schedule for each card if you’re willing to do that{' '}
                    </div>
                  </div>
                  <div className="relative mb-4 py-2 flex items-center flex-grow focus-within:z-10 border-gray200 border-2 rounded-md ">
                    <div className="flex justify-center w-1/12 ">
                      <SearchIcon className="w-6 h-6 text-mediumGray" />
                    </div>
                    <input
                      type="searchimage"
                      name="searchimage"
                      id="searchimage"
                      // value={searchText}
                      onChange={(e) => handleSearchRecipients(e.target.value)}
                      onKeyDown={onKeyDown}
                      className="block w-11/12 relative text-mediumGray rounded-none focus:outline-none rounded-l-md sm:text-md border-shadowColor mr-2"
                      placeholder="Search recipients"
                    />
                  </div>
                  <div
                    className={
                      initialLoading
                        ? 'flex flex-col border-2 animate-pulse border-gray200 h-64 sm:overflow-hidden sm:overflow-y-scroll overflow-y-scroll bg-white rounded-md'
                        : 'hidden'
                    }
                  >
                    <div className="bg-alto flex-none h-10 my-3 rounded mx-2"></div>
                    <div className="bg-alto flex-1 my-3 rounded mx-2"></div>
                  </div>
                  <div className={initialLoading ? 'hidden' : ''}>
                    <ScheduledRecipientsTableContainer
                      infiniteRef={infiniteRef}
                      recipientListLoading={recipientListLoading}
                      fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
                      setUpdateLoading={setUpdateLoading}
                    />
                  </div>
                  <div className="mt-12 flex justify-end mb-4 ">
                    <div className="flex ">
                      <div className=" ">
                        <LoadingButton
                          onClick={handleScheduleCard}
                          isLoading={saveCardLoading}
                          disabled={saveCardLoading || updateLoading}
                          className="gap-2 text-white  flex items-center hover:opacity-75 transition duration-300 w-fit justify-center border-2 rounded-md px-3 py-2 bg-indigo"
                        >
                          Send card
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewModal open={previewEmailModal} closeIcon={closeIcon} onClose={handleClose} className="w-4/6 bg-white relative">
          <EmailPreview imgUrl={card?.currentCardData?.designUrl} header={cardPreviewHeader} footer={cardPreviewFooter} viewCardButtonColor={currentWorkspace?.previewSetting?.buttonColor} subjectLine={subjectLine1} isRounded={currentWorkspace?.previewSetting?.buttonRounded}/>
        </NewModal>
        <NewModal open={viewCardModal} closeIcon={closeIcon} onClose={handleCloseViewCard} style={{ background: currentWorkspace?.previewSetting ? currentWorkspace?.previewSetting?.solid ? currentWorkspace?.previewSetting?.solidColor : `radial-gradient(at center bottom, ${currentWorkspace?.previewSetting?.startColor}, ${currentWorkspace?.previewSetting?.endColor})`:'#fff' }} className="w-4/6 bg-white relative">
          <ViewCardPreview subjectLine1={subjectLine1} cardPreviewHeader={cardPreviewHeader} isCta={isCta} ctaText={ctaText} ctaLink={ctaLink}/>
        </NewModal>
      </div>
    </>
  );
};

export default CardScheduled;
