export const setCurrentCardData = (value: any) => {
	return {
		type: 'CURRENT_CARD_DATA',
		payload: value,
	};
};

export const setCurrentCardId = (value: string | null) => {
	return {
		type: 'CURRENT_CARD_ID',
		payload: value,
	};
};

export const setUploadedImageData = (value: any) => {
	return {
		type: 'UPLOADED_IMAGE_DATA',
		payload: value,
	};
};

export const setCardPagesRdx = (value: any) => {
	return {
		type: 'CARD_PAGES',
		payload: value,
	};
};
