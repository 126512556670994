import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewDrawer from '../../../../components/NewDrawer';
import useWhitelabelSettings from '../../../../hooks/useWhitelabelSettings';
import { ROUTE_NAMES } from '../../../../Routes/routeNames';
import { firebaseAuthServices } from '../../../../services/firebaseAuth';
import { AuthContext } from '../../../../utils/auth';
import useComponentVisible from '../../../../utils/useComponentVisible';

const MenuDrop = () => {
	const { currentUser } = useContext(AuthContext);
	const userProfile = useSelector((state) => state.userProfile);
	const currentWorkspace = useSelector((state) => state.currentWorkspace);
	const whiteLabelSettings = useSelector((state) => state.whiteLabelSettings);
	const [userRole, setUserRole] = useState(null);
	const { isWhiteLabel } = useWhitelabelSettings();
	useEffect(() => {
		if (!!currentWorkspace) {
			if (currentWorkspace?.owner_id === userProfile?._id) {
				setUserRole('superAdmin');
			} else {
				const role = currentWorkspace?.member?.find((item) => item.email === userProfile?.email)?.role;
				if (!!role) {
					setUserRole(role);
				} else {
					console.log('cannot get role for this user');
				}
			}
		}
	}, [currentWorkspace, userProfile]);

	const userEmail = currentUser?.email || '';

	const navBarList = [
		{
			title: 'Your Profile',
			path: ROUTE_NAMES.profile,
			iconLink: (c) => (
				<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
					<path
						d="M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.5523 5.44772 21 6 21H9M19 10L21 12M19 10V20C19 20.5523 18.5523 21 18 21H15M9 21C9.55228 21 10 20.5523 10 20V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V20C14 20.5523 14.4477 21 15 21M9 21H15"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			isVisible: true,
		},
		{
			title: 'Billing',
			path: ROUTE_NAMES.billing,
			iconLink: (c) => (
				<svg width="20" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
					<path
						d="M6 5V1M14 5V1M5 9H15M3 19H17C18.1046 19 19 18.1046 19 17V5C19 3.89543 18.1046 3 17 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19Z"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			isVisible: userRole === 'superAdmin',
		},
		{
			title: 'Generate Zapier key',
			path: ROUTE_NAMES.generatezapierkey,
			iconLink: (c) => (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="26"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="#9CA3AF"
					className=" mr-3"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
					/>
				</svg>
			),
			isVisible: true,
		},
		{
			title: 'Refer & Earn',
			path: ROUTE_NAMES.refer,
			iconLink: (c) => (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="22"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="#9CA3AF"
					className=" mr-3"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
			),
			isVisible: !isWhiteLabel,
		},
		{
			title: 'Manage Workspaces',
			path: ROUTE_NAMES.manageworkspace,
			iconLink: (c) => (
				<svg
					width="20"
					height="22"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className=" mr-3"
				>
					<path
						d="M9 3.88218V17.2402C9 18.2121 8.2121 19 7.24018 19C6.49646 19 5.83302 18.5325 5.58288 17.8321L3.43647 11.6829M16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5M3.43647 11.6829C2.0043 11.0741 1 9.65433 1 8C1 5.79086 2.79086 4 4.99999 4H6.83208C10.9327 4 14.4569 2.7659 16 1L16 15C14.4569 13.2341 10.9327 12 6.83208 12L4.99998 12C4.44518 12 3.91677 11.887 3.43647 11.6829Z"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			isVisible: true,
		},

		{
			title: 'Basic Settings',
			path: ROUTE_NAMES.workspacesettings,
			iconLink: (c) => (
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
					<path
						d="M7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L7.29289 15.2929ZM15 11C15 12.1046 14.1046 13 13 13V15C15.2091 15 17 13.2091 17 11H15ZM11 11C11 9.89543 11.8954 9 13 9V7C10.7909 7 9 8.79086 9 11H11ZM13 9C14.1046 9 15 9.89543 15 11H17C17 8.79086 15.2091 7 13 7V9ZM10.1716 12.4142L7.29289 15.2929L8.70711 16.7071L11.5858 13.8284L10.1716 12.4142ZM13 13C12.4474 13 11.9488 12.7772 11.5858 12.4142L10.1716 13.8284C10.8943 14.5512 11.8957 15 13 15V13ZM11.5858 12.4142C11.2228 12.0512 11 11.5526 11 11H9C9 12.1043 9.44881 13.1057 10.1716 13.8284L11.5858 12.4142ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4Z"
						fill="#9CA3AF"
					/>
				</svg>
			),
			isVisible: userRole === 'superAdmin',
		},
		{
			title: 'Team Members',
			path: ROUTE_NAMES.team,
			iconLink: (c) => (
				<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
					<path
						d="M17 20H22V18C22 16.3431 20.6569 15 19 15C18.0444 15 17.1931 15.4468 16.6438 16.1429M17 20H7M17 20V18C17 17.3438 16.8736 16.717 16.6438 16.1429M7 20H2V18C2 16.3431 3.34315 15 5 15C5.95561 15 6.80686 15.4468 7.35625 16.1429M7 20V18C7 17.3438 7.12642 16.717 7.35625 16.1429M7.35625 16.1429C8.0935 14.301 9.89482 13 12 13C14.1052 13 15.9065 14.301 16.6438 16.1429M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM21 10C21 11.1046 20.1046 12 19 12C17.8954 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8C20.1046 8 21 8.89543 21 10ZM7 10C7 11.1046 6.10457 12 5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8C6.10457 8 7 8.89543 7 10Z"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			isVisible: true,
		},
		{
			title: 'Email Accounts',
			path: ROUTE_NAMES.emailaccounts,
			iconLink: (c) => (
				<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
					<path
						d="M17 20H22V18C22 16.3431 20.6569 15 19 15C18.0444 15 17.1931 15.4468 16.6438 16.1429M17 20H7M17 20V18C17 17.3438 16.8736 16.717 16.6438 16.1429M7 20H2V18C2 16.3431 3.34315 15 5 15C5.95561 15 6.80686 15.4468 7.35625 16.1429M7 20V18C7 17.3438 7.12642 16.717 7.35625 16.1429M7.35625 16.1429C8.0935 14.301 9.89482 13 12 13C14.1052 13 15.9065 14.301 16.6438 16.1429M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM21 10C21 11.1046 20.1046 12 19 12C17.8954 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8C20.1046 8 21 8.89543 21 10ZM7 10C7 11.1046 6.10457 12 5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8C6.10457 8 7 8.89543 7 10Z"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			),
			isVisible: true,
		},
	];

	const mobileNavBarList = [
		{
			title: 'Dashboard',
			path: ROUTE_NAMES.dashboard,
		},
		{
			title: 'My Cards',
			path: ROUTE_NAMES.mycards,
		},
		{
			title: 'Settings',
			path: ROUTE_NAMES.settings,
		},
	];

	const { ref } = useComponentVisible(false);

	const handleSignout = () => {
		firebaseAuthServices.signOut();
		if (!whiteLabelSettings) {
			const userEmail = userProfile?.email;
			if (userProfile?.email) {
				window.analytics.track('Signout', {
					userEmail,
				});
				window.hyperengage('track', 'Signout', {
					properties: {
						userEmail: userEmail,
					},
				});
			}
		}
	};

	return (
		<div className="relative  inline-block text-left" ref={ref}>
			<NewDrawer
				navBarList={navBarList}
				userProfile={userProfile}
				userEmail={userEmail}
				userRole={userRole}
				handleSignout={handleSignout}
				mobileNavBarList={mobileNavBarList}
			/>
		</div>
	);
};

export default memo(MenuDrop);
