import React from 'react';
// import { boolean } from 'yup/lib/locale'
import NotifySwal from './NotifySwalView';
import WarningSwal from './WarningSwalView';
import ErrorSwal from './ErrorSwalView';
import ErrorWarning from './ErrorWarningView';

type Options = {
	visible: boolean;
	title: string;
	message: string;
	swalType: string;
};
interface Props {
	swalOptions: Options;
	closeSwal: () => void;
	customSwalResponseHandler?: any;
}
const CustomSwalContainer: React.FC<Props> = ({ swalOptions, closeSwal, customSwalResponseHandler }) => {
	return (
		<>
			{swalOptions.swalType === 'warning' ? (
				<WarningSwal
					customSwalResponseHandler={customSwalResponseHandler}
					swalOptions={swalOptions}
					closeSwal={closeSwal}
				/>
			) : swalOptions.swalType === 'success' ? (
				<NotifySwal swalOptions={swalOptions} closeSwal={closeSwal} />
			) : swalOptions.swalType === 'error' ? (
				<ErrorSwal swalOptions={swalOptions} closeSwal={closeSwal} />
			) : swalOptions.swalType === 'errorWarning' ? (
				<ErrorWarning swalOptions={swalOptions} closeSwal={closeSwal} />
			) : (
				''
			)}
		</>
	);
};

export default CustomSwalContainer;
