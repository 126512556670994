import React, { useContext } from 'react';
// import ProgressiveImage from '../../../../../../../components/ProgressiveImage';
import { DesignToolContext } from '../../../../../../../contexts/DesignTool/DesignToolContext';
import { TemplateInterface } from '../../../../../../../services/request/template.interface';
interface Props {
	template: TemplateInterface;
	handleTemplatetoCard: (template: TemplateInterface) => void;
	TemplatetoCardLoading: Boolean;
	selectedTemplateid: String | null;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateInterface | null>>;
}
const TemplateImage: React.FC<Props> = ({
	template,
	handleTemplatetoCard,
	TemplatetoCardLoading,
	selectedTemplateid,
	setSelectedTemplate,
}) => {
	const { setPreviewCardOptions } = useContext(DesignToolContext);
	// const imageProxy = 'https://image.cardclan.io/api/imageproxy/150x,q140/';
	// const imageProxyBlur = 'https://image.cardclan.io/api/imageproxy/10x,q40/';
	const handlePreviewModal = (template: TemplateInterface) => {
		setSelectedTemplate(template);
		setPreviewCardOptions((prev) => {
			prev.modalOpen = true;
		});
	};
	return (
		<div className="flex flex-col w-56  ">
			<div className="relative " style={{ width: '200px' }}>
				<div
					className="w-44  ml-1 bg-white absolute left-1.5  rounded-md  z-20"
					style={{ height: '236px', transform: 'rotate(5deg)', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
				></div>
				<div
					className="w-44  ml-2 absolute bg-white  left-2.5 rounded-md  z-10"
					style={{ height: '236px', transform: 'rotate(8deg)', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
				></div>
				<div
					className="w-44  ml-3 absolute rotate-90 left-3.5  bg-white rounded-md  z-0"
					style={{ height: '236px', transform: 'rotate(10deg)', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
				></div>
				{/* <ProgressiveImage
					placeholder={imageProxyBlur + template.card.designUrl}
					uri={imageProxy + template.card.designUrl}
					render={(src: any) => <img src={src} className="rounded-md  z-30 relative w-44" alt="Design Templates" />}
				/> */}
        <img src={template.card.designUrl} className="rounded-md  z-30 relative w-44" alt="Design Templates" />
			</div>

			<div title={template.card.title ? template.card.title : 'Untitled'} className="text-base font-semibold text-textareacolor mt-2  w-48 truncate">
				{template.card.title ? template.card.title : 'Untitled'}
			</div>
			<div className="mb-2">
			</div>
			<div className="flex gap-x-1 justify-start">
				<button
					onClick={() => handlePreviewModal(template)}
					className="bg-previewlight px-2 py-1 flex items-center gap-x-1  hover:opacity-80 transition rounded duration-300"
					style={{ borderRadius: '4px' }}
				>
					<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.99999 6.39961C7.77318 6.39961 8.39998 5.77281 8.39998 4.99961C8.39998 4.22641 7.77318 3.59961 6.99999 3.59961C6.22679 3.59961 5.59999 4.22641 5.59999 4.99961C5.59999 5.77281 6.22679 6.39961 6.99999 6.39961Z"
							fill="#0C2A90"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0.320435 4.99964C1.21241 2.15965 3.86561 0.0996094 6.99996 0.0996094C10.1343 0.0996094 12.7875 2.15962 13.6795 4.99958C12.7875 7.83957 10.1343 9.89961 6.99994 9.89961C3.86561 9.89961 1.21242 7.8396 0.320435 4.99964ZM9.79998 4.99961C9.79998 6.54601 8.54638 7.79961 6.99999 7.79961C5.45359 7.79961 4.19999 6.54601 4.19999 4.99961C4.19999 3.45321 5.45359 2.19961 6.99999 2.19961C8.54638 2.19961 9.79998 3.45321 9.79998 4.99961Z"
							fill="#0C2A90"
						/>
					</svg>
					<p className="text-sm">Preview</p>
				</button>
				<button
					onClick={() => handleTemplatetoCard(template)}
					className="bg-indigoText px-2 py-1 flex items-center gap-x-2  hover:opacity-80 transition duration-300"
					style={{ borderRadius: '4px' }}
				>
					<svg
						className={TemplatetoCardLoading && template._id === selectedTemplateid ? 'hidden' : ''}
						width="10"
						height="11"
						viewBox="0 0 10 11"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.13386 0.866117L9.48741 0.512563V0.512563L9.13386 0.866117ZM2.25 9.51774V10.0177C2.38261 10.0177 2.50979 9.96506 2.60355 9.87129L2.25 9.51774ZM0.5 9.51774H0C0 9.79388 0.223858 10.0177 0.5 10.0177L0.5 9.51774ZM0.5 7.73221L0.146447 7.37865C0.0526784 7.47242 0 7.5996 0 7.73221H0.5ZM7.71964 1.21967C8.01254 0.926777 8.48741 0.926777 8.7803 1.21967L9.48741 0.512563C8.80399 -0.170854 7.69595 -0.170854 7.01254 0.512563L7.71964 1.21967ZM8.7803 1.21967C9.0732 1.51256 9.0732 1.98744 8.7803 2.28033L9.48741 2.98744C10.1708 2.30402 10.1708 1.19598 9.48741 0.512563L8.7803 1.21967ZM8.7803 2.28033L1.89645 9.16419L2.60355 9.87129L9.48741 2.98744L8.7803 2.28033ZM2.25 9.01774H0.5V10.0177H2.25V9.01774ZM7.01254 0.512563L0.146447 7.37865L0.853553 8.08576L7.71964 1.21967L7.01254 0.512563ZM0 7.73221V9.51774H1V7.73221H0ZM6.26254 1.96967L8.0303 3.73744L8.73741 3.03033L6.96964 1.26256L6.26254 1.96967Z"
							fill="white"
						/>
					</svg>
					<p className={TemplatetoCardLoading && template._id === selectedTemplateid ? 'hidden' : 'text-sm text-white'}>
						Use
					</p>
					<svg
						className={TemplatetoCardLoading && template._id === selectedTemplateid ? 'animate-spin w-5 ' : 'hidden'}
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="white"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default TemplateImage;
