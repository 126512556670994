import React from 'react';
import { Layer, Stage, Text } from 'react-konva';
import ViewImage from './ViewImage';
import CallToAction from './CallToAction';
import Gif from './Gif';
import Rects from './Rect';

const CardPage = ({ stage, pageData, pageId }) => {
  return (
    <Stage
      ref={(ref) => {
        if (ref) {
          ref.getStage().container().style.backgroundColor = 'white';
          ref.getStage().container().style.backfaceVisibility = 'hidden';
        }
      }}
      // style={style}
      {...stage}
    >
      <Layer ref={(ref) => ref?.getLayer().listening(false)} id={`layer${pageId}`} key={pageId}>
        {pageData?.rects?.map((rectProps, ind) => (
          <Rects key={ind} rectProps={rectProps} />
        ))}
        {pageData?.images?.map((image, ind) => (
          <ViewImage imageObj={image} ind={ind} key={ind} />
        ))}
        {pageData?.gifs?.map((gifProps, ind) => (
          <Gif key={ind} gifProps={gifProps} />
        ))}
        {pageData?.textBoxes?.map((txbox, ind) => (
          <Text key={ind} listening={false} perfectDrawEnabled={false} {...txbox} draggable={false} />
        ))}
        {pageData?.ctas?.map((ctaProps, ind) => (
          <CallToAction key={ind} ctaProps={ctaProps} />
        ))}
      </Layer>
    </Stage>
  );
};

export default React.memo(CardPage);
