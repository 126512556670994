/*
	ANCHOR - cannot export firebase User Interface from another file
	reason: firebase re-exporting a type when the '--isolatedmodules' flag is provided requires using 'export type'
*/
import { User } from '@firebase/auth';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import CustomLoader from '../components/CustomLoader';
import firebaseApp, { getAuth, onIdTokenChanged, getIdToken } from '../config/firebase';
import { setUserIdToken } from '../services/request/axios';
export const AuthContext = React.createContext(null as any);

export const AuthProvider = ({ children }: any) => {
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [pending, setPending] = useState(true);
	const [idToken, setIdToken] = useState<string | null>(null);

	useEffect(() => {
		const unsub = onIdTokenChanged(
			getAuth(firebaseApp),
			(user) => {
				setCurrentUser(user);
				if (user) {
					getIdToken(user, true)
						.then((idToken) => {
							setUserIdToken(idToken);
							setIdToken(idToken);
							setPending(false);
						})
						.catch((error) => {
							console.log('idtoken error', error);
						});
				} else {
					setUserIdToken('');
					setIdToken(null);
					setPending(false);
				}
			},
			(err: any) => swal(err)
		);
		return () => {
			unsub();
		};
	}, []);

	if (pending) {
		return <CustomLoader key="CustomLoader" />;
	}
	return (
		<AuthContext.Provider key="AuthContext.Provider" value={{ currentUser, idToken, setIdToken }}>
			{children}
		</AuthContext.Provider>
	);
};
