import { DocumentAddIcon } from '@heroicons/react/solid';
import React from 'react';

interface Props {
	handleFileUpload: (e: any) => void;
}

const UploadButton: React.FC<Props> = ({ handleFileUpload }) => {
	return (
		<div className="mt-4">
			<h3 className="font-medium mb-2">CSV File</h3>
			<label className="cursor-pointer">
				<div className="relative block w-full border-2 border-gray-300 hover:bg-gray-50 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none">
					<div className="flex justify-center">
						<DocumentAddIcon className="w-10 h-10 text-mediumGray" />
					</div>
					<span className="mt-2 block text-xl font-medium text-mediumGray">Click to upload your csv file</span>
				</div>
				<input type="file" accept=".csv" className="opacity-0" onChange={handleFileUpload} />
			</label>
		</div>
	);
};

export default UploadButton;
