// First we need to import axios.js
import axios from 'axios';
import { firebaseAuthServices } from '../firebaseAuth';
// Next we make an 'instance' of it
const instance = axios.create({
	// baseURL: 'http://localhost:5000/',
	// baseURL: 'https://edca-203-175-78-24.ngrok.io/',
	baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.response.use(
	(response) => response,
	async(error) => {
		// whatever you want to do with the error
		if (error?.response?.status === 401) {
			firebaseAuthServices.signOut();
      // const token = await firebaseAuthServices.refrestToken()
      // setUserIdToken(token)
		}
		if (error?.response?.data) {
			throw error?.response?.data;
		} else {
			console.error('Network connection error. Please check you internet connection or try to log in again.');
			throw new Error('Network connection error. Please check you internet connection or try to log in again.');
		}
	}
);

instance.defaults.headers.post['Content-Type'] = 'application/json';
// instance.defaults.headers.get['Content-Type'] = 'application/json';

// instance.defaults.headers['Content-Type'] = 'application/json';

// Where you would set stuff like your 'Authorization' header, etc ...

//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM instance';

// Also add/ configure interceptors && all the other cool stuff

//instance.interceptors.instance...
/**
 * attaches the logged in user's firebase `idToken` to the authentication header
 */
export const setUserIdToken = (idtk: string) => {
	instance.defaults.headers.common['Authorization'] = `Bearer ${idtk}`;
};
export default instance;
