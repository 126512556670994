const defaultState: any = {
	currentCardData: {},
	currentCardId: '',
	uploadedImageData: null,
};

const cardReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'CURRENT_CARD_DATA':
			return {
				...state,
				currentCardData: action.payload,
			};

		case 'CURRENT_CARD_ID':
			return {
				...state,
				currentCardId: action.payload,
			};

		case 'UPLOADED_IMAGE_DATA':
			return {
				...state,
				uploadedImageData: action.payload,
			};

		case 'CARD_PAGES':
			return {
				...state,
				currentCardData: {
					...state.currentCardData,
					cardPages: action.payload,
				},
			};

		default:
			return state;
	}
};

export default cardReducer;
