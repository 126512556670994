import React from 'react';
import ScheduleSkipContinueModal from './ScheduleSkipContinueModal';

interface Props {
	openScheduleSkipContinueModalHandler: () => void;
	closeScheduleSkipContinueModalHandler: () => void;
	closeSceduleLaterModalHandler: () => void;
	openScheduleSkipContinueModal: boolean;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const ScheduleSkipContinueModalContainer: React.FC<Props> = ({
	openScheduleSkipContinueModal,
	closeScheduleSkipContinueModalHandler,
	openScheduleSkipContinueModalHandler,
	setTitleModel,
	setProgressSteps,
	progressSteps,
}) => {
	return (
		<ScheduleSkipContinueModal
			openScheduleSkipContinueModal={openScheduleSkipContinueModal}
			closeScheduleSkipContinueModalHandler={closeScheduleSkipContinueModalHandler}
			openScheduleSkipContinueModalHandler={openScheduleSkipContinueModalHandler}
			setTitleModel={setTitleModel}
			setProgressSteps={setProgressSteps}
			progressSteps={progressSteps}
		/>
	);
};

export default ScheduleSkipContinueModalContainer;
