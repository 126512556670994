import { CheckIcon, CodeIcon, InformationCircleIcon, XIcon } from '@heroicons/react/solid';
import React from 'react';
import ListDropDown from '../../../../../../../../components/ListDropDown';

interface Props {
	addNewMergeTag: boolean;
	toggleAddMergeTag: () => void;
	csvFields: Array<string> | null;
	mergeTags: any;
	setnewMergeTagName: React.Dispatch<React.SetStateAction<string>>;
	newMergeTagName: string;
	setNewMergeTagColName: React.Dispatch<React.SetStateAction<string>>;
	newMergeTagColName: string;
	handleDropdownValueChange: (tagName: string, colName: string) => void;
	handleAddMergeTag: (tagName: string, colName: string) => void;
}

const MergeTagList: React.FC<Props> = ({
	addNewMergeTag,
	toggleAddMergeTag,
	mergeTags,
	csvFields,
	handleDropdownValueChange,
	setnewMergeTagName,
	newMergeTagName,
	setNewMergeTagColName,
	newMergeTagColName,
	handleAddMergeTag,
}) => {
	return (
		<div className="mt-4">
			<div className="flex gap-2">
				<h3 className="text-sm leading-6 text-mediumGray gap-x-1 flex justify-start items-center">
					<p>SELECT MERGE TAGS</p>
				</h3>
				<div className="group md:block hidden">
					<InformationCircleIcon className="w-6 h-6 text-indigo" />
					<div className="tooltip-text bg-indigo w-80 text-white p-3 rounded hidden group-hover:block absolute text-left py-2 px-6 z-50">
						<p className="font-medium">What is a merge tag?</p>
						<p className="text-sm">
							Merge tags are personalized tags mapped with your bulk data to personalize documents, graphics etc.
						</p>
					</div>
				</div>
			</div>
			{mergeTags?.map((item: any) => (
				<div key={item.tagName} className="flex justify-between mt-5">
					<div className="flex items-center w-1/4">
						<span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
							{item.tagName}
						</span>
					</div>
					<div className="flex items-center w-3/4">
						<ListDropDown
							data={csvFields}
							selectedItem={item.colName}
							handleChange={handleDropdownValueChange}
							mergeTagName={item.tagName}
						/>
					</div>
				</div>
			))}
			<div className={addNewMergeTag ? 'p-2 bg-lightgray flex items-center gap-2 rounded-md mt-5' : 'hidden'}>
				<input
					className="shadow-sm focus:ring-indigo-500 focus:border-indigo block w-full sm:text-sm border-gray-300 rounded-md"
					type="text"
					required
					name="mergeTag"
					// color="secondary"
					placeholder="Enter tag name"
					value={newMergeTagName}
					onChange={(e: any) => setnewMergeTagName(e.target.value)}
				/>
				<ListDropDown
					selectedItem={newMergeTagColName}
					data={csvFields}
					handleChange={(value: string) => setNewMergeTagColName(value)}
				/>
				<button
					onClick={() => handleAddMergeTag(newMergeTagName, newMergeTagColName)}
					className="bg-successIconColor rounded-md flex justify-center items-center w-28 h-10"
					disabled={newMergeTagColName === 'Select value' || !newMergeTagName}
				>
					<CheckIcon className="w-5 h-5 text-white" />
				</button>
				<button
					onClick={toggleAddMergeTag}
					title="Button"
					className="bg-white rounded-md flex justify-center items-center border border-grey w-28 h-10"
				>
					<XIcon className="w-5 h-5 " />
				</button>
			</div>
			<button onClick={toggleAddMergeTag} disabled={addNewMergeTag} className="flex gap-2 items-center mt-5">
				<CodeIcon className="w-5 h-5 text-indigo" />
				<p className="text-indigo hover:underline hover:decoration-indigobtnhovr hover:text-indigobtnhovr">
					Add new merge tag
				</p>
			</button>
		</div>
	);
};

export default MergeTagList;
