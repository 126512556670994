import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import CustomSwalContainer from '../../../components/CustomSwal/CustomSwalContainer';
import HeadlessModal from '../../../components/HeadlessModal/HeadlessModal';
import LoadingButton from '../../../components/LoadingButton';
import { EmailAccountContext } from '../../../contexts/CardProspects/emailAccountContext';
import { FromNameContext } from '../../../contexts/CardProspects/fromName';
import useWhitelabelSettings from '../../../hooks/useWhitelabelSettings';
import { setEmailAccountRdx } from '../../../redux/actions';
import { billing_limit_services } from '../../../services/request/billingLimits';
import { BillingLimits } from '../../../services/request/billingLimits.interface';
import { email_account_services } from '../../../services/request/emailAccounts';
import { EmailAccountInterface } from '../../../services/request/emailAccounts.interface';
import AccountsModal from '../../LoggedInLayout/Settings/EmailAccounts/Components/AccountsModal';
import frame from '../../../assets/images/Frame.png';

interface Props {
	setTitleModel: any;
	setProgressSteps: Dispatch<any>;
	progressSteps: any;
}
const SenderDetail: React.FC<Props> = ({ setTitleModel, setProgressSteps, progressSteps }) => {
	const [accountsModal, setAccountsModal] = useState<boolean>(false);
	const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
	const emailAccountRdx: EmailAccountInterface[] = useSelector((state: any) => state.emailAccountRdx);
	const userProfile = useSelector((state: any) => state.userProfile);
	const [limits, setlimits] = useState<BillingLimits | null>(null);
	const { setSelectedEmailAccount, selectedEmailAccount } = useContext(EmailAccountContext);
	const { setFromName } = useContext(FromNameContext);
	const [swalOptions, setSwalOptions] = useState({
		visible: false,
		title: '',
		message: '',
		swalType: '',
	});
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const { isWhiteLabel } = useWhitelabelSettings();
	useEffect(() => {
		if (currentWorkspace?.owner_id) {
			/** Fetching all the billing limits in the plan. */
			billing_limit_services
				.getBillingLimits(currentWorkspace?.owner_id)
				.then((res: any) => {
					setlimits(res);
					email_account_services
						.getEmailAccount(currentWorkspace?._id)
						.then((res: any) => {
							dispatch(setEmailAccountRdx([...res?.data]));
						})
						.catch((err: any) => {
							console.log(err.message);
						});
				})
				.catch((e: any) => console.log('error occurred while fetching billing limits', e));
		}
	}, [currentWorkspace?._id, dispatch, userProfile._id, currentWorkspace?.owner_id]);

	const handleAccountClose = () => {
		setAccountsModal(false);
	};

	const changeHandler = (emailAccount: EmailAccountInterface) => {
		if (selectedEmailAccount?.fromEmail === emailAccount.fromEmail) {
			setFromName('');
			setSelectedEmailAccount(null);
			return;
		}
		setFromName(emailAccount.fromName);
		setSelectedEmailAccount(emailAccount);
	};
	// const closeIcon = useSvg('http://www.w3.org/2000/svg', '0 0 24 24', 'white', '#808080', svgPath);
	//When selected email
	const handleEmailNext = () => {
		if (emailAccountRdx?.length < 1) {
			setSwalOptions({
				visible: true,
				title: 'Error',
				message: `Please connect your personal email address ${
					isWhiteLabel ? '' : 'or send using our default account.'
				} `,
				swalType: 'error',
			});

			return;
		} else if (!selectedEmailAccount) {
			setSwalOptions({
				visible: true,
				title: 'Error',
				message:
					' Please select a custom email account to schedule the card or select the option to send using our default email.',
				swalType: 'error',
			});
			return;
		}
		setTitleModel('Customize the subject line');
		const newArray = [...progressSteps];
		const findStatus = newArray?.includes('2');
		if (!findStatus) {
			newArray.push('2');
			setProgressSteps(newArray);
		}
	};
	//Send using default emailing system
	const handleSendEmailUsingDefaultSystem = () => {
		setSelectedEmailAccount(null);
      if (userProfile.firstName === '' && userProfile.lastName === '') {
        setFromName('CardClan Greetings');
      }
      else {
        setFromName(userProfile.firstName +' '+ userProfile.lastName);
      }
		setTitleModel('Customize the subject line');
		const newArray = [...progressSteps];
		const findStatus = newArray?.includes('2');
		if (!findStatus) {
			newArray.push('2');
			setProgressSteps(newArray);
		}
	};

	const handleOpenAddNewAccount = () => {
		console.log('On open add new account');
		//TODO: Add custom swal instead of native swal - @wajahat
		if (!limits?.emailAccounts) {
			swal('Error', 'Your plan does not allow adding custom email accounts. Please updgrade your plan', 'error');
			return;
		}
		if (Number(limits?.emailAccounts?.userLimit) < 1) {
			swal(
				'Error',
				'Your email accounts limit has reached. Please updgrade your plan to add more email accounts',
				'error'
			);
			return;
		}
		setAccountsModal(true);
	};
	const closeSwal = () => {
		setSwalOptions({
			visible: false,
			title: '',
			message: '',
			swalType: '',
		});
	};

	return (
		<div className="w-full">
			<div className="w-full flex flex-col gap-y-7">
				<div className="flex flex-col gap-y-2">
					<h1 className="text-lg  text-blackText leading-6 font-normal">Select an Email account </h1>
					<p className="text-sm text-mediumGray leading-4 font-medium">
						Select your preferred email account to send cards from.
					</p>
				</div>
				<div
					className={emailAccountRdx.length > 0 ? 'flex justify-between gap-x-3 flex-col md:flex-row' : 'flex flex-col'}
				>
					<div className={emailAccountRdx.length > 0 ? 'flex flex-col gap-y-4 ' : 'flex flex-col items-center gap-y-4'}>
						<div className={emailAccountRdx.length > 0 ? 'hidden' : ''}>
							<img src={frame} alt="" />
						</div>
						<div>
							{!!emailAccountRdx?.filter((item) => currentWorkspace._id === item.workspaceId).length ? (
								emailAccountRdx
									.filter((item) => currentWorkspace._id === item.workspaceId)
									.map((emailAccount) => {
										return (
											<div key={emailAccount.fromEmail} className="flex items-center mb-1  ">
												<input
													type="checkbox"
													onChange={() => changeHandler(emailAccount)}
													checked={selectedEmailAccount?.fromEmail === emailAccount.fromEmail}
													id={emailAccount.fromEmail}
													name="email1"
													value={emailAccount.fromEmail}
													className="cursor-pointer"
												/>
												<label
													className="ml-2 text-sm leading-5 font-medium cursor-pointer hover:opacity-70"
													htmlFor={emailAccount.fromEmail}
												>
													{emailAccount.fromEmail}
												</label>
												<br />
											</div>
										);
									})
							) : (
								<p>No email accounts to show</p>
							)}
						</div>
						<div>
							<button
								onClick={handleOpenAddNewAccount}
								className="bg-lightIndigo text-darkIndigo px-4 py-1 rounded hover:opacity-80 hover:text-darkIndigo transition duration-300 font-medium"
							>
								+ Add new email account
							</button>
						</div>
					</div>
					<div
						className={
							emailAccountRdx.length > 0 ? 'md:w-fit self-end flex md:justify-end w-full justify-center ' : 'w-full'
						}
					>
						<div
							className={
								emailAccountRdx.length > 0
									? ' flex max-w-xl flex-col gap-x-2 md:flex-row'
									: ' flex flex-col max-w-xs  self-center mx-auto'
							}
						>
							<LoadingButton
								onClick={handleEmailNext}
								className=" mt-4 transition border border-C6B7280 duration-300 flex justify-center items-center rounded-md hover:opacity-75 w-full px-4 py-2 gap-x-2  text-blackTextlight"
								isLoading={false}
								disableElevation
							>
								<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M0.00300002 1.884L8 5.882L15.997 1.884C15.9674 1.37444 15.7441 0.895488 15.3728 0.545227C15.0016 0.194965 14.5104 -9.35847e-05 14 3.36834e-08H2C1.48958 -9.35847e-05 0.998447 0.194965 0.627178 0.545227C0.255908 0.895488 0.0326041 1.37444 0.00300002 1.884Z"
										fill="#1E40AF"
									/>
									<path
										d="M16 4.118L8 8.118L0 4.118V10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H14C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10V4.118Z"
										fill="#1E40AF"
									/>
								</svg>
								<p className="font-inter font-medium text-sm ">Confirm email account</p>
							</LoadingButton>
							<LoadingButton
								disabled={!!selectedEmailAccount}
								onClick={handleSendEmailUsingDefaultSystem}
								className={
									isWhiteLabel
										? 'hidden'
										: !!selectedEmailAccount
										? 'text-white cursor-default rounded-md  w-full transition duration-300 font-medium text-sm leading-5 px-2 mt-4 bg-buttonGray'
										: 'text-white rounded-md hover:opacity-75 w-full transition duration-300 font-medium text-sm leading-5 px-2 mt-4 bg-blue-800'
								}
								style={{ minHeight: '36px' }}
							>
								<div
									title="Send using our default mailing system"
									className="flex flex-row justify-center items-center "
								>
									<p className="mr-2 w-max">Send via default email address</p>
									<svg
										className="ml-3"
										width="21"
										height="20"
										viewBox="0 0 21 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M18.5 10C18.5 14.4183 14.9183 18 10.5 18C6.08172 18 2.5 14.4183 2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9183 2 18.5 5.58172 18.5 10ZM10.5 7C10.1311 7 9.8076 7.19922 9.63318 7.50073C9.35664 7.97879 8.74491 8.14215 8.26685 7.86561C7.78879 7.58906 7.62543 6.97733 7.90197 6.49927C8.41918 5.60518 9.38833 5 10.5 5C12.1569 5 13.5 6.34315 13.5 8C13.5 9.30622 12.6652 10.4175 11.5 10.8293V11C11.5 11.5523 11.0523 12 10.5 12C9.94773 12 9.50001 11.5523 9.50001 11V10C9.50001 9.44772 9.94773 9 10.5 9C11.0523 9 11.5 8.55228 11.5 8C11.5 7.44772 11.0523 7 10.5 7ZM10.5 15C11.0523 15 11.5 14.5523 11.5 14C11.5 13.4477 11.0523 13 10.5 13C9.94772 13 9.5 13.4477 9.5 14C9.5 14.5523 9.94772 15 10.5 15Z"
											fill="white"
										/>
									</svg>
								</div>
							</LoadingButton>
						</div>
					</div>
				</div>

				<div>
					{/* <button onClick={handleSendEmailUsingDefaultSystem} className=" text-addindigo px-4 py-1 rounded hover:text-radicalRed transition duration-300 font-medium text-sm leading-5">Send using our default email system</button> */}
					<HeadlessModal open={accountsModal} handleCloseModal={handleAccountClose}>
						<AccountsModal
							edit={false}
							onClose={handleAccountClose}
							schedule={true}
							emailAccounts={emailAccountRdx}
							emailAccount={null}
						/>
					</HeadlessModal>
				</div>
				<CustomSwalContainer closeSwal={closeSwal} swalOptions={swalOptions} />
			</div>
		</div>
	);
};

export default SenderDetail;
