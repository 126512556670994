import { Dialog } from '@headlessui/react';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/solid';
import React from 'react';

interface Props {
	csvFile: File | null;
	handleFileUpload: (e: any) => void;
	prospectListEdit: boolean;
	currentFile: string | null;
}

const Title: React.FC<Props> = ({ csvFile, handleFileUpload, currentFile, prospectListEdit }) => {
	return (
		<div>
			<div className="mt-3 text-left sm:mt-5">
				<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-blackText">
					Let's prepare your contact list
				</Dialog.Title>
				<div className={prospectListEdit ? 'mt-2' : 'hidden'}>
					<p className="text-sm text-mediumGray">
						Your file is successfully uploaded. Now let's prepare it for personalization of the cards
					</p>
				</div>
			</div>
			<div className="mt-3 text-left sm:mt-5">
				<div className="flex gap-2">
					<h3 className="text-lg leading-6 font-medium text-blackText">Contact list title</h3>
					<div className="group md:block hidden">
						<InformationCircleIcon className="w-6 h-6 text-indigo" />
						<div className="tooltip-text w-80 bg-indigo text-white p-3 rounded hidden group-hover:block absolute text-left py-2 px-6 z-50">
							<p className="font-medium">What is a contact list?</p>
							<p className="text-sm">
								Contact list is a contact book which you can use anytime you want, to send personalized and bulk cards
							</p>
						</div>
					</div>
				</div>

				<label className="relative w-full mt-2 flex justify-between border-2 border-dashed hover:bg-gray-50 border-gray-300 py-2 rounded-md cursor-pointer">
					<div className="flex w-full justify-between">
						<p className="text-sm font-medium text-mediumGray w-96 truncate pl-2">
							{prospectListEdit ? currentFile : csvFile?.name || 'no csv file selected'}
						</p>
						<span className={prospectListEdit ? 'hidden' : 'text-base leading-normal flex items-center'}>
							<PencilIcon className="w-5 h-5 text-mediumGray mr-1 " />
						</span>
						<input
							className="hidden"
							type="file"
							accept=".csv"
							onChange={handleFileUpload}
							disabled={prospectListEdit}
						/>
					</div>
				</label>
			</div>
		</div>
	);
};

export default Title;
