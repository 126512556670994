import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { UserProfileUpdateInterface } from './userProfile.interface';

const updateProfile = (userId: string, data: UserProfileUpdateInterface) => {
	return new Promise(async (resolve, reject) => {
		try {
			var config: AxiosRequestConfig = {
				method: 'put',
				url: '/user-profile',
				data: { ...data },
				params: { userId },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
};

export const user_profile_service = {
	updateProfile,
};
