import React, { Dispatch, useContext, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSelector } from 'react-redux';
import { CardProspectsContext } from '../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../contexts/DesignTool/DesignToolContext';
import { card_prospect_services } from '../../../services/request/cardProspect';
import AddRecipient from './AddRecipient';

interface Props {
	setTitleModel: any;
	setProgressSteps: Dispatch<any>;
	progressSteps: any;
}
const AddRecipientContainer: React.FC<Props> = ({ setTitleModel, setProgressSteps, progressSteps }) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const card = useSelector((state: any) => state.card);

	const { prospectLists, selectedProspectList, prospectListWithProspectData } = useContext(DesignToolContext);
	const { setCardProspectsData } = useContext(CardProspectsContext);
	const [initialLoading, setInitialLoading] = useState(true);
	const [prospectsLoading, setProspectsLoading] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		if (card.currentCardId && selectedProspectList) handleLoadMore(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [card.currentCardId, selectedProspectList]);

	const handleSearchRecipients = (value: string) => {
		setSearchValue(value.trim());
	};

	const handleLoadMore = (loadMore: boolean, searchTerm?: string) => {
		if (prospectsLoading) return;
		setProspectsLoading(true);
		if (!loadMore) setInitialLoading(true);
		const prospectlistId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
		if (prospectlistId) {
			console.log('SKip', skip);
			card_prospect_services
				.getCardProspects(card.currentCardId, prospectlistId, loadMore ? skip : 0, 10, searchTerm || '', false)
				.then((response) => {
					if (loadMore) {
						setCardProspectsData((prev) => {
							prev.cardProspects.push(...(response.cardProspects || []));
						});
					} else {
						setCardProspectsData(response);
					}
					setSkip((prev) => prev + 10);
					setHasNextPage(response.hasNextEntries);
				})
				.catch((err) => {
					console.log({ err });
					setHasNextPage(false);
				})
				.then(() => {
					setProspectsLoading(false);
					setInitialLoading(false);
				});
		}
	};

	const onLoadMore = () => {
		handleLoadMore(true);
	};

	const onKeyDown = (e: any) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setSkip(0);
			setCardProspectsData((prev) => {
				prev.cardProspects = [];
			});
			handleLoadMore(false, searchValue);
		}
		if (e.key === 'Backspace' && searchValue === '') {
			e.preventDefault();
			setSkip(0);
			setCardProspectsData((prev) => {
				prev.cardProspects = [];
			});
			handleLoadMore(false);
		}
	};

	const [infiniteRef] = useInfiniteScroll({
		loading: prospectsLoading,
		hasNextPage: hasNextPage,
		onLoadMore,
	});
	const [infiniteRefCopyUrl] = useInfiniteScroll({
		loading: prospectsLoading,
		hasNextPage: hasNextPage,
		onLoadMore,
	});

	return (
		<AddRecipient
			handleSearchRecipients={handleSearchRecipients}
			prospectListWithProspectData={prospectListWithProspectData}
			selectedProspectList={selectedProspectList}
			fetchProspectsAndProspectlist={() => handleLoadMore(false)}
			onKeyDown={onKeyDown}
			infiniteRef={infiniteRef}
			infiniteRefCopyUrl={infiniteRefCopyUrl}
			prospectsLoading={prospectsLoading}
			initialLoading={initialLoading}
			setSkip={setSkip}
			setTitleModel={setTitleModel}
			setProgressSteps={setProgressSteps}
			progressSteps={progressSteps}
		/>
	);
};

export default AddRecipientContainer;
