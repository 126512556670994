/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import LoadingButton from '../../../components/LoadingButton';
import { ROUTE_NAMES } from '../../../Routes/routeNames';

const Header: React.FC = () => {
	const card = useSelector((state: any) => state.card);
	const navigate = useNavigate();

	const handleEditCard = () => {
		// window.location.href = `${ROUTE_NAMES.createcard}?id=${card.currentCardId}`;
		navigate(`${ROUTE_NAMES.createcard}?id=${card.currentCardId}`);
	};

	return (
		<>
			<Disclosure as="nav" className="bg-indigo ">
				{() => (
					<>
						<div className="w-full  px-4 sm:px-6 lg:px-8 ">
							<div className="flex items-center justify-between h-16">
								<div className="flex justify-between w-full ">
									<div className="flex w-4/12 px-4">
										<LoadingButton onClick={handleEditCard} className="text-white flex items-center">
											<ArrowLeftIcon className="w-4 h-4 mr-2" />
											Edit card
										</LoadingButton>
									</div>

									<div className="w-4/12"></div>
								</div>
								<div className="hidden md:block"></div>
								<div className="-mr-2 flex md:hidden"></div>
							</div>
						</div>

						<Disclosure.Panel className="md:hidden">
							<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3"></div>
							<div className="pt-4 pb-3 border-t border-indigo-700">
								<div className="mt-3 px-2 space-y-1"></div>
							</div>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</>
	);
};

export default Header;
