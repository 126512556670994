const defaultState: any = '';

const whiteLabelSettingsReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'SET_WHITE_LABEL_SETTINGS':
			return action.payload;

		default:
			return state;
	}
};

export default whiteLabelSettingsReducer;
