import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { DesignToolContext } from '../../../../../../../../contexts/DesignTool/DesignToolContext';
// import { ROUTE_NAMES } from '../../../../../../../../Routes/routeNames';
import { card_prospect_services } from '../../../../../../../../services/request/cardProspect';
import ModalDialogBox from './ModalDialogBox';

interface Props {
	closeScheduleSkipContinueModalHandler: () => void;
	setdateDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const ModalDialogBoxContainer: React.FC<Props> = ({
	closeScheduleSkipContinueModalHandler,
	setdateDialog,
	setTitleModel,
	progressSteps,
	setProgressSteps,
}) => {
	const [selectedTime, setSelectedTime] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	const card = useSelector((state: any) => state.card);
	const { selectedProspectList, prospectLists } = useContext(DesignToolContext);
	// const navigate = useNavigate();

	const dataChangeHandler = (date: any, value: any) => {
		setSelectedTime(new Date(date));
		console.log(value);
		console.log('time..', new Date(date).getTime(), new Date(date).getTimezoneOffset);
		// card_prospect_services.updateCardProspects(prospectListId, card.currentCardId, , , false)
	};

	const scheduleRecipientsHandler = async (title: string) => {
		try {
			const prospectListId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
			const dateAndTimeOffset = selectedTime.getTimezoneOffset();
			const date = selectedTime.getTime();
			const saveNow = false;
			if (prospectListId && card.currentCardId) {
				setIsLoading(true);
				await card_prospect_services.updateCardProspects(
					prospectListId,
					card.currentCardId,
					date,
					dateAndTimeOffset,
					saveNow
				);
				setTitleModel(title);
			} else {
				console.log('No prospect list Or card Id found to process the request.');
			}
		} catch (error) {
			console.log({ error });
		} finally {
			setIsLoading(false);
		}
		closeScheduleSkipContinueModalHandler();
		const newArray = [...progressSteps];
		const findStatus = newArray?.includes('1');
		if (!findStatus) {
			newArray.push('1');
			setProgressSteps(newArray);
		}
	};

	return (
		<ModalDialogBox
			closeScheduleSkipContinueModalHandler={closeScheduleSkipContinueModalHandler}
			setdateDialog={setdateDialog}
			dataChangeHandler={dataChangeHandler}
			isLoading={isLoading}
			scheduleRecipientsHandler={scheduleRecipientsHandler}
			selectedTime={selectedTime}
		/>
	);
};

export default ModalDialogBoxContainer;
