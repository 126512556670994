/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Outlet } from 'react-router-dom';
import CardsScheduleContainer from './CardSchedule/CardsScheduleContainer';
import Header from './Header';

const ScheduleCardLayout: React.FC = () => {
	return (
		<>
			<div className="bg-graySelction min-h-screen pb-4 md:pb-0 flex flex-col ">
				<div className="fixed w-full z-10">
					<Header />
				</div>
				<main>
					<div className=" w-full mt-16 z-0">
						<CardsScheduleContainer />
					</div>
					<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 hidden">
						<Outlet />
					</div>
				</main>
			</div>
		</>
	);
};

export default ScheduleCardLayout;
