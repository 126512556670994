const defaultState: any = [];

const domainReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'SET_DOMAIN':
			return action.payload;

		default:
			return state;
	}
};

export default domainReducer;
