import React from 'react';
import HeadlessModal from '../../../../../../../components/HeadlessModal/HeadlessModal';
import LoadingButton from '../../../../../../../components/LoadingButton';
import MergeTagListContainer from './MergeTagList/MergeTagListContainer';
import TitleContainer from './Title/TitleContainer';

interface Props {
	open: boolean;
	loading: boolean;
	handleCloseModal: () => void;
	csvFile: File | null;
	handleFileUpload: (e: any) => void;
	addNewMergeTag: boolean;
	toggleAddMergeTag: () => void;
	handleFinalizeCsv: () => void;
	parsedProspects: Array<any> | null;
	setparsedProspects: React.Dispatch<React.SetStateAction<any[] | null>>;
	addProspectListHandler: () => Promise<void>;
	disableNext: boolean;
	setdisableNext: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactList: React.FC<Props> = ({
	open,
	loading,
	handleCloseModal,
	csvFile,
	handleFileUpload,
	addNewMergeTag,
	toggleAddMergeTag,
	handleFinalizeCsv,
	parsedProspects,
	setparsedProspects,
	disableNext,
	setdisableNext,
}) => {
	return (
		<HeadlessModal open={open} handleCloseModal={handleCloseModal}>
			<TitleContainer csvFile={csvFile} handleFileUpload={handleFileUpload} />
			<MergeTagListContainer
				addNewMergeTag={addNewMergeTag}
				toggleAddMergeTag={toggleAddMergeTag}
				parsedProspects={parsedProspects}
				setparsedProspects={setparsedProspects}
				setdisableNext={setdisableNext}
			/>
			<div className="flex justify-end mt-4 space-x-2">
				<LoadingButton
					className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo"
					onClick={handleCloseModal}
				>
					Cancel
				</LoadingButton>
				<LoadingButton
					onClick={handleFinalizeCsv}
					className="inline-flex items-center justify-center px-3 py-2 w-16 h-9 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red hover:bg-indigobtnhovr focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo"
					disabled={disableNext}
					isLoading={loading}
				>
					Next
				</LoadingButton>
			</div>
		</HeadlessModal>
	);
};

export default ContactList;
