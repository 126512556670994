import { ComponentType } from 'react';
import swal from 'sweetalert';

export default function componentLoader(
	lazyComponent: any,
	attemptsLeft: number
): Promise<{ default: ComponentType<any> }> {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error: any) => {
				// retry after 500 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						swal({
							title: 'The page took too long to connect.',
							text: "Check your internet connection and reload the page.\nIf that didn't work...\n- Clear your cache and cookies.\n- Disable extensions that might block certain pages.\n- Get help from our support -> 'support@cardclan.io' ",
						});
						reject(error);
						return;
					}
					console.log('Retrying to load component.');
					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
				}, 500);
			});
	});
}
