const defaultState: any = [];
const emailAccountReducer = (state = defaultState, action: { type: string; payload: any }) => {
	switch (action.type) {
		case 'SET_EMAILACCOUNT':
			return action.payload;

		default:
			return state;
	}
};

export default emailAccountReducer;
