/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

interface Props {
	selectedItem?: string;
	mergeTagName?: string;
	handleChange: any;
	borderLess?: boolean;
	data?: Array<string> | null;
	dataObj?: Array<any> | null;
}

const defaultState = 'select value';

const ListDropDown: React.FC<Props> = ({
	selectedItem = defaultState,
	handleChange,
	mergeTagName,
	borderLess,
	data,
	dataObj,
}) => {
	return (
		<Listbox
			value={selectedItem}
			onChange={mergeTagName ? (value: string) => handleChange(mergeTagName, value) : handleChange}
		>
			{({ open }) => (
				<>
					<div className="relative w-full">
						<Listbox.Button
							className={
								borderLess
									? 'bg-white relative w-full rounded-md pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none sm:text-sm'
									: 'bg-white relative w-full border border-gray200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo400 focus:border-indigo400 sm:text-sm'
							}
						>
							<span className={borderLess ? 'block truncate font-semibold text-lg' : 'block truncate'}>
								{selectedItem}
							</span>
							<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<ChevronDownIcon
									className={borderLess ? 'h-5 w-5 text-blackText' : 'h-5 w-5 text-mediumGray'}
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
								{!!dataObj
									? dataObj?.map((item) => (
											<Listbox.Option
												disabled={item.fileName === selectedItem}
												key={item._id}
												className={({ active }) =>
													classNames(
														active ? 'text-white bg-indigo' : 'text-gray900',
														'cursor-default select-none relative py-2 pl-3 pr-9'
													)
												}
												value={item.fileName}
											>
												{({ selected, active }) => (
													<>
														<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
															{item.fileName}
														</span>

														{selected ? (
															<span
																className={classNames(
																	active ? 'text-white' : 'text-gray900',
																	'absolute inset-y-0 right-0 flex items-center pr-4'
																)}
															>
																<CheckIcon className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
									  ))
									: data?.map((item) => (
											<Listbox.Option
												key={item}
												disabled={item === selectedItem}
												className={({ active }) =>
													classNames(
														active ? 'text-white bg-indigo' : 'text-gray900',
														'cursor-pointer select-none relative py-2 my-2 pl-3 pr-9'
													)
												}
												value={!!item ? item : 'Unnamed'}
											>
												{({ selected, active }) => (
													<>
														<span className={classNames(selected ? 'font-bold' : 'font-normal', 'block truncate')}>
															{!!item ? item : 'Unnamed'}
														</span>

														{selected ? (
															<span
																className={classNames(
																	active ? 'text-white' : 'text-gray900',
																	'absolute inset-y-0 right-0 flex items-center pr-4'
																)}
															>
																<CheckIcon className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
									  ))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};

export default ListDropDown;
