import { combineReducers } from "redux";
import userProfileReducer from "./userProfileReducer";
import cardReducer from "./cardReducer";
import categoriesReducer from "./catagoriesReducer";
import orderReducer from "./orderReducer";
import userPlanReducer from "./userPlanReducer";
import workspacesReducer from "./workspacesReducer";
import currentWorkspaceReducer from "./currentWorkspaceReducer";
import whiteLabelSettingsReducer from "./whiteLabelSettings";
import emailAccountReducer from "./emailAccountReducer";
import domainReducer from "./domainReducer";

const allReducers = combineReducers({
	userProfile: userProfileReducer,
	card: cardReducer,
	categories: categoriesReducer,
	myOrders: orderReducer,
	userPlan: userPlanReducer,
	workspaces: workspacesReducer,
	currentWorkspace: currentWorkspaceReducer,
	whiteLabelSettings: whiteLabelSettingsReducer,
	emailAccountRdx: emailAccountReducer,
	domain: domainReducer,
});

export default allReducers;
