import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesignToolContext } from '../../../../../contexts/DesignTool/DesignToolContext';
import { setCurrentCardData } from '../../../../../redux/actions';
import { card_prospect_services } from '../../../../../services/request/cardProspect';
import { prospect_list_services } from '../../../../../services/request/prospectList';
import UploadCsvModalContainer from './components/UploadCSVModal/UploadCsvModalContainer';
import ContactListModalContainer from './components/ContactList/ContactListContainer';
import AddRecipientModalContainer from '../Recipients/components/AddRecipientModal/AddRecipientModalContainer';
import NoRecipients from './NoRecipients';
import { CardProspectsContext } from '../../../../../contexts/CardProspects/CardProspectsContext';

const RecipientContainer: React.FC = () => {
	const { setCsvModalOpen } = useContext(CardProspectsContext);
	const [openEditModal, setopenEditModal] = useState<boolean>(false);
	const [recipientEdit, setrecipientEdit] = useState<boolean>(false);
	const [recipientOnAdd, setRecipientOnAdd] = useState<any>(null);
	const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
	const [isLoading, setIsLoading] = useState(false);
	const card = useSelector((state: any) => state.card);
	const dispatch = useDispatch();
	const { mergeTags, setMergeTags, setSelectedProspectList, setprospectLists } = useContext(DesignToolContext);

	const openEditModalHandler = (edit: boolean) => {
		setopenEditModal(true);
		setrecipientEdit(edit);
	};

	const handleOpenCsvModal = () => {
		setCsvModalOpen(true);
	};

	const closeEditModalHandler = () => {
		setopenEditModal(false);
	};

	const recipientValueChangeHandler = (inputLabel: string, value: string) => {
		if (!recipientEdit) {
			setRecipientOnAdd({ ...recipientOnAdd, [inputLabel]: value });
		}
	};

	const addNewRecipientHandler = async (e: any) => {
		try {
			e.preventDefault();
			if (Object.keys(mergeTags).length) {
				if (Object.keys(recipientOnAdd).length) {
					setIsLoading(true);
					let updatedTags: any = [];
					Object.keys(recipientOnAdd).forEach((item: any) => {
						updatedTags.push({ tagName: item, colName: item });
					});
					/**
					 * It will create a new prospect list in case there are no prospect list is added throught CSV.
					 * It will then create a card prospect.
					 * Add's the single recipient that is added into the form. It will shift the layout towards the recipient container.
					 */
					const res = await card_prospect_services.createCardProspectsWihoutCsv(
						card.currentCardId,
						`${currentWorkspace?.name}-${new Date().toDateString()}`,
						recipientOnAdd,
						updatedTags
					);

					if (res) {
						const prospectLists = await prospect_list_services.getProspectListWithoutProspects(currentWorkspace._id);
						// Update the prospect lists. If not will not be able to add furthur recipients.
						setprospectLists(prospectLists);

						// Set the updated merge tags in the context.
						setMergeTags(res.mergeTags);
						dispatch(setCurrentCardData({ ...card.currentCardData, mergeTags: res.mergeTags }));

						// Selected prospect list is set here in the response after the prospect list created. Currently it uses the workspace name
						// but when the card title will be added, it will use the card title.
						setSelectedProspectList(res.prospectListData?.fileName);
						// Closes the add recipient modal, after all the other calls are performed.
						closeEditModalHandler();
					} else {
						console.log("Couldn't update merge tags becusae prospect list id wasn't found");
					}
				} else {
					console.log('Unable to access properties of recipient being added.');
				}
			} else {
				console.log('No merge tags found while adding new recipient.');
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<NoRecipients handleOpenCsvModal={handleOpenCsvModal} openEditModalHandler={openEditModalHandler} />
			<UploadCsvModalContainer />
			<ContactListModalContainer />
			<AddRecipientModalContainer
				closeEditModalHandler={closeEditModalHandler}
				openEditModalHandler={openEditModalHandler}
				openEditModal={openEditModal}
				recipientEdit={recipientEdit}
				recipientValueChangeHandler={recipientValueChangeHandler}
				recipientOnEdit={recipientOnAdd}
				updateRecipientHandler={addNewRecipientHandler}
				isRecipientEditLoading={isLoading}
			/>
		</>
	);
};

export default RecipientContainer;
