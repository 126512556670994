import React, { useState } from 'react';
import { animated as a, useSpring } from 'react-spring';

const isSafari =
	/constructor/i.test(window.HTMLElement) ||
	(function (p) {
		return p.toString() === '[object SafariRemoteNotification]';
	})(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
const RightPage = ({ visible, onClick, zIndex, className, component }) => {
	const [hover, setHover] = useState(false);
	const springProps = useSpring({
		transform: `perspective(1200px) rotateY(${visible ? (hover ? 335 : 355) : 185}deg)`,
		transformOrigin: 'left center',
		backfaceVisibility: 'hidden',
		zIndex,
		display: isSafari ? (visible ? 'block' : 'none') : '',
		opacity: visible ? 1 : 0,
	});
	return (
		<a.div
			onClick={onClick}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			className={className}
			style={{ borderLeft: '0.5px ridge  #ececec', ...springProps }}
		>
			{component()}
		</a.div>
	);
};

const LeftPage = ({ visible, onClick, zIndex, className, component }) => {
	const [hover, setHover] = useState(false);
	// console.log(`Left zIndex: ${zIndex}`)
	const springProps = useSpring({
		transform: `perspective(1200px) rotateY(${visible ? (hover ? 25 : 5) : 175}deg)`,
		transformOrigin: 'right center',
		backfaceVisibility: 'hidden',
		opacity: visible ? 1 : 0,
		display: isSafari ? (visible ? 'block' : 'none') : '',
		zIndex,
	});
	return (
		<a.div
			onClick={onClick}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			className={className}
			style={{ borderRight: '0.5px ridge  #ececec', ...springProps }}
		>
			{component()}
		</a.div>
	);
};

function CardViewer({ pages, rightPageClass, leftPageClass, mainClass }) {
	const [p, setP] = useState(pages.map((page, index) => (index % 2 ? false : true)));
	// console.info("-----------------------------")
	// console.log([...p.map((x,i) => {
	//     return x? '|':'-'
	// })])
	return (
		<div className={mainClass}>
			{pages.map((page, index, array) => {
				const rIndex = index,
					lIndex = index + 1;
				return (
					<div key={index}>
						{rIndex % 2 === 0 && (
							<RightPage
								className={rightPageClass}
								pageIndex={rIndex}
								key={rIndex}
								zIndex={pages.length - rIndex}
								visible={p[rIndex]}
								onClick={() => {
									// console.log(`Right page clicked - Page#${index} - r#${lIndex}`)
									p[rIndex] = false;
									p[rIndex + 1] = true;
									if (rIndex + 2 < pages.length) p[rIndex + 2] = true;
									setP([...p]);
								}}
								component={array[rIndex]}
							/>
						)}
						{lIndex % 2 > 0 && (
							<LeftPage
								key={lIndex}
								className={leftPageClass}
								pageIndex={lIndex}
								zIndex={0}
								visible={p[lIndex]}
								onClick={() => {
									// console.log(`Left page clicked - Page#${index} - l#${lIndex}`)
									p[lIndex - 1] = true;
									p[lIndex] = false;
									setP([...p]);
								}}
								component={array[lIndex]}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
}
export default React.memo(CardViewer);
