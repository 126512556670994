import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import UserFormContainer from './components/UserFormContainer';

interface Props {
	closeEditModalHandler: () => void;
	openEditModalHandler: (edit: boolean) => void;
	updateRecipientHandler?: (e: any) => void;
	openEditModal: boolean;
	recipientEdit: boolean;
	recipientOnEdit?: any;
	isRecipientEditLoading: boolean;
	recipientValueChangeHandler: (inputLabel: string, value: string) => void;
}

const AddRecipientModal: React.FC<Props> = ({
	closeEditModalHandler,
	openEditModal,
	updateRecipientHandler,
	recipientEdit,
	recipientOnEdit,
	recipientValueChangeHandler,
	isRecipientEditLoading,
}) => {
	return (
		<Transition appear show={openEditModal} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeEditModalHandler}>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
							<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
								{recipientEdit ? 'Recipient info' : 'Add recipient'}
							</Dialog.Title>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									{recipientEdit
										? 'Update your recipients information, so they will receive card properly.'
										: 'Add name and email address of recipient'}
								</p>
							</div>
							<UserFormContainer
								updateRecipientHandler={updateRecipientHandler}
								closeEditModalHandler={closeEditModalHandler}
								recipientEdit={recipientEdit}
								recipientOnEdit={recipientOnEdit}
								recipientValueChangeHandler={recipientValueChangeHandler}
								isRecipientEditLoading={isRecipientEditLoading}
							/>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default AddRecipientModal;
