import DateFnsUtils from '@date-io/date-fns';
import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import { createTheme } from '@material-ui/core';
import lime from '@material-ui/core/colors/blue';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CardProspectsData } from '../../../../contexts/CardProspects/interfaces';

const useStyles = makeStyles({
	root: {
		flexDirection: 'row-reverse',
	},
});

const defaultMaterialTheme = createTheme({
	palette: {
		primary: lime,
	},
});

interface Props {
	cardProspectsData: CardProspectsData;
	computeTableColumns: (prospectObj: any) => [string, unknown][];
	computerHeaderColumns: () => Array<string>;
	infiniteRef: any;
	recipientListLoading: boolean;
	handleUpdateProspectDate: (prospectData: any, date: MaterialUiPickersDate) => void;
}

const ScheduledRecipientsTable: React.FC<Props> = ({
	computerHeaderColumns,
	cardProspectsData,
	computeTableColumns,
	infiniteRef,
	recipientListLoading,
	handleUpdateProspectDate,
}) => {
	const classes = useStyles();
	return (
		<div className="border-2 border-gray200 h-64 overflow-scroll bg-white rounded-md">
			<table className="min-w-full divide-y divide-lightgray">
				<thead className="bg-lightgray border-b-2 border-gray200">
					<tr>
          <th className='px-6 py-3'></th>
						{computerHeaderColumns().map((item, index) => (
							<th key={index}
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
							>
								{item}
							</th>
						))}
						<th scope="col" className="relative px-6 py-3">
							<span className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Scheduled At
							</span>
						</th>
					</tr>
				</thead>
				<tbody className="border divide-y-2 divide-gray200">
					{cardProspectsData.cardProspects.map((cardProspect, index) => (
						<tr key={cardProspect._id} className={'bg-white'}>
              <td className="pl-4">{index+1}</td>
							{computeTableColumns(cardProspect).map((item, idx) => (
								<td
									key={item[0]}
									className={`px-6 py-4 whitespace-nowrap text-sm ${
										!idx ? 'font-semibold text-blackText' : 'text-mediumGray'
									}`}
								>
									{typeof item[1] === 'string' && !!item[1] ? item[1] : '-'}
								</td>
							))}
							<td className="px-2 py-4 whitespace-nowrap flex justify-center gap-1 text-sm font-medium">
								<button className="text-mediumGray hover:text-indigo flex justify-center border-0">
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<ThemeProvider theme={defaultMaterialTheme}>
											<KeyboardDateTimePicker
												value={!cardProspect.saveNow ? new Date(cardProspect.date) : null}
												className={!cardProspect.saveNow ? ' md:w-full' : 'md:w-11/12'}
												onChange={(date: MaterialUiPickersDate) => handleUpdateProspectDate(cardProspect, date)}
												onError={console.log}
												minDate={new Date()}
												rightArrowIcon={<ClockIcon />}
												format="MMM d, yyyy hh:mm a"
												keyboardIcon={<CalendarIcon className="w-5 h-5 text-calendarBlue" />}
												variant="dialog"
												placeholder={'Now'}
												InputProps={{
													disableUnderline: true,
													readOnly: true,
													classes: { root: classes.root },
												}}
											/>
										</ThemeProvider>
									</MuiPickersUtilsProvider>
									{/* <span className={!cardProspect.saveNow ? 'hidden' : ''}>Now</span> */}
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<p
				className={
					cardProspectsData.cardProspects?.length
						? 'hidden'
						: 'flex justify-center items-center text-center text-mediumGray my-10'
				}
			>
				No selected recipients found.
			</p>
			<div ref={infiniteRef} className="p-2 bg-white">
				<p className={recipientListLoading ? 'animate-pulse rounded-lg h-5 text-indigo text-center' : 'hidden'}>
					Loading ...
				</p>
			</div>
		</div>
	);
};

export default ScheduledRecipientsTable;
