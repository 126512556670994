import React from 'react';
import { Outlet } from 'react-router-dom';

const LoggedOutLayout: React.FC = () => {
	return (
		<div className="font-inter">
			<div>
				<Outlet />
			</div>
		</div>
	);
};

export default LoggedOutLayout;
