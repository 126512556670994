import React from 'react';
import UploadButton from './UploadButton';

interface Props {
	handleFileUpload: (e: any) => void;
}

const UploadButtonContainer: React.FC<Props> = ({ handleFileUpload }) => {
	return <UploadButton handleFileUpload={handleFileUpload} />;
};

export default UploadButtonContainer;
