class Resizer {
	static changeHeightWidth(height: number, maxHeight: number, width: number, maxWidth: number) {
		// width = maxWidth
		// height = maxHeight
		if (width > maxWidth) {
			height = Math.round((height * maxWidth) / width);
			width = maxWidth;
		}
		if (height > maxHeight) {
			width = Math.round((width * maxHeight) / height);
			height = maxHeight;
		}
		return { height, width };
	}

	static resizeAndRotateImage(
		image: HTMLImageElement,
		_: number,
		__: number,
		compressFormat = 'jpeg',
		quality = 100,
		rotation = 0
	) {
		var qualityDecimal = quality / 100;
		var canvas = document.createElement('canvas');

		var width = image.width;
		var height = image.height;

		// var newHeightWidth = this.changeHeightWidth(height, maxHeight, width, maxWidth)
		var newHeightWidth = { width: image.width, height: height };
		if (rotation && (rotation === 90 || rotation === 270)) {
			canvas.width = newHeightWidth.height;
			canvas.height = newHeightWidth.width;
		} else {
			canvas.width = newHeightWidth.width;
			canvas.height = newHeightWidth.height;
		}

		width = newHeightWidth.width;
		height = newHeightWidth.height;

		var ctx = canvas.getContext('2d');

		if (ctx != null) {
			if (rotation) {
				ctx.rotate((rotation * Math.PI) / 180);
				if (rotation === 90) {
					ctx.translate(0, -canvas.width);
				} else if (rotation === 180) {
					ctx.translate(-canvas.width, -canvas.height);
				} else if (rotation === 270) {
					ctx.translate(-canvas.height, 0);
				} else if (rotation === 0 || rotation === 360) {
					ctx.translate(0, 0);
				}
			}
			ctx.drawImage(image, 0, 0, width, height);
		}

		return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
	}

	static b64toBlob(b64Data: any, contentType: string) {
		contentType = contentType || 'image/jpeg';
		var sliceSize = 512;

		var byteCharacters = atob(b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	static createResizedImage(
		file: File,
		maxWidth: number,
		maxHeight: number,
		compressFormat: string,
		quality: number,
		rotation: number,
		responseUriFunc: any,
		outputType = 'base64'
	) {
		var blob = null;
		const reader = new FileReader();
		var image: any = new Image();
		if (file) {
			reader.readAsDataURL(file);
			reader.onload = () => {
				image.src = reader.result;
			};

			image.onload = () => {
				var resizedDataUrl = Resizer.resizeAndRotateImage(
					image,
					maxWidth,
					maxHeight,
					compressFormat,
					quality,
					rotation
				);
				blob = Resizer.b64toBlob(resizedDataUrl, `image/${compressFormat}`);
				outputType === 'blob'
					? responseUriFunc(blob, image.width, image.height)
					: responseUriFunc(resizedDataUrl, image.width, image.height);
			};
			reader.onerror = (error) => {
				responseUriFunc(error);
			};
		} else {
			responseUriFunc('File Not Found');
		}
	}
}
const resizerContainer = {
	imageFileResizer: (
		file: File,
		maxWidth: number,
		maxHeight: number,
		compressFormat: string,
		quality: number,
		rotation: number,
		responseUriFunc: any,
		outputType: string
	) => {
		return Resizer.createResizedImage(
			file,
			maxWidth,
			maxHeight,
			compressFormat,
			quality,
			rotation,
			responseUriFunc,
			outputType
		);
	},
};

export default resizerContainer;
