import { AxiosRequestConfig } from 'axios';
import { User } from 'firebase/auth';
import { firebaseAuthServices } from '../firebaseAuth';
import axios from './axios';
import { LayoutVerificationInterface } from './layoutVerification.interface';

export const layout_verification_service = {
	verifyPrivateLayout,
};

function verifyPrivateLayout(): Promise<LayoutVerificationInterface> {
	const currentUser: User | null = firebaseAuthServices.getCurrentUser();

	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/composite/layout-verification',
				params: {
					userId: currentUser?.uid,
				},
			};
			const resp = await axios(config);
			resolve(resp?.data?.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
}
