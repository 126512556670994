import React from 'react';
interface Props {
	imgUrl:any,header:any,footer:any,
    viewCardButtonColor:any,
    subjectLine:string
    viewButtonText?:string
    isRounded?:boolean
}
const EmailPreview :React.FC<Props>= ({imgUrl,header,footer,viewCardButtonColor,subjectLine,viewButtonText,isRounded}) => {
 
    const emailHeader =  header.length ? header:subjectLine;
    const emailFooter =  footer.length ? footer: "Your inbox just got a special delivery. View your card online."
    return <>
    <div className="flex flex-col items-center">
        <h1 className='mb-5'>{emailHeader}</h1>
        <img src={imgUrl} className='w-96 mb-5' alt="My Image" />
        <h1 className='mb-5'>{emailFooter}</h1>
        <button style={{backgroundColor:viewCardButtonColor ? viewCardButtonColor:'#FF3C69',borderRadius:isRounded ? '30px':'0px'}} className=' px-5 py-2 text-white mb-5'>
            {viewButtonText?viewButtonText:'View card'}
        </button>
        <p className='underline '>unsubscribe</p>
    </div>
    </>
    }
  
  export default EmailPreview