import React from 'react';
interface Props {
	children?: any;
	onClose?: () => void;
	open?: boolean;
	className?: string;
	modalContainer?: string;
	closeIcon?: string;
	iconClassName?: string;
}

const Modal: React.FC<Props> = ({ children, onClose, open, className, modalContainer, closeIcon, iconClassName }) => {
	return (
		<div className={open ? '' : 'hidden'}>
			<div className="fixed z-50 inset-0 shadow-2xl bg-black bg-opacity-40 transition-opacity">
				<div className={modalContainer + ' flex flex-wrap place-items-center justify-center  mx-auto h-full'}>
					<div
						className={className + ' rounded-lg text-left shadow-xl transition-all duration-500 ease-in-out transform '}
					>
						<img
							src={closeIcon}
							onClick={onClose}
							className={
								closeIcon
									? iconClassName + ' p-1  top-2 rounded-full absolute right-2 cursor-pointer hover:opacity-70 z-50'
									: 'hidden'
							}
							alt="logo"
						/>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Modal;
