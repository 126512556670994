import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { Persona } from './persona.interface';

const getUserPersona = (): Promise<Persona[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/persona',
			};
			const resp = await axios(config);
			const designCategoriesData = resp.data;
			resolve(designCategoriesData.data);
		} catch (error: any) {
			reject(error);
		}
	});
};


export const user_persona = {
	getUserPersona,
	
};
