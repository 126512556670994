import DateFnsUtils from '@date-io/date-fns';
import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import lime from '@material-ui/core/colors/blue';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import LoadingButton from '../../../../../../../../components/LoadingButton';

interface Props {
	closeScheduleSkipContinueModalHandler: () => void;
	setdateDialog: React.Dispatch<React.SetStateAction<boolean>>;
	dataChangeHandler: (e: any, date: any) => void;
	isLoading: boolean;
	scheduleRecipientsHandler: (title: string) => Promise<void>;
	selectedTime: Date;
}
const useStyles = makeStyles({
	root: {
		flexDirection: 'row-reverse',
	},
});

const defaultMaterialTheme = createTheme({
	palette: {
		primary: lime,
	},
});

const ModalDialogBox: React.FC<Props> = ({
	closeScheduleSkipContinueModalHandler,
	setdateDialog,
	dataChangeHandler,
	isLoading,
	scheduleRecipientsHandler,
	selectedTime,
}) => {
	const classes = useStyles();

	return (
		<>
			<div className="py-2">
				<p className="text-sm text-mediumGray font-medium">
					Let’s add the date and time you want your contacts to receive the card. You can also change the schedule
					manually for recipients.
				</p>
			</div>
			<div className="flex flex-col mt-2">
				<label htmlFor="dateselect" className="block text-md font-medium text-blackTextlight mb-2">
					Select date and time
				</label>
				<button
					id="dateselect"
					onClick={() => setdateDialog(true)}
					className="text-mediumGray hover:text-indigo flex justify-center border-1 -ml-5 rounded-md"
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={defaultMaterialTheme}>
							<KeyboardDateTimePicker
								// open={dateDialog}
								value={selectedTime}
								className="lg:w-full "
								onChange={dataChangeHandler}
								onError={console.log}
								minDate={new Date()}
								rightArrowIcon={<ClockIcon />}
								format="MMM d, yyyy hh:mm a"
								keyboardIcon={<CalendarIcon className="w-7 h-7 text-calendarBlue border-2 m-2 border-calendarBlue  rounded-full p-calender_icon_padding"  />}
								variant="dialog"
								InputProps={{
									disableUnderline: true,
									readOnly: true,
									classes: { root: classes.root },
								}}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</button>
			</div>
			<div className="mt-4 flex justify-end">
				<div className="">
					<button
						type="button"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blackTextlight bg-white border border-transparent rounded-md focus:outline-none"
						onClick={closeScheduleSkipContinueModalHandler}
					>
						Cancel
					</button>
					<LoadingButton
						isLoading={isLoading}
						disabled={isLoading}
						onClick={() => scheduleRecipientsHandler('Connect your email account')}
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
					>
						Continue
					</LoadingButton>
				</div>
			</div>
		</>
	);
};

export default ModalDialogBox;
