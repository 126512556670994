const useSvg = (xmlns: string, viewBox: string, fill: string, stroke: string, path: string) => {
	let svg = `<svg xmlns="${xmlns}" fill="${fill}" viewBox="${viewBox}" stroke="${stroke}">${path}</svg>`;

	let blob = new Blob([svg], { type: 'image/svg+xml' });
	let url = URL.createObjectURL(blob);

	return url;
};

export default useSvg;
