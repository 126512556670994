import Operations from './';

interface Props {
	setTitleModel: React.Dispatch<React.SetStateAction<string>>;
	ModelTitle: string;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}
const CardScheduledContainer: React.FC<Props> = ({ ModelTitle, setTitleModel, setProgressSteps, progressSteps }) => {
	const titlesArray = ['Add some recipients', 'Connect your email account', 'Customize the subject line'];

	const handleOpenModelClick = (title: string) => {
		setTitleModel(title);
	};
	return (
		<Operations
			handleOpenModelClick={handleOpenModelClick}
			titlesArray={titlesArray}
			ModelTitle={ModelTitle}
			setTitleModel={setTitleModel}
			setProgressSteps={setProgressSteps}
			progressSteps={progressSteps}
		/>
	);
};

export default CardScheduledContainer;
