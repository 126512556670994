/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import ProgressBar from './ProgressBar';
import RightSideBar from './RightSideBar';
import Confetti from 'react-dom-confetti';

interface Props {
  setTitleModel: React.Dispatch<React.SetStateAction<string>>;
  ModelTitle: string;
  showConfetti: boolean;
  progressSteps: any;
  setProgressSteps: React.Dispatch<any>;
  confettiConfig: any
}

const CardSchedule: React.FC<Props> = ({ setTitleModel, ModelTitle, progressSteps, setProgressSteps, confettiConfig, showConfetti }) => {

  return (
    <>
      <div className="relative px-8 pt-6 w-full h-full">
        {' '}
        <div className='absolute inset-2/4'>
          <Confetti active={showConfetti} config={confettiConfig} />
        </div>
        <div className="fixed">
          <ProgressBar ModelTitle={ModelTitle} progressSteps={progressSteps} />
        </div>
        <div className=" h-full max-w-full ml-40">
          <RightSideBar
            ModelTitle={ModelTitle}
            setTitleModel={setTitleModel}
            setProgressSteps={setProgressSteps}
            progressSteps={progressSteps}
          />
        </div>
      </div>
    </>
  );
};

export default CardSchedule;
