import axios from './axios';

export const custom_domain_services = {
	createCustomDomain,
	getCustomDomain,
	deleteCustomDomain,
	updateCustomDomain,
	getCustomDomainByUserId,
};

function getCustomDomain() {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'get',
				url: '/custom-domain',
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function getCustomDomainByUserId(userId) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'get',
				url: `/custom-domain/user/${userId}`,
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Error occurred in getCustomDomainbyUserID');
			reject(error);
		}
	});
}

function createCustomDomain(userId, data) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'post',
				url: '/custom-domain',
				data: { ...data, userId },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function deleteCustomDomain(data) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'post',
				url: '/custom-domain',
				data: { ...data },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function updateCustomDomain(userId, data) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'put',
				url: '/custom-domain',
				data: { ...data, userId },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}
