import React from 'react';
import ScheduleLaterModal from './ScheduleLaterModal';

interface Props {
	closeSceduleLaterModalHandler: () => void;
	openScheduleSkipContinueModalHandler: () => void;
	openScheduleLaterModal: boolean;
	cancelButtonRefScheduleLater: React.MutableRefObject<null>;
}

const ScheduleLaterModalContainer: React.FC<Props> = ({
	openScheduleLaterModal,
	closeSceduleLaterModalHandler,
	cancelButtonRefScheduleLater,
	openScheduleSkipContinueModalHandler,
}) => {
	return (
		<ScheduleLaterModal
			cancelButtonRefScheduleLater={cancelButtonRefScheduleLater}
			closeSceduleLaterModalHandler={closeSceduleLaterModalHandler}
			openScheduleLaterModal={openScheduleLaterModal}
			openScheduleSkipContinueModalHandler={openScheduleSkipContinueModalHandler}
		/>
	);
};

export default ScheduleLaterModalContainer;
