import { useImmerState } from '@shrugsy/use-immer-state';
import React, { createContext } from 'react';
import { EmailAccountInterface } from '../../services/request/emailAccounts.interface';
import { selectedEmailAccountDefault } from './defaults';
import { ISelectedEmailAccount } from './interfaces';

export const EmailAccountContext = createContext<ISelectedEmailAccount>(selectedEmailAccountDefault);

export const EmailAccountProvider: React.FC = ({ children }) => {
	const [selectedEmailAccount, setSelectedEmailAccount] = useImmerState<EmailAccountInterface | null>(
		selectedEmailAccountDefault.selectedEmailAccount
	);

	return (
		<EmailAccountContext.Provider value={{ selectedEmailAccount, setSelectedEmailAccount }}>
			{children}
		</EmailAccountContext.Provider>
	);
};
