import I18n from 'i18n-js';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { animated as a, useSpring } from 'react-spring';
import swal from 'sweetalert';
import WebFont from 'webfontloader';
import CardViewer from '../../components/CardViewer';
import CardViewerMobile from '../../components/CardViewerMobile';
import Modal from '../../components/Modal/index';
import ToolTipCard from '../../components/ToolTipPanel/ToolTipCard';
import { DesignToolContext } from '../../contexts/DesignTool/DesignToolContext';
import { TMergeTag } from '../../contexts/DesignTool/types';
import { analyticsService } from '../../services/request/analytics';
import { card_services } from '../../services/request/card';
import { custom_domain_services } from '../../services/request/customDomain';
import { recipients_service } from '../../services/request/recipients';
import CardPage from './CardPage';
import { ImageObj } from './types';
// import { useSelector } from 'react-redux';
import Confetti from 'react-dom-confetti';
import { workspace_services } from '../../services/request/workspace';
import Konva from 'konva';

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
	Konva.pixelRatio = 1;
}

interface Props {
	isPreviewModal?: any;
	props?: any;
	cardId?: any;
	selectedTemplate?: any;
}

const ViewCard = ({ isPreviewModal, cardId, selectedTemplate }: Props) => {
	// set our merge tags format to _.template
	_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
	const [newPages, setNewPages] = useState<{ (): JSX.Element }[]>([]);
	const [openCard, setopenCard] = useState(true);
	const [open, setOpen] = useState(true);
	const [currentWorkspace, setCurrentWorkspace] = useState<any>();
	const [isCTA, setCTA] = useState(false);
	const [ctaLink, setCTALink] = useState<string>('https://www.example.com');
	const [cardHeader, setCardHeader] = useState<string>('');
	const [ctaText, setCTAText] = useState<string>('Click here');
	const [recipientName, setRecipientName] = useState<string>('');
	const [senderName, setSenderName] = useState<string>('');
	const [isConfettiOn, setConfetti] = useState(false);
	const { previewCardOptions, mergeTags, selectedCardId } = useContext(DesignToolContext);

	const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

	let { id }: { id?: any } = useParams();
	let [searchParams] = useSearchParams();
	const recipientId: any = searchParams.get('recipientId');
	if (!id) {
		id = cardId || selectedCardId;
	}
	const routePath = window.location.pathname;
	const handleGetWorkspace = async (workspaceId: string) => {
		try {
			const res = await workspace_services.getWorkspaceByID(workspaceId);
			setCurrentWorkspace(res);
		} catch (error) {
			console.log('  error:', error);
		}
	};
	const getCardData = useCallback(async () => {
		if (id) {
			let somePages: { (): JSX.Element }[] = [];
			// NOTE - need logged in user to call firebase
			// if (!firebaseAuthServices.getCurrentUser() && !!id) await firebaseAuthServices.collaboratorSignIn();
			let res = await card_services.getCardById(id);
			if (routePath.includes('viewcard')) {
				if (res && res.emailPreviewData) {
					if (res.emailPreviewData.isCTA) {
						setCTA(res.emailPreviewData.isCTA);
					}
					if (res.emailPreviewData.ctaLink) {
						setCTALink(res.emailPreviewData.ctaLink);
					}
					if (res.emailPreviewData.ctaText) {
						setCTAText(res.emailPreviewData.ctaText);
					}
					if (res.emailPreviewData.cardPreviewHeader) {
						setCardHeader(res.emailPreviewData.cardPreviewHeader);
					}
					if (res.emailPreviewData.isConfettiOn) {
						setConfetti(res.emailPreviewData.isConfettiOn);
					}
				}

				if (res.deliveryInfo && res.deliveryInfo.fromName) {
					setSenderName(res.deliveryInfo.fromName);
				}
				if (res.workspace_id) {
					handleGetWorkspace(res.workspace_id);
				}
			}
			try {
				const { id: cardId, ...cardData } = res;
				if (cardData.stage) {
					const compileTemplate = _.template(JSON.stringify(cardData.cardPages));
					let compiledCardPages = cardData.cardPages;
					// if previewing card for prospects using merge tags then replace merge tags in cardpages
					if (isPreviewModal && previewCardOptions.previewProspect) {
						let dataWithFallback = previewCardOptions.prospectData;
						Object.keys(previewCardOptions.prospectData).forEach((key: string) => {
							if (!previewCardOptions.prospectData[key]) {
								const fallback = mergeTags.find((item) => item.tagName === key)?.fallback || '';
								dataWithFallback = {
									...dataWithFallback,
									[key]: fallback,
								};
							}
						});
						try {
							compiledCardPages = JSON.parse(compileTemplate(dataWithFallback));
						} catch (error) {
							console.log({ error });
							swal({
								icon: 'warning',
								title: 'Incorrect merge tag(s)!',
								text: 'One or more merge tags were missing or incorrect.',
							});
						}
					} else if (recipientId) {
						const recipientData = await recipients_service.getRecipientById(recipientId);
						setRecipientName(recipientData.mergeTags[0].value);
						let recipientTagsData: any = {};
						if (recipientData) {
							recipientData.mergeTags.forEach((item: TMergeTag) => {
								recipientTagsData = {
									...recipientTagsData,
									[item.tagName]: item.value || item.fallback || '',
								};
							});
						}
						try {
							compiledCardPages = JSON.parse(compileTemplate(recipientTagsData));
						} catch (error) {
							console.log({ error });
						}
					} else {
						let noProspectPreview: any = {};
						mergeTags.forEach((item) => {
							noProspectPreview[item.tagName] = item.fallback;
						});
						try {
							compiledCardPages = JSON.parse(compileTemplate(noProspectPreview));
						} catch (error) {
							console.log({ error });
							// swal({
							// 	icon: 'warning',
							// 	title: 'Incorrect merge tag(s)!',
							// 	text: 'One or more merge tags were missing or incorrect.',
							// });
						}
					}
					const updatedCardPages = await Promise.all(
						compiledCardPages.map(async (pageData: any) => {
							return pageData?.images?.length
								? await Promise.all(
										pageData?.images?.map(async (image: ImageObj, imageIndex: number) => {
											if (image.imageUrl || image.downloadUrl) {
												if (image.imageUrl) pageData.images[imageIndex].imageUrl = image.imageUrl;
												if (image.downloadUrl) pageData.images[imageIndex].downloadUrl = image.downloadUrl;
												return pageData;
											}
										})
								  )
								: [pageData];
							// NOTE - return [pageData] because out expected output is an array of object(object being updated page data with blobs)
						})
					);
					const checkedCardPages = checkNumOfPages(updatedCardPages);
					checkedCardPages.forEach((pageData: any, pageId: any) => {
						// NOTE - because of array.map each page is an array containing cardPage object at pageData[0]

						somePages.push(() => <CardPage stage={cardData.stage} pageData={pageData[0]} pageId={pageId} />);
					});
					setNewPages(somePages);
					setOpen(false);
				}
			} catch (err) {
				console.log({ 'Error getting card data: ': err });
			}
		}
	}, [id, mergeTags, previewCardOptions.previewProspect, previewCardOptions.prospectData, isPreviewModal, recipientId]);

	const handleTemplatePreview = async () => {
		let somePages: { (): JSX.Element }[] = [];
		try {
			const { card: cardData } = selectedTemplate;
			if (cardData.stage) {
				const compileTemplate = _.template(JSON.stringify(cardData.cardPages));
				let compiledCardPages = cardData.cardPages;
				// if previewing card for prospects using merge tags then replace merge tags in cardpages
				if (isPreviewModal && previewCardOptions.previewProspect) {
					let dataWithFallback = previewCardOptions.prospectData;
					Object.keys(previewCardOptions.prospectData).forEach((key: string) => {
						if (!previewCardOptions.prospectData[key]) {
							const fallback = mergeTags.find((item) => item.tagName === key)?.fallback || '';
							dataWithFallback = {
								...dataWithFallback,
								[key]: fallback,
							};
						}
					});
					try {
						compiledCardPages = JSON.parse(compileTemplate(dataWithFallback));
					} catch (error) {
						console.log({ error });
					}
				} else if (recipientId) {
					const recipientData = await recipients_service.getRecipientById(recipientId);
					let recipientTagsData: any = {};
					if (recipientData) {
						recipientData.mergeTags.forEach((item: TMergeTag) => {
							recipientTagsData = {
								...recipientTagsData,
								[item.tagName]: item.value || item.fallback || '',
							};
						});
					}
					try {
						compiledCardPages = JSON.parse(compileTemplate(recipientTagsData));
					} catch (error) {
						console.log({ error });
					}
				} else {
					let noProspectPreview: any = {};
					mergeTags.forEach((item) => {
						noProspectPreview[item.tagName] = item.fallback;
					});
					try {
						compiledCardPages = JSON.parse(compileTemplate(noProspectPreview));
					} catch (error) {
						console.log({ error });
					}
				}
				const updatedCardPages = await Promise.all(
					compiledCardPages.map(async (pageData: any) => {
						return pageData?.images?.length
							? await Promise.all(
									pageData?.images?.map(async (image: ImageObj, imageIndex: number) => {
										if (image.imageUrl || image.downloadUrl) {
											if (image.imageUrl) pageData.images[imageIndex].imageUrl = image.imageUrl;
											if (image.downloadUrl) pageData.images[imageIndex].downloadUrl = image.downloadUrl;
											return pageData;
										}
									})
							  )
							: [pageData];
						// NOTE - return [pageData] because out expected output is an array of object(object being updated page data with blobs)
					})
				);
				const checkedCardPages = checkNumOfPages(updatedCardPages);
				checkedCardPages.forEach((pageData: any, pageId: any) => {
					// NOTE - because of array.map each page is an array containing cardPage object at pageData[0]

					somePages.push(() => <CardPage stage={cardData.stage} pageData={pageData[0]} pageId={pageId} />);
				});
				setNewPages(somePages);
				somePages = [];
				setOpen(false);
			}
		} catch (err) {
			console.log({ 'Error getting card data: ': err });
		}
	};
	useEffect(() => {
		WebFont.load({
			google: {
				families: [
					'Comfortaa',
					'Josefin Sans',
					'Euphoria Script',
					'Oswald',
					'Alfa Slab One',
					'UnifrakturMaguntia',
					'Bangers',
					'Caveat',
					'DM Sans',
					'East Sea Dokdo',
					'Homemade Apple',
					'Kristi',
					'Lobster Two',
					'Maven Pro',
					'Monoton',
					'Montserrat',
					'Playfair Display',
					'Press Start 2P',
					'Raleway',
					'Roboto Slab',
					'Shadows Into Light',
					'Swanky and Moo Moo',
					'Bebas Neue',
					'Pacifico',
					'Secular One',
					'Teko',
					'Crimson Text',
					'Righteous',
					'Satisfy',
					'Kavoon',
					'Fredoka One',
					'IBM Plex Mono',
					'Questrial',
					'Barlow Semi Condensed',
					'Exo',
					'Play',
					'Lilita One',
					'Acme',
					'Chakra Petch',
					'Permanent Marker',
					'Orbitron',
					'Ubuntu Condensed',
					'Cinzel',
					'Spectral',
					'Covered By Your Grace',
					'Kalam',
					'Changa',
					'Space Mono',
					'Courgette',
					'Patua One',
					'Great Vibes',
					'Kaushan Script',
					'Cormorant',
					'Yeseva One',
					'Sora',
					'Oleo Script',
					'Outfit',
					'Yellowtail',
					'Montserrat Alternates',
					'Cookie',
					'Michroma',
					'Philosopher',
					'Lexend',
					'Urbanist',
					'Sawarabi Mincho',
					'Patrick Hand',
					'Concert One',
					'Gloria Hallelujah',
					'Sacramento',
					'Press Start 2P',
					'Poppins',
					'Inter',
					'Nunito',
					'Rubik',
					'Kanit',
					'Mulish',
					'Quicksand',
					'Inconsolata',
					'Titillium Web',
					'Dosis',
					'Libre Baskerville',
					'Prompt',
					'Irish Grover',
					'Sniglet',
					'Merienda One',
					'Rubik Moonrocks',
					'Seaweed Script',
					'Gabriela',
					'Averia Libre',
					'Viaoda Libre',
					'Grandstander',
					'Caladea',
					'Tillana',
					'Podkova',
					'Spinnaker',
					'Paprika',
					'Hanuman',
					'Kelly Slab',
					'Mirza',
					'Copse',
					'Marvel',
					'Calligraffitti',
					'Vollkorn SC',
					'Lemon',
					'Gugi',
					'Rakkas',
					'Bungee Shade',
					'Limelight',
					'Grenze Gotisch',
					'Cormorant Upright',
					'McLaren',
					'Turret Road',
					'Fondamento',
					'Oleo Script Swash Caps',
					'Sansita Swashed',
					'Rouge Script',
					'Fanwood Text',
					'Montez',
					'Mansalva',
					'Vast Shadow',
					'Mountains of Christmas',
					'Ruslan Display',
					'Charmonman',
					'Walter Turncoat',
					'Life Savers',
					'Yeon Sung',
					'Sail',
					'Gemunu Libre',
					'Vibur',
					'Cherry Cream Soda',
					'Sulphur Point',
					'Redressed',
					'Engagement',
					'Lovers Quarrel',
					'Monofett',
					'Chicle',
					'Sarina',
					'Croissant One',
					'Text Me One',
					'Wellfleet',
					'Margarine',
					'Mogra',
					'Mogra',
					'Zen Tokyo Zoo',
					'Praise',
					'Moulpali',
					'Trochut',
					'Taprom',
					'Sunshiney',
					'Vibes',
					'Nova Script',
					'Gwendolyn',
					'Kenia',
					'Tourney',
					'Warnes',
					'Ole',
					'Nerko One',
					'Goldman',
					'Germania One',
					'Supermercado One',
				],
			},
		});

		if (!!selectedTemplate) {
			handleTemplatePreview()
				.then(() => {
					setOpen(false);
				})
				.catch((err) => {
					console.log({ 'Error getting card data': err });
					setOpen(false);
				});
		} else {
			getCardData()
				.then(() => {
					setOpen(false);
				})
				.catch((err) => {
					console.log({ 'Error getting card data': err });
					setOpen(false);
				});
		}
	}, [getCardData, previewCardOptions.modalOpen]);

	const checkNumOfPages = (pages: any) => {
		let updatedPages = pages;

		if (updatedPages.length < 4) {
			while (updatedPages.length < 4) {
				updatedPages.push([
					{
						images: [],
						textBoxes: [],
					},
				]);
			}
		} else if (updatedPages.length % 2 !== 0) {
			updatedPages.push([
				{
					images: [],
					textBoxes: [],
				},
			]);
		}

		return updatedPages;
	};

	const springProp = useSpring({
		scale: 1,
		from: { scale: 3 },
		config: { mass: 5, tension: 200, friction: 40, precision: 0.001 },
		delay: 200,
	});
	const [, setisWhiteLabel] = useState(false);
	const [, setImage] = useState('');
	useEffect(() => {
		custom_domain_services.getCustomDomain().then((whitelabelResponse) => {
			if (whitelabelResponse.status) {
				const { connectionStatus, sslStatus, logo } = whitelabelResponse.data;
				if (connectionStatus && sslStatus) {
					setisWhiteLabel(true);
					setImage(logo?.['Dark Logo']);
				}
			}
		});
	}, []);
	useEffect(() => {
		if (window.location.pathname.includes('viewcard')) {
			(async () => {
				try {
					await analyticsService.updateCardOpened(id, recipientId);
				} catch (error) {
					console.log({ error });
				}
			})();
		}
	}, []);
	useEffect(() => {
		if (!previewCardOptions.modalOpen) {
			setNewPages([]);
			setopenCard(true);
		}
	}, [previewCardOptions]);

	const confettiConfig: any = {
		angle: '111',
		spread: '155',
		startVelocity: '50',
		elementCount: '199',
		dragFriction: '0.25',
		duration: '6290',
		stagger: '14',
		width: '12px',
		height: '13px',
		perspective: '296px',
		colors: ['#f00', '#0f0', '#00f'],
	};

	const getBrightness = (color: string) => {
		const hex = color?.replace('#', '');
		const r = parseInt(hex?.substring(0, 2), 16);
		const g = parseInt(hex?.substring(2, 4), 16);
		const b = parseInt(hex?.substring(4, 6), 16);
		const brightness = (r * 299 + g * 587 + b * 114) / 1000;
		return brightness;
	};

	// Determine the appropriate text color based on brightness
	const getTextColor = (backgroundColor: string) => {
		const brightness = getBrightness(backgroundColor);
		return brightness > 128 ? '#3730a3' : '#e0e7ff';
	};

	return (
		<>
			<div
				className="flex items-center relative justify-center md:w-auto md:h-auto w-screen  h-screen  no-scrollbar"
				style={{
					background: currentWorkspace?.previewSetting?.solid
						? currentWorkspace?.previewSetting?.solidColor
						: `radial-gradient(at center bottom, ${currentWorkspace?.previewSetting?.startColor}, ${currentWorkspace?.previewSetting?.endColor})`,
				}}
			>
				<div className="absolute inset-2/4">
					{(isConfettiOn && width > breakpoint) && <Confetti active={isConfettiOn} config={confettiConfig} />}
				</div>
				{/* //? Outer Layout - Background */}
				<div className={!!isPreviewModal ? 'hidden' : ' flex flex-wrap no-scrollbar'}>
					<div className=" w-screen md:flex hidden" style={{ height: '100vh' }}></div>
				</div>
				<div
					style={{ fontFamily: 'Urbanist', display: routePath.includes('viewcard') ? '' : 'none' }}
					className="absolute  h-20 md:top-8 top-12 text-gray-800 font-semibold md:text-xl text-base  flex m-5  justify-center"
				>
					{cardHeader ? cardHeader : `Hey ${recipientName}, ${senderName} created a digital card for you.`}
				</div>
				<div
					style={{ display: routePath.includes('viewcard') ? '' : 'none' }}
					className={
						currentWorkspace?.previewSetting?.cardClanLogo
							? 'absolute  left-0 w-40 h-20 top-0 flex m-6 items-start justify-start'
							: 'hidden'
					}
				>
					<img className="object-contain " alt="logo" src={currentWorkspace?.previewSetting?.companyLogo} />
				</div>

				<a.div
					style={springProp}
					className={`p-10 absolute 2xl:top-10 -top-4  xl:block hidden  ${
						!!isPreviewModal ? '' : openCard ? '2xl:pt-20 md:pt-36 -ml-64 z-30' : '2xl:pt-20 md:pt-36 ml-10 z-30'
					} `}
					onClick={() => setopenCard(false)}
				>
					{!!newPages.length && (
						<CardViewer
							pages={newPages}
							mainClass="grid grid-cols-2 cursor-pointer  "
							leftPageClass="absolute right-20  "
							rightPageClass=" absolute left-0 "
						/>
					)}
				</a.div>
				<div className={!!newPages.length ? 'xl:hidden absolute   ' : 'hidden'}>
					<CardViewerMobile pages={newPages} />
				</div>
				<div
					style={{ display: routePath.includes('viewcard') ? '' : 'none' }}
					className={isCTA ? 'absolute bottom-24' : 'hidden'}
				>
					<a href={`https://${ctaLink}`} target="_blank" className="px-3 py-2 text-white bg-indigo rounded-lg" rel="noreferrer">
						{ctaText}
					</a>
				</div>
				<div
					style={{
						bottom: '70px',
						left: '45%',
						color: currentWorkspace?.previewSetting?.solid
							? getTextColor(currentWorkspace?.previewSetting?.solidColor)
							: getTextColor(currentWorkspace?.previewSetting?.startColor),
					}}
					// #4F46E5
					className={openCard ? 'absolute  z-40  xl:block  hidden ' : 'hidden'}
				>
					<ToolTipCard text={'Click on card to open it.'} textFontSize="18px" isPreviewModal={isPreviewModal} />
				</div>
			</div>
			<Modal open={open} className="-mt-40">
				<div className="bg-white p-10 text-radicalRed rounded-md md:mx-0 mx-3 ">
					<h1 className="text-center"> {I18n.t('generate_card_preview')}</h1>
					<div className="flex place-items-center justify-center pt-5 ">
						<div className="loader"></div>
					</div>
				</div>
			</Modal>
			{/* </AppContainer>0 */}
		</>
	);
};

export default React.memo(ViewCard);
