import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardProspectsContext } from '../../../../../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../../../../../contexts/DesignTool/DesignToolContext';
import { setCurrentCardData } from '../../../../../../../redux/actions';
import { card_services } from '../../../../../../../services/request/card';
import { card_prospect_services } from '../../../../../../../services/request/cardProspect';
import { prospect_list_services } from '../../../../../../../services/request/prospectList';
import ContactList from './ContactList';
import './../../Modalstyle.css';
declare const window: any;

const ContactListContainer: React.FC = () => {
	const {
		setSelectedProspectList,
		mergeTags,
		prospectListEdit,
		currentFile,
		prospectLists,
		setprospectLists,
		setMergeTags,
	} = useContext(DesignToolContext);
	const { contactListModalOpen, setContactListModalOpen, csvFile, setCsvFile, setCsvUploadFinalized } =
		useContext(CardProspectsContext);
	const [addNewMergeTag, setAddNewMergeTag] = useState<boolean>(false);
	const [parsedProspects, setparsedProspects] = useState<Array<any> | null>(null);
	const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
	const userProfile = useSelector((state: any) => state.userProfile);
	const [disableNext, setdisableNext] = useState<boolean>(false);
	const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
	const card = useSelector((state: any) => state.card);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const handleCloseModal = () => {
		setContactListModalOpen(false);
		setTimeout(() => {
			const html: any = document.body.parentNode as HTMLElement | null;
			html.classList.add('modal_close');
		}, 50);
	};
	const handleFileUpload = (e: any) => {
		if (e?.target?.files?.length) {
			setCsvFile(e.target.files[0]);
		}
	};
	const toggleAddMergeTag = () => {
		setAddNewMergeTag((prev) => !prev);
	};
	const handleFinalizeCsv = async () => {
		setLoading(true);
		await addProspectListHandler();
		if (!whiteLabelSettings) {
			const rows = parsedProspects?.length;
			if (window.analytics) {
				window.analytics.track('Csv_Uploaded ', {
					rows,
				});
			}
			if (window.hyperengage) {
				window.hyperengage('track', 'Csv_Uploaded ', {
					properties: {
						rows: rows,
					},
				});
			}
		}
		setLoading(false);
		handleCloseModal();
		setCsvUploadFinalized(true);
	};

	/**
	 * Adds prospect list in the database and selected file to prospect list being added
	 */
	const addProspectListHandler = async () => {
		try {
			/**
			 * prospectListEdit - A toggle to check whether prospect list is added or edited
			 * currentFile - The file that user wants to edit
			 */
			if (prospectListEdit) {
				if (currentFile) {
					const prospectList = prospectLists?.find((item) => {
						return item.fileName === currentFile;
					});
					if (prospectList?._id) {
						try {
							await card_services.updateMergeTags(card.currentCardId, mergeTags, prospectList._id);
							const cardFromDb = await card_services.getCardByIdForMergeTag(card.currentCardId);
							setSelectedProspectList(prospectList.fileName);
							setMergeTags(cardFromDb.mergeTags);
							dispatch(setCurrentCardData(cardFromDb));
						} catch (error) {
							console.log(error);
						}
					} else {
						console.log('No prospect list id found while updating merge tags');
					}
				} else {
					console.log('No current file found');
				}
			} else {
				if (parsedProspects != null) {
					try {
						const res = await prospect_list_services.createProspectList(
							currentWorkspace?._id,
							userProfile?._id,
							csvFile?.name || 'Unnamed file',
							parsedProspects,
							card.currentCardId,
							mergeTags
						);
						const prospectLists = await prospect_list_services.getProspectListWithoutProspects(currentWorkspace._id);
						await card_prospect_services.createCardProspects(card?.currentCardId, res?.prospectListData.fileName, {
							...card.currentCardData,
							mergeTags: [...(res.mergeTags || [])],
						});
						setprospectLists(prospectLists);
						setMergeTags(res.mergeTags);
						setSelectedProspectList(res?.prospectListData.fileName);
						dispatch(
							setCurrentCardData({
								...card.currentCardData,
								mergeTags: [...(res.mergeTags || [])],
							})
						);
					} catch (error) {
						console.log(error);
					}
				} else {
					console.log('No prospects found.');
				}
			}
		} catch (error) {
			console.log('Some error ocurred while processing the prospect-list/contacts-list', error);
		}
	};

	return (
		<ContactList
			open={contactListModalOpen}
			loading={loading}
			handleCloseModal={handleCloseModal}
			csvFile={csvFile}
			handleFileUpload={handleFileUpload}
			addNewMergeTag={addNewMergeTag}
			toggleAddMergeTag={toggleAddMergeTag}
			handleFinalizeCsv={handleFinalizeCsv}
			setparsedProspects={setparsedProspects}
			parsedProspects={parsedProspects}
			addProspectListHandler={addProspectListHandler}
			setdisableNext={setdisableNext}
			disableNext={disableNext}
		/>
	);
};

export default ContactListContainer;
