import Konva from 'konva';
import React, { useRef } from 'react';
import { Group, Rect } from 'react-konva';
import { Html } from 'react-konva-utils';

interface Props {
	ctaProps: any;
}

const CallToAction: React.FC<Props> = ({ ctaProps }) => {
	const rectRef = useRef<Konva.Rect>(null);
	return (
		<Group draggable={ctaProps.draggable} x={ctaProps.x} y={ctaProps.y} id={ctaProps.id} name={ctaProps.name}>
			<Html>
				<a
					href={ctaProps.urlLink}
					target="_blank"
					rel="noreferrer"
					style={{
						borderRadius: ctaProps.borderRadius === 'rounded' ? '10px' : '0px',
						padding: '12px',
						textDecoration: 'none',
						position: 'absolute',
						textAlign: 'center',
						color: ctaProps.textColor,
						backgroundColor: ctaProps.btnColor,
						width: ctaProps.width,
					}}
					ref={(ref: HTMLAnchorElement) => {
						if (ref) {
							if (rectRef.current) {
								rectRef.current.setAttr('width', ref?.offsetWidth);
								rectRef.current.setAttr('height', ref?.offsetHeight);
							}
						}
					}}
				>
					{ctaProps?.text}
				</a>
			</Html>
			<Rect ref={rectRef} width={ctaProps.width} height={ctaProps.height} />
		</Group>
	);
};

export default CallToAction;
