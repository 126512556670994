export const cardProspectsDefault = {
	cardProspectsData: {
		cardProspects: [],
		errors: { errorCount: 0, invalidEmailCount: 0 },
		hasNextEntries: true,
		totalDecrementCount: 0,
	},
	setCardProspectsData: () => null,
	csvFile: null,
	setCsvFile: () => null,
	csvModalOpen: false,
	setCsvModalOpen: () => null,
	contactListModalOpen: false,
	setContactListModalOpen: () => null,
	csvUploadFinalized: false,
	setCsvUploadFinalized: () => null,
	cardScheduledProspectData: {
		cardProspects: [],
		errors: { errorCount: 0, invalidEmailCount: 0 },
		hasNextEntries: true,
		totalDecrementCount: 0,
	},
	setCardScheduledProspectData: () => null,
};

export const selectedEmailAccountDefault = {
	selectedEmailAccount: null,
	setSelectedEmailAccount: () => null,
};

export const fromNameDefault = {
	fromName: '',
	subjectLine: '',
	cardPreviewHeader:'',
	cardPreviewFooter:'',
	viewCardButtonColor:'#ff2c68',
	setSubjectLine: () => null,
	setFromName: () => null,
	setCardPreviewHeader:() => null,
	setCardPreviewFooter:() => null,
	setViewCardButtonColor:() => null,
};
