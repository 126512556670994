import React from 'react';


interface Props {
	text: string;
	textFontSize?: string;
	isPreviewModal?: boolean;
}

const ToolTipCard: React.FC<Props> = ({ text, textFontSize, isPreviewModal }) => {
	return (
		<div
			className='border-0 xl:border-2 border-indigo '
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				padding: '0px',
				position: 'absolute',
				width: '300px',
				opacity: 0.9,
				left: '25%',
				transform: 'translate(-30%, 50%)',
				top: '',
				background: '',
				// color: '#4F46E5',
				borderRadius: isPreviewModal ? '0.4rem 0.4rem 0.4rem 0.4rem ' : '0px',

			}}
		>
			<div
				className='px-0 '
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'static',
					left: '0px',
					top: '0px',
					flex: 'none',
					order: '0',
					alignSelf: 'stretch',
					flexGrow: '0',
					margin: '0px 0px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						columnGap: '10px',
						alignItems: 'flex-start',
						padding: '0px',
						position: 'static',
						left: '24px',
						top: '24px',
						flex: 'none',
						order: '0',
						alignSelf: 'stretch',
						flexGrow: '0',
						margin: '0px 0px',
					}}
				>
					<div

						style={{
							position: 'static',
							left: '0px',
							display: 'flex',
							flexDirection: 'row',
							columnGap: '10px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: '500',
							fontSize: textFontSize || '20px',
							// color: '#4F46E5',
							order: '1',
							alignSelf: 'stretch',
							flexGrow: '0',
						}}
					>
						<div
						>
						{text}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ToolTipCard;
