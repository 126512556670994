import React, { useCallback, useState } from 'react';
import ToolTipCardSwipe from '../ToolTipSwipe/ToolTipSwipeCard'
import Viewpager from './ViewerPager';

interface Props {
	pages: (() => JSX.Element)[];
}

const CardViewerMobile: React.FC<Props> = ({ pages }) => {
	const [showTooltip, setShowTooltip] = useState(true);
	const hideTooltip = useCallback(() => {
		if (showTooltip) setShowTooltip(false);
	}, [showTooltip]);

	return (
		<>
			<Viewpager pages={pages} hideTooltip={hideTooltip} />
			{showTooltip && <ToolTipCardSwipe text={'👈 Swipe to view your card 👉'} />}
		</>
	);
};

export default React.memo(CardViewerMobile);
