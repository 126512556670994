/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { ROUTE_NAMES } from '../../../../Routes/routeNames';
import { NavLink } from 'react-router-dom';

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}
interface Props {
	workspaces: any;
	currentWorkspace: any;
	handleSetCurrentWorkspace: any;
}
const Index: React.FC<Props> = ({ workspaces, currentWorkspace, handleSetCurrentWorkspace }) => {
	const [selected, setSelected] = useState(workspaces);

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					<div className="mt-1 relative ">
						<Listbox.Button className="relative w-full bg-offWhite border-none rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none  sm:text-sm hover:opacity-70">
							<span className="flex items-center ">
								<img
									src={currentWorkspace?.workspaceLogo}
									alt=""
									className={!!currentWorkspace?.workspaceLogo ? 'flex-shrink-0 h-6 w-6 rounded-full ' : 'hidden'}
								/>
								<div className="h-6 w-6 rounded-full">
									<span
										className={
											currentWorkspace?.workspaceLogo
												? 'hidden'
												: 'text-radicalRed text-lg border border-shadowColor h-6 w-6 rounded-full flex justify-center items-center'
										}
									>
										{currentWorkspace?.name?.toUpperCase().substring(1, 0)}
									</span>
								</div>
								<div className="ml-6">
									<span className="block truncate leading-4 text-xs">WORKSPACE</span>

									<span className="block truncate font-normal text-sm leading-3">{currentWorkspace?.name}</span>
								</div>
							</span>
							<span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
								{workspaces.map((workspace: any) => (
									<Listbox.Option
										key={workspace._id}
										className={({ active }) =>
											classNames(
												active ? 'text-white bg-purpleTheme cursor-pointer' : 'text-black',
												'cursor-pointer select-none relative pr-9'
											)
										}
										value={workspace}
									>
										{({ selected, active }) => (
											<>
												<div
													onClick={() => handleSetCurrentWorkspace(workspace)}
													className="flex items-center py-2 pl-3 h-full"
													id="listbox-option-0"
												>
													<div className="bg-white rounded-full h-6 w-6  flex justify-center items-center mr-3 border border-shadowColor">
														<img
															className={workspace?.workspaceLogo ? 'flex-shrink-0 h-6 w-6 rounded-full ' : 'hidden'}
															alt="logo"
															src={workspace?.workspaceLogo}
														/>
														<p className={workspace?.workspaceLogo ? 'hidden' : 'text-radicalRed text-lg'}>
															{workspace?.name?.toUpperCase().substring(1, 0)}
														</p>
													</div>

													<span
														className={classNames(selected ? 'font-semibold ' : 'font-normal', 'ml-3 block truncate')}
													>
														{workspace?.name}
													</span>
												</div>
												{selected ? (
													<span
														className={classNames(
															active ? 'text-white' : 'text-purpleTheme',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
								<NavLink
									to={ROUTE_NAMES.manageworkspace}
									className=" py-2 font-normal flex truncate text-base text-textGrayesh hover:underline justify-center items-center gap-x-2  border-t border-shadowColor border-opacity-70"
								>
									<p> view all</p>
								</NavLink>
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};
export default Index;
