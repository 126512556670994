import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setcurrentWorkspace, setUserPlan, setUserProfile, setworkspaces } from '../redux/actions';
import { layout_verification_service } from '../services/request/layoutVerification';
import { AuthContext } from '../utils/auth';
import { ROUTE_NAMES } from './routeNames';

interface Props {
	component: React.FC;
	rest?: any;
}

const PrivateRoute = ({ component: RouteComponent }: Props) => {
	const { currentUser }: any = useContext(AuthContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!currentUser) {
			navigate(ROUTE_NAMES.register);
		}
	}, [currentUser, navigate]);

	useEffect(() => {
		layout_verification_service.verifyPrivateLayout().then((res) => {
			if (!res?.status) {
				res?.userPlan && dispatch(setUserPlan(res?.userPlan));
				res?.profile && dispatch(setUserProfile(res?.profile));
				res?.workspaces && dispatch(setworkspaces(res?.workspaces));
				const selectedWorkspace = res?.profile?.selectedWorkspace
					? res?.workspaces?.find((ws) => ws._id === res?.profile?.selectedWorkspace)
					: res?.workspaces?.length && res?.workspaces[0];
				selectedWorkspace && dispatch(setcurrentWorkspace(selectedWorkspace));
				res?.url && navigate(res?.url);
			} else {
				dispatch(setUserPlan(res?.userPlan));
				dispatch(setUserProfile(res?.profile));
				dispatch(setworkspaces(res?.workspaces));
				const selectedWorkspace = res?.profile?.selectedWorkspace
					? res?.workspaces?.find((ws) => ws._id === res?.profile?.selectedWorkspace)
					: res?.workspaces?.length && res?.workspaces[0];
				dispatch(setcurrentWorkspace(selectedWorkspace));
			}
		});
	}, [dispatch, navigate]);

	return <RouteComponent key={RouteComponent.name} />;
};

export default PrivateRoute;
