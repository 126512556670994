import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';

interface Props {
	closeSceduleLaterModalHandler: () => void;
	openScheduleLaterModal: boolean;
	cancelButtonRefScheduleLater: React.MutableRefObject<null>;
	openScheduleSkipContinueModalHandler: () => void;
}

const ScheduleLaterModal: React.FC<Props> = ({
	closeSceduleLaterModalHandler,
	openScheduleLaterModal,
	cancelButtonRefScheduleLater,
	openScheduleSkipContinueModalHandler,
}) => {
	return (
		<Transition.Root show={openScheduleLaterModal} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRefScheduleLater}
				onClose={closeSceduleLaterModalHandler}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-successBgColor sm:mx-0 sm:h-10 sm:w-10">
									<ExclamationIcon className="h-6 w-6 text-errorIconColor rounded-full" aria-hidden="true" />
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Missing data
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											You have some missing data in the columns which might cause an issue in sending the cards.
										</p>
									</div>
								</div>
							</div>
							<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo text-base font-medium text-white hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
									onClick={closeSceduleLaterModalHandler}
								>
									Update data
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 focus:outline-none bg-white text-base font-medium text-gray-700 sm:mt-0 sm:w-auto sm:text-sm"
									onClick={openScheduleSkipContinueModalHandler}
									ref={cancelButtonRefScheduleLater}
								>
									{'Skip & continue'}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ScheduleLaterModal;
