import React from 'react';
import AddRecipientModal from './AddRecipientModal';

interface Props {
	closeEditModalHandler: () => void;
	openEditModalHandler: (edit: boolean) => void;
	openEditModal: boolean;
	recipientEdit: boolean;
	recipientOnEdit?: any;
	updateRecipientHandler?: (e: any) => Promise<void>;
	recipientValueChangeHandler: (inputLabel: string, value: string) => void;
	isRecipientEditLoading: boolean;
}

const AddRecipientModalContainer: React.FC<Props> = ({
	closeEditModalHandler,
	openEditModalHandler,
	openEditModal,
	recipientEdit,
	recipientOnEdit,
	updateRecipientHandler,
	recipientValueChangeHandler,
	isRecipientEditLoading,
}) => {
	return (
		<AddRecipientModal
			closeEditModalHandler={closeEditModalHandler}
			openEditModalHandler={openEditModalHandler}
			openEditModal={openEditModal}
			updateRecipientHandler={updateRecipientHandler}
			recipientEdit={recipientEdit}
			recipientOnEdit={recipientOnEdit}
			recipientValueChangeHandler={recipientValueChangeHandler}
			isRecipientEditLoading={isRecipientEditLoading}
		/>
	);
};

export default AddRecipientModalContainer;
