import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { CardData } from './card.interface';

export const card_prospect_services = {
	createCardProspects,
	getCardProspects,
	updateCardProspectStatus,
	updateAllCardProspectsStatus,
	updateCardProspect,
	createCardProspect,
	updateCardProspects,
	createCardProspectsWihoutCsv,
	downloadRecipientsCsv,
};

/**
 *
 * @param cardId Card Id
 * @param fileName prospect list file name.
 * @param cardData card data
 * @returns
 */
function createCardProspects(cardId: string, fileName: string, cardData: CardData): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const { cardPages, ...card } = cardData;
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/cardProspect`,
				data: {
					cardId,
					fileName,
					cardPages,
					card,
				},
			};

			const resp = await axios(config);
			const response = resp.data.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 *
 * @param cardId Card Id
 * @param fileName prospect list file name.
 * @param prospectData prospect data
 * @param mergeTags merge tags array
 * @returns
 */
function createCardProspectsWihoutCsv(
	cardId: string,
	fileName: string,
	prospectData: any,
	mergeTags: any
): Promise<any> {
	console.log(fileName);
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/cardProspect/withoutcsv`,
				data: {
					cardId,
					fileName,
					prospectData,
					mergeTags,
				},
			};

			const resp = await axios(config);
			const response = resp.data.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 *
 * @param cardId Card Id
 * @param prospectListId Id of prospect list
 * @param skip skip documents
 * @param limit limit documents for single query
 * @param searchValue search value or empty string
 * @param selected if true will return only the card Prospect with status SELECTED
 * @returns `cardProspects`, `errors: { errorCount, invalidEmailCount }`, `hasNextEntries`
 */
function getCardProspects(
	cardId: string,
	prospectListId: string,
	skip: number,
	limit: number,
	searchValue: string,
	selected: boolean = false
): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/cardProspect`,
				params: {
					cardId,
					prospectListId,
					skip,
					limit,
					searchValue,
					selected,
				},
			};

			const resp = await axios(config);
			const response = resp.data.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 *
 * @param status status of document
 * @param cardProspectId Card Prospect Id
 * @returns `cardProspects`, `errors: { errorCount, invalidEmailCount }`, `hasNextEntries`
 */
function updateCardProspectStatus(cardProspectId: string, status: string): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/cardProspect/status/${cardProspectId}`,
				data: {
					status,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 *
 * @param prospectListId
 * @param cardId
 * @param checked
 * @returns Promise
 */
function updateAllCardProspectsStatus(prospectListId: string, cardId: string, checked: boolean): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/cardProspect/selectall/all`,
				data: {
					prospectListId,
					cardId,
					checked,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 * @param cardProspectId
 * @param cardProspectData
 * @returns
 */
function updateCardProspect(cardProspectId: string, cardProspectData: any): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/cardProspect/${cardProspectId}`,
				data: {
					cardProspectData,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 * @param prospectListId
 * @param cardId
 * @param cardProspectData
 * @returns
 */
function createCardProspect(prospectListId: string, cardId: string, cardProspectData: string): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/cardProspect/prospect`,
				data: {
					cardProspectData,
					prospectListId,
					cardId,
				},
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 * Bulk updates the card Prospects
 * @param prospectListId
 * @param cardId
 * @param date
 * @param dateAndTimeOffset
 * @param saveNow
 * @returns message if successfully updated all the card prospects
 */
function updateCardProspects(
	prospectListId: string,
	cardId: string,
	date: number,
	dateAndTimeOffset: number,
	saveNow: boolean
): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/cardProspect/user/prospect`,
				data: {
					date,
					dateAndTimeOffset,
					saveNow,
					prospectListId,
					cardId,
				},
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}

/**
 * downloads recipients csv which is generated from card prospects
 * @param cardId
 * @param prospectListId
 * @returns
 */
function downloadRecipientsCsv(cardId: string, prospectListId: string, cardTitle: string): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/cardProspect/downloadcsv`,
				data: {
					cardId,
					prospectListId,
				},
				responseType: 'blob',
			};

			const response = await axios(config);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${cardTitle || 'untitled-' + new Date().toLocaleString()}.csv`);
			document.body.appendChild(link);
			link.click();
			resolve(true);
		} catch (error: any) {
			console.log('Axios catch exec', { error });
			reject(error);
		}
	});
}
