import { AxiosRequestConfig } from 'axios';
import axios from './axios';
import { BillingLimits } from './billingLimits.interface';

export const billing_limit_services = {
	getBillingLimits,
};

/**
 *
 * @param userId user's Id of workspace owner
 * @returns an object of user's limits for current plan
 */
function getBillingLimits(userId: string): Promise<BillingLimits> {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: '/composite/all-limits',
				params: {
					userId,
				},
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
}
