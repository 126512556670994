import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { card_services } from '../../../../../services/request/card';
import { user_persona } from '../../../../../services/request/persona';
import { user_templates } from '../../../../../services/request/template';
import { TemplateInterface } from '../../../../../services/request/template.interface';
import TemplateImage from './components/TemplateImage';
import { stageDimensions } from '../../../../../utils/defaults';
import { setCurrentCardData, setCurrentCardId, setcurrentWorkspace } from '../../../../../redux/actions';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from '../../../../../Routes/routeNames';
import CustomSpinnerLoader from '../../../../../components/CustomSpinnerLoader';
import { Persona } from '../../../../../services/request/persona.interface';
interface Props {
	setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateInterface | null>>;
}
declare const window: any;
const PreBuiltTemplates: React.FC<Props> = ({ setSelectedTemplate }) => {
  const [getTemplateLoading, setTemplateLoading] = useState(false)
  const [TemplatetoCardLoading, setTemplatetoCardLoading] = useState(false)
  const [templateCategory, setTemplateCategory] = useState<Persona[]>();
  const [selectedCategory, setSelectedCategory] = useState<Persona>({ _id: "637ddb41b8732c1f33ebc4db", name: "Happy Thanksgiving" });
  const [selectedTemplateid, setSelectedtemplateid] = useState<String | null>(null);
  const userProfile = useSelector((state: any) => state.userProfile);
  const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
  const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
  const currentCard = useSelector((state: any) => state.card);
  const [templates, setTemplates] = useState<any>();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleGetUserPersona = async () => {
    try {
      const response = await user_persona.getUserPersona()
      if (response) {
        setTemplateCategory(response)
        setSelectedCategory(response[0])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleGetAllTemplates = async () => {
    setTemplateLoading(true)
    try {
      const response: TemplateInterface[] = await user_templates.getAllTemplates()
      setTemplates(response)
      setTemplateLoading(false)
		} catch (error) {
			setTemplateLoading(false);
			console.log(error);
		}
	};
	useEffect(() => {
		handleGetUserPersona();
		handleGetAllTemplates();
		if (userProfile.persona) {
			setSelectedCategory(userProfile.persona);
		}
	}, []);
	const ref: any = useRef(null);
	const handleTemplatetoCard = async (template: TemplateInterface) => {
		setSelectedtemplateid(template._id);
		setTemplatetoCardLoading(true);
		const { card } = template;
		let cardTitle = template.card.title;
		if (!whiteLabelSettings) {
			if (window.analytics) {
				window.analytics.track('Template used', {
					cardTitle,
				});
			}
			if (window.hyperengage) {
				window.hyperengage('track', 'Template used', {
					properties: {
						cardTitle: cardTitle,
					},
				});
			}
		}
		try {
			let cardRef = await card_services.createNewCard(userProfile._id, {
				...card,
				workspace_id: currentWorkspace._id,
				stage: stageDimensions,
				status: 'DRAFT',
				owner: userProfile._id,
			});
			dispatch(setCurrentCardId(cardRef._id));
			dispatch(setCurrentCardData({ ...currentCard.currentCardData, ...cardRef.data }));
			dispatch(
				setcurrentWorkspace({
					...currentWorkspace,
					cards: [...(currentWorkspace.cards || []), cardRef._id],
				})
			);
			setTemplatetoCardLoading(false);
			navigate(ROUTE_NAMES.createcard + '?id=' + cardRef._id);
		} catch (error: any) {
			setTemplatetoCardLoading(false);
			swal({
				icon: 'error',
				title: error.message,
			});
			console.log(error);
			// setIsLoading(false);
		}
	};

	return (
		<div className="bg-white mt-10 py-8 px-8 rounded-lg shadow-lg w-full">
			<div className="w-full">
				<div className="flex justify-between items-center ">
					<div className="flex items-center">
						<span className="text-2xl leading-9 font-bold">Pre Built Templates</span>{' '}
						<span className="bg-greenText rounded-full text-green100 px-3 py-0.5 ml-2 text-sm">New feature</span>
					</div>
				</div>
				<div className="flex mt-2 gap-x-3 gap-y-2 flex-wrap text-center justify-start items-center ">
					{templateCategory &&
						templateCategory.map((item) => {
							return (
								<button
									key={item._id}
									className={
										item === selectedCategory
											? 'px-4 py-2  bg-darkIndigo transition duration-500 text-lightIndigo rounded-md flex-shrink-0 hover:opacity-80'
											: 'transition duration-500 hover:scale-105 transform px-4 py-2 bg-lightIndigo text-darkIndigo  bg-lightBlueHover rounded-md flex-shrink-0'
									}
									onClick={() => setSelectedCategory(item)}
								>
									{item.name}
								</button>
							);
						})}
					<button></button>
				</div>
				<div className="border-t-2 my-5"></div>
				<CustomSpinnerLoader isLoading={getTemplateLoading} className="h-96" />
				<div className={getTemplateLoading ? 'hidden' : 'flex items-center relative'}>
					<div
						className="mt-5 flex flex-wrap gap-y-10 gap-x-10 no-scrollbar relative"
						ref={ref}
						style={{ width: '78vw' }}
					>
						{templates
							?.filter((card: any) => card?.persona?.name === selectedCategory.name)
							.map((template: any) => (
								<TemplateImage
									template={template}
									setSelectedTemplate={setSelectedTemplate}
									selectedTemplateid={selectedTemplateid}
									handleTemplatetoCard={handleTemplatetoCard}
									TemplatetoCardLoading={TemplatetoCardLoading}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreBuiltTemplates;
