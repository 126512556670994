import {
	createUserWithEmailAndPassword,
	EmailAuthProvider,
	getAuth,
	getRedirectResult,
	linkWithCredential,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import firebaseApp from '../config/firebase';

class firebaseServicesClass {
	constructor() {
		this.auth = getAuth(firebaseApp);
		this.db = getFirestore(firebaseApp);
	} 
  
	getRedirectResult = async () => await getRedirectResult();

	loginWithAuthProvider = async (provider) => await signInWithPopup(getAuth(firebaseApp), provider);

	sendVerificationEmail = (actionCodeSetting) =>
		sendEmailVerification(getAuth(firebaseApp).currentUser, actionCodeSetting);

	loginWithEmail = (email, password) => signInWithEmailAndPassword(getAuth(firebaseApp), email, password);

	signupWithEmail = (email, password) => createUserWithEmailAndPassword(getAuth(firebaseApp), email, password);
  refrestToken = async () => await getAuth().currentUser.getIdToken(true)
	signOut = () => {
		this.auth.signOut().then((res) => {
			const app_version = localStorage.getItem('app_version');
			localStorage.clear();
			sessionStorage.clear();
			localStorage.setItem('app_version', app_version);
			window.location.href = '/auth/login';
		});
	};
	signOutforTokenExpiration = () => {
		this.auth.signOut().then((res) => {
			const app_version = localStorage.getItem('app_version');
			localStorage.clear();
			sessionStorage.clear();
			localStorage.setItem('app_version', app_version);
		});
	};

	getCurrentUser = () => getAuth(firebaseApp).currentUser;

	getReloadedCurrentUser = async () => {
		await this.auth.currentUser?.reload();
		return this.auth.currentUser;
	};

	checkUserAuth = (user) => this.auth.onAuthStateChanged(user);

	forgotPassword = (email = '') => {
		const actionCodeSettings = {
			url: `${process.env.REACT_APP_PUBLIC_URL}`,
			handleCodeInApp: true,
		};

		return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
	};

	// isVerificationRequiredWithoutReload = () => {
	//     let user = this.auth.currentUser
	//     user && user.reload()
	//     let providerData =
	//         user?.providerData || this.auth.currentUser?.providerData
	//     let providerIds = providerData?.map((pd) => pd.providerId)
	//     let excludedProviderIds = ["facebook.com", "google.com"]
	//     let isVerificationRequired = !providerIds?.some((providerId) =>
	//         excludedProviderIds.includes(providerId)
	//     )
	//     return isVerificationRequired && !user?.emailVerified
	// };

	// ANCHOR - for email verification check
	// isVerificationRequired = async () => {
	//     await this.auth.currentUser?.reload()
	//     let user = this.auth.currentUser
	//     let providerData =
	//         user?.providerData || this.auth.currentUser?.providerData
	//     let providerIds = providerData?.map((pd) => pd.providerId)
	//     let excludedProviderIds = ["facebook.com", "google.com"]
	//     let isVerificationRequired = !providerIds?.some((providerId) =>
	//         excludedProviderIds.includes(providerId)
	//     )
	//     return isVerificationRequired && !user?.emailVerified
	// };

	collaboratorSignIn = () => signInAnonymously(this.auth);

	addCollaboratorAsVerified = (email, password) => {
		let credential = EmailAuthProvider.credential(email, password);
		return linkWithCredential(getAuth(firebaseApp).currentUser, credential);
	};

	/**
	 * @param {email} email valid email address of the user
	 * @param {password} password valid password string
	 * @param {lt_bf} lt_bf boolean flag for black friday launch
	 * @returns `response`: `{ status: boolean, message: string }`
	 */
	createCustomUser = async ({ uid, email, password, lt_bf }) => {
		console.log('run createUser service', uid, email, password);

		// try {
		//   var config = {
		//     method: 'post',
		//     url: '/auth/signup',
		//     data: {
		//       uid,
		//       email,
		//       password,
		//       lt_bf
		//     }
		//   };

		//   const resp = await axios(config);
		//   const response = resp.data;
		//   console.log({ response });
		//   return response;
		//   //  resolve(response);
		// } catch (error) {
		//   //  reject(error)
		// }

		return await fetch(`${process.env.REACT_APP_FUNCTIONS_REQUEST_URL}/customAuth-createUser`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid,
				email,
				password,
				lt_bf,
			}),
		})
			.then((res) => res.json())
			.then((response) => {
				console.log('response from backend friebaseAuh', response);
				return response;
			})
			.catch((error) => {
				console.log({ error });
				return { status: false, error };
			});
	};

	signInWithCustomToken = async (email) => {
		return await fetch(`${process.env.REACT_APP_FUNCTIONS_REQUEST_URL}/customAuth-generateCustomToken`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
			.then((res) => res.json())
			.then((response) => {
				signInWithCustomToken(this.auth, response.customAuthToken).then((userCredential) => {
					// Signed ins
					const user = userCredential.user;
					return user;
				});
			})
			.catch((error) => {
				console.log({ error });
			});
	};
	resendCustomVerificationEmail = async () => {
		if (!this.auth.currentUser) return;

		const email = this.auth.currentUser?.email;
		return await fetch(`${process.env.REACT_APP_FUNCTIONS_REQUEST_URL}/customAuth-resendVerificationEmail`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
			.then((res) => res.json())
			.then((response) => response)
			.catch((error) => {
				console.log({ error });
				return { status: false, error: error.message };
			});
	};
}
const firebaseAuthServices = new firebaseServicesClass();
export { firebaseAuthServices };
