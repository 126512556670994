import axios from './axios';

export const email_account_services = {
	createEmailAccount,
	getEmailAccount,
	deleteEmailAccount,
	updateEmailAccount,
};

function updateEmailAccount(data) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'put',
				url: '/email-account',
				data: { ...data },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}
function createEmailAccount(data) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'post',
				url: '/email-account',
				data: { ...data },
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function getEmailAccount(workspaceId) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'get',
				url: '/email-account',
				params: {
					workspaceId,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}

function deleteEmailAccount(workspaceId, fromEmail, userId) {
	return new Promise(async (resolve, reject) => {
		try {
			var config = {
				method: 'delete',
				url: '/email-account',
				params: {
					workspaceId,
					userId,
					fromEmail,
				},
			};

			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			console.log('Axios catch exec');
			reject(error);
		}
	});
}
