import { SetState } from '@shrugsy/use-immer-state';
import { EmailAccountInterface } from '../../services/request/emailAccounts.interface';

export type CardProspectErrors = {
	errorCount: number;
	invalidEmailCount: number;
};

export type CardProspectsData = {
	cardProspects: any[];
	errors: CardProspectErrors;
	hasNextEntries: boolean;
	totalDecrementCount: number;
};

export interface ICardProspects {
	cardProspectsData: CardProspectsData;
	setCardProspectsData: SetState<CardProspectsData>;
	csvFile: File | null;
	setCsvFile: SetState<File | null>;
	csvModalOpen: boolean;
	setCsvModalOpen: SetState<boolean>;
	contactListModalOpen: boolean;
	setContactListModalOpen: SetState<boolean>;
	csvUploadFinalized: boolean;
	setCsvUploadFinalized: SetState<boolean>;
	cardScheduledProspectData: CardProspectsData;
	setCardScheduledProspectData: SetState<CardProspectsData>;
}

export interface ISelectedEmailAccount {
	selectedEmailAccount: EmailAccountInterface | null;
	setSelectedEmailAccount: SetState<EmailAccountInterface | null>;
}

export interface IfromName {
	fromName: string;
	subjectLine: string;
	cardPreviewHeader: string;
	cardPreviewFooter: string;
	viewCardButtonColor: string;
	setSubjectLine: SetState<string>;
	setFromName: SetState<string>;
	setCardPreviewHeader: SetState<string>;
	setCardPreviewFooter: SetState<string>;
	setViewCardButtonColor: SetState<string>;
	
}

export enum CardProspectStatusEnum {
	ERROR = 'ERROR',
	INVALID_EMAIL = 'INVALID EMAIL',
	SELECTED = 'SELECTED',
	UNSELECTED = 'UNSELECTED',
	COPY = 'COPY',
}
