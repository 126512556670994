import React, { createContext, useEffect, useState } from 'react';

declare const window: any;

interface IApiButton {
	apiObjectCanva: any;
}

const defaultState = {
	apiObjectCanva: null,
};

export const CanvaButtonContext = createContext<IApiButton>(defaultState);

export const CanvaButtonProvider: React.FC = ({ children }) => {
	const [apiObjectCanva, setApiObjectCanva] = useState<any>(null);

	useEffect(() => {
		((document, url) => {
			const script = document.createElement('script');
			script.src = url;
			script.id = 'canva-image-tool-container';
			script.onload = () => {
				// API initialization
				(async function () {
					if (window.Canva && window.Canva.DesignButton) {
						const api = await window.Canva.DesignButton.initialize({
							apiKey: process.env.REACT_APP_CANVA_API_KEY,
						});
						setApiObjectCanva(api);
					}
				})();
			};
			document.body.appendChild(script);
		})(document, 'https://sdk.canva.com/designbutton/v2/api.js');
	}, []);
	return <CanvaButtonContext.Provider value={{ apiObjectCanva }}>{children}</CanvaButtonContext.Provider>;
};
