import 'gifler';
import Konva from 'konva';
import React, { useEffect } from 'react';
import { Image } from 'react-konva';

declare const window: any;

interface Props {
	gifProps: any;
}

const Gif: React.FC<Props> = ({ gifProps }) => {
	const imageRef = React.useRef<Konva.Image>(null);
	const canvas = React.useMemo(() => {
		const node = document.createElement('canvas');
		return node;
	}, []);

	useEffect(() => {
		// save animation instance to stop it on unmount
		let anim: any;
		window.gifler(gifProps.src).get((a: any) => {
			anim = a;
			anim.animateInCanvas(canvas);
			anim.onDrawFrame = (ctx: any, frame: any) => {
				ctx.drawImage(frame.buffer, frame.x, frame.y);
				imageRef.current?.getLayer()?.draw();
			};
		});
		return () => anim?.stop();
	}, [gifProps.src, canvas]);

	const { src, ...gifImgProps } = gifProps;

	return <Image image={canvas} ref={imageRef} {...gifImgProps} draggable={false} />;
};
export default Gif;
