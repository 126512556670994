const en = {
	verification_email_sent_msg: 'A verification link has been sent to your email account!',
	login_title: 'log in as',

	//single digital card
	single_digital_card: 'Single digital card',
	checkout_heading_1: 'Choose illustrated designs',
	checkout_heading_2: 'Design and sign',
	checkout_heading_3: 'Collaborate and share',
	checkout_heading_4: 'Scheduled auto-delivery',
	checkout_desc_1: "Select one of our artists' illustrations designs as you cover, or create your own",
	checkout_desc_2: 'Design your card with a collection of fonts, colours, styles for text, and upload your own images.',
	checkout_desc_3: 'Our simple share allows you to invite everyone who wants to join in writing a greeting',
	checkout_desc_4: 'Choose the date for this special delivery to auto-send.',

	//checkout
	card_details: 'Card details',
	name_on_card: 'Name on card',
	checkout: 'Checkout',
	pay: 'Pay',
	country: 'Country',
	select_country: 'Select country',
	buy_now: 'Buy Now!',

	//landing
	home: 'My Cards',
	sign_in: 'Sign in',
	login_simple: 'Login',
	login: 'Sign in to your account',
	logout: 'Log Out',
	how_it_works: 'How it works',
	create_your_card: 'Create your card',
	my_cards: 'My Cards',
	a_better_way: 'A better way to make',
	together: 'together.',
	bring_the_whole:
		"Bring the whole Clan together to create and send a single digital card, with everyone's personal message.",
	make_the_feel_special: 'Make them feel special. One card, many messages.',
	copy_right: 'Copyright © 2020 CardClan',
	terms_conditions: 'Terms & Conditions',
	step1: 'Step 1',
	step2: 'Step 2',
	step3: 'Step 3',
	select_the_card: 'Select the card design',
	schedule_date: 'Schedule auto-send date',
	sign_and_share_link: 'Sign and share the link with your kian',
	pricing: 'Pricing',
	select: 'Select',
	close: 'Close',
	//Login
	your_email_address: 'Email address',
	email_address: 'Email address',
	enter_email_address: 'Enter email address',
	your_password: 'Password',
	enter_password: 'Enter password',
	forgot_password: 'Forgot password?',
	new: 'New?',
	sign_up: 'Sign Up',
	appsumo_code: 'AppSumo code',
	enter_appsumo_code: 'Enter AppSumo code',
	registermember: 'Signup to get Started!',

	// profile
	basic_detail: 'Basic Detail',
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	emailgmail: 'email@gmail.com',
	updatebtn: 'Update Profile',
	savebtn: 'Save',
	addteamMemberbtn: 'Add Team member',
	updateteamMemberbtn: 'Update',
	profile: 'Profile',
	Billing_Plan: 'Billing & Plan',
	account_setting: 'Account Settings',
	setting: 'Setting',

	//Billing & plan
	plan_detail: 'Plan Detail',
	orders: 'Orders',
	current_plan: 'Current Plan',
	limits: 'Limits',
	recipients_allowed: 'Recipients Allowed',
	your_limits_will_renew_every_month: 'Your limits will renew every month!',
	increase_limits: 'Increase Limits',

	//404 page
	page_not_found_header: 'The page you are looking for isn’t here',
	page_not_found_para: `You either tried some shady route or you came here by mistake.
                        Whichever it is, try using the navigation`,
	//Change Password
	change_pass: 'Change Password',
	old_pass: 'Old Password',
	new_pass: 'New Password',
	confirm_pass: 'Confirm New Password',

	//SignUp
	hello: 'Hello!',
	register: 'Register',
	signup_to_get_started: 'Signup to get started.',
	choose_a_password: 'Choose a password',
	signup: 'Signup',
	or: 'OR',
	continue_with_facebook: 'Continue with Facebook',
	continue_with_google: 'Continue with Google',
	already_have_an_account: 'Already have an account?',

	//Email Not Verified
	verification_link: 'verification link!',
	check_your_inbox: 'Check your inbox for a',
	open_email: 'Open email',
	resend_verification_link: 'Resend verification link',
	email_not_received: "Didn't receive an email?",
	checking_email_verification: 'Checking email verification...',

	//Forgot Password
	reset_your_password: 'Reset your password',
	send: 'Send',
	password_reset_link_sent: 'Password reset link has been sent to your email address',

	//My Cards
	create_new_card: 'Create new card',
	draft: 'Draft',
	del_draft: 'Delete draft',
	sure_del_draft: 'Are you sure you want to delete this draft card?',
	yes_del_drafe: 'Yes, delete draft',
	no_dont_del: "No, don't delete",
	clone_card: 'Clone',
	scheduled: 'Scheduled',
	finalized: 'Finalized',
	delivered: 'Delivered',
	deliveredon: 'Delivered on ',
	edit: 'Edit',
	delete: 'Delete',
	show_more: 'Show More',
	my: 'My',
	cards: 'Cards',

	//Create Card
	save_and_home: 'Save and go back',
	save_and_return: 'Save & Return',
	choose_outer_design: 'Choose outer design',
	choose: 'Choose',
	cover_design: 'cover design',
	upload_image: 'Upload Image',
	image: 'Image',
	start_new: 'Start new',
	page: 'Page',
	text: 'Text',
	add_message: 'Add Message',
	share: 'Share Card',
	save: 'Save Card',
	gif: 'GIF',
	change_cover: 'Change cover',
	search_giphy_com: 'Search giphy.com',
	generating_pdf: 'Generating Pdf',
	pdf_gen_succ: 'Pdf generated successfully!',
	failed_to_gen_pdf: 'Failed to generate pdf!',
	font_size: 'Font Size',

	create_first_card: 'Create your first card',
	welcome_to_the_clan: 'Welcome to the Clan!',
	start_typing: 'Start typing',
	undo: 'Undo',
	redo: 'Redo',
	select_from_collection: 'Select from collection',
	design_on_canva: 'Design on Canva',
	select_design: 'Select Image',
	add_image: 'Add Image',
	pixabay: 'Pixabay',
	unsplash: 'Unsplash',
	pexels: 'Pexels',
	no_result_found: 'No Result Found',
	//scheduleDelivery
	lets_sch_spcl_del: "Let's schedule the special delivery.",
	their_name: "Receiver's name",
	their_email: "Receiver's email",
	enter_name: 'Enter name',
	choose_date_ddmmyyy: 'Delivery time',
	whos_the_card_from: "Who's the card from",
	your_golf_buddies: "eg. 'Your Golf buddies'",
	remind_me_nxt_yr: 'Remind me next year',
	sch_card_del: 'Schedule card delivery',
	sch_card: 'Schedule your card',
	reschedule_card: 'Reschedule card',
	share_and_get_klan: 'Share and get your clan to sign',
	copy_share_link: 'Copy share link:',
	or_share_via_email: 'Or share via email',
	email_link: 'Email link',
	share_via: 'Share via',

	//success
	wohoo_this_card: 'Wo0hoo! This card is looking great.',
	you_can_view_card_in_profile: 'You can view your card in My Card in your profile',
	create_another_card: 'Create another card',
	//Card Delivered
	card_delivered: 'This card is already delivered.',
	//general
	back_to_home_page: 'Back to home page',
	back_to_card: 'Back to cards',

	//collaborator
	create_your_own_card: 'Create Your Own Card',
	create_account: 'Create Account',
	submit: 'Submit',

	//resources
	resources: 'Resources',
	the_academy: 'The Academy',
	personalised_gifts: 'Personalised Gifts',
	podcast: 'Podcast',
	blog: 'Blog',
	case_study: 'Case Study',

	//support
	support: 'Support',
	contact_us: 'Contact Us',
	faq: 'Faq',
	pulse_support: 'Pulse Support',
	experience_support: 'Experience Support',
	info_mail: 'info@cardclan.com',

	//company
	company: 'Company',
	our_story: 'Our Story',
	careers: 'Careers',
	partners: 'Partners',
	street: '200E on street',
	suite: 'Suite 210,',
	example: 'Example',

	//footer
	cookies: 'Cookies',
	privacy: 'Privacy',
	terms: 'Terms',

	//sweet alerts
	good_job: 'Good job!',
	you_successfully_login: 'You have successfully logged in!',
	failed_to_charge: 'failed to charge',
	uh_oh: 'Uh oh!',
	oops: 'Oops!',
	There_was_a_problem_logging_in_please_contact_support: 'There was a problem logging in, please contact support!',
	are_you_sure: 'Are you sure?',
	Once_deleted_you_will_not_be_able_to_recover_this_page: 'Once deleted, you will not be able to recover this page!',
	poof_Your_page_has_been_deleted: 'Poof! Your page has been deleted!',
	invalid_or_no_password: 'The password is invalid or the user does not have a password.',
	yayy: 'Yayy!',
	profile_updated: 'Profile updated!',
	password_updated: 'Password updated!',
	email_already_used: 'Email already used by another account!',
	not_a_valid_file: 'Not a valid file.',
	invalid_email: 'Invalid Email!',
	please_enter_a_valid_email: 'Please enter a valid email address',
	email_already_added: 'This email is already added!',
	invalid_password: 'Invalid Password',
	invalid_code: 'invalid_code',
	code_is_already_used: 'Code is already used!',

	generate_card_preview: 'Please wait while we generate card preview',
};

export default en;
