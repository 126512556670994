import React, { useContext, useState } from 'react';
import CardSchedule from '.';
import { DesignToolContext } from '../../../contexts/DesignTool/DesignToolContext';

const CardsScheduleContainer: React.FC = () => {
	const [ModelTitle, setTitleModel] = useState('Add some recipients');
  // const confettiConfig = {
  //   angle: 0,
  //   spread: 660,
  //   startVelocity: 90,
  //   elementCount: 700,
  //   dragFriction: 0.11,
  //   duration: 7000,
  //   stagger: 3,
  //   width: '10px',
  //   height: '10px',
  //   perspective: '1500px',
  //   colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  // };
  const confettiConfig = {
    angle: "111",
    spread: "155",
    startVelocity: "50",
    elementCount: "199",
    dragFriction: "0.25",
    duration: "6290",
    stagger: "14",
    width: "12px",
    height: "13px",
    perspective: "296px",
    colors: ["#f00", "#0f0", "#00f"]
  };
	const [progressSteps, setProgressSteps] = useState<any>([]);
	const { showConfetti} = useContext(DesignToolContext);
  return (
		<>
			<div>
				<CardSchedule
					setTitleModel={setTitleModel}
					ModelTitle={ModelTitle}
					progressSteps={progressSteps}
					setProgressSteps={setProgressSteps}
          confettiConfig={confettiConfig}
          showConfetti={showConfetti}
				/>
			</div>
		</>
	);
};

export default CardsScheduleContainer;
