import React from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

type Options = {
	visible: boolean;
	title: string;
	message: string;
	swalType: string;
};
interface Props {
	swalOptions: Options;
	closeSwal: () => void;
	customSwalResponseHandler?: any;
}
const SwalView: React.FC<Props> = ({ swalOptions, closeSwal, customSwalResponseHandler }) => {
	const cancelButtonRef = useRef(null);
	return (
		<Transition.Root show={swalOptions.visible} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={closeSwal}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-mediumGray bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-errorBgColor sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationIcon className="h-6 w-6 text-errorIconColor" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-blackText">
											{swalOptions.title}
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-mediumGray">{swalOptions.message}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2 bg-errorButton text-base font-medium text-white hover:bg-errorButtonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-errorButtonBorder sm:ml-3 sm:w-auto sm:text-sm"
									onClick={customSwalResponseHandler}
								>
									Yes
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-shadowColor shadow-sm px-6 py-2 bg-white text-base font-medium text-blackTextlight hover:bg-lightgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bordergray sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={closeSwal}
									ref={cancelButtonRef}
								>
									No
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default SwalView;
