import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import i18n from 'i18n-js';
import { NavLink } from 'react-router-dom';
function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}
interface Props {
	navBarList: any;
	userProfile: any;
	userEmail: string;
	userRole: string;
	mobileNavBarList: any;
	handleSignout: () => void;
}
const Index: React.FC<Props> = ({ navBarList, userProfile, userEmail, userRole, handleSignout, mobileNavBarList }) => {
	return (
		<Menu as="div" className="relative inline-block text-left  z-50">
			<div>
				<Menu.Button className="inline-flex justify-center rounded-full w-12 h-12  shadow-sm  text-sm font-medium text-gray-700 outline-none ">
					<span className="text-jacksonsPurple font-bold flex justify-center">
						<img
							className={
								!!userProfile.userImage
									? 'mb-2 rounded-full w-12 h-12 flex justify-center place-items-center'
									: 'hidden'
							}
							src={userProfile.userImage}
							alt="logo"
						></img>
						<span
							className={
								!userProfile.userImage
									? 'bg-radicalRed mb-2 rounded-full w-12 h-12 flex justify-center place-items-center text-white text-2xl font-bold '
									: 'hidden'
							}
						>
							{userEmail?.toUpperCase().substring(1, 0)}
						</span>
					</span>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-600"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
					<div className="py-1">
						<div className="flex flex-col justify-center pt-4 pb-2 px-5">
							<p className="truncate">Signed in as</p>
							<span className="text-base truncate">{userEmail}</span>
							<div className="flex-grow-0 flex ">
								<p className=" text-sm rounded-full bg-lightgreen px-3" style={{ paddingTop: 2, paddingBottom: 2 }}>
									{userRole === 'superAdmin' && 'Admin'}
									{userRole === 'teamMember' && 'Team Member'}
									{userRole === 'workspaceManager' && 'workspaceManager'}
								</p>
							</div>
						</div>
						<div className="py-1">
							{navBarList.map((item: any, index:number) => {
								return (
									<div key={item.title}>
										{index === 0 ? (
											<p className="text-whitegray py-2 text-sm pl-5 border-t border-veryLightGray my-1">
												Account Settings
											</p>
										) : index === 4 ? (
											<p className="text-whitegray py-2 text-sm border-t border-veryLightGray  my-1 pl-5">
												Workspace Settings
											</p>
										) : null}
										<div className={item.isVisible ? '' : 'hidden'} >
											<Menu.Item>
												{({ active }) => (
													<NavLink
														to={item.path}
														className={classNames(
															active ? 'bg-grayHover text-black' : 'text-blackTextlight',
															'block px-5 py-2 text-sm'
														)}
													>
														{item.title}
													</NavLink>
												)}
											</Menu.Item>
										</div>
									</div>
								);
							})}
						</div>

						<div className="md:hidden inline-block">
							{mobileNavBarList.map((item: any) => {
								return (
									<div key={item.title}>
										<Menu.Item >
											{({ active }) => (
												<NavLink
													to={item.path}
													className={classNames(
														active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
														'block px-5 py-2 text-sm'
													)}
												>
													{item.title}
												</NavLink>
											)}
										</Menu.Item>
									</div>
								);
							})}
						</div>

						<div className=" flex justify-center mb-7 mt-2">
							<button
								style={{
									borderRadius: 10,
									borderStyle: 'solid',
									borderWidth: 1,
									borderColor: 'lightgray',
									display: 'flex',
									justifyContent: 'center',
								}}
								className="focus:outline-none w-10/12 py-2 hover:bg-shadowColor"
								onClick={handleSignout}
							>
								<span className="flex gap-x-2">
									<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M4.75 3.38889H2.875C1.83947 3.38889 1 4.23457 1 5.27778V16.6111C1 17.6543 1.83947 18.5 2.875 18.5H12.25C13.2855 18.5 14.125 17.6543 14.125 16.6111V15.6667M4.75 3.38889C4.75 4.43209 5.58947 5.27778 6.625 5.27778H8.5C9.53553 5.27778 10.375 4.43209 10.375 3.38889M4.75 3.38889C4.75 2.34568 5.58947 1.5 6.625 1.5H8.5C9.53553 1.5 10.375 2.34568 10.375 3.38889M10.375 3.38889H12.25C13.2855 3.38889 14.125 4.23457 14.125 5.27778V8.11111M16 11.8889H6.625M6.625 11.8889L9.4375 9.05556M6.625 11.8889L9.4375 14.7222"
											stroke="#374151"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<h4>{i18n.t('logout')}</h4>
								</span>
							</button>
						</div>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default Index;
