import { useContext } from 'react';
import { DesignToolContext } from './DesignToolContext';
import { TRightSideBar } from './types';
const LeftSideBarActions = () => {
	const { designToolDispatch } = useContext(DesignToolContext);

	const selectElement = (value: TRightSideBar) => {
		designToolDispatch({ type: 'SELECTED_ELEMENT', payload: value });
	};

	return {
		selectElement,
	};
};

export default LeftSideBarActions;
