import React from 'react';
import { defaultDesignToolState, defaultPreviewCardOptions } from '../contexts/DesignTool/defaultStates';
import { DesignToolContext } from '../contexts/DesignTool/DesignToolContext';

const useMergeTagsFunctions = () => {
	const {
		setCsvFile,
		setMergeTags,
		setPreviewCardOptions,
		setSelectedProspectList,
		setcsvFields,
		setcurrentFile,
		setprospectListWithProspectData,
		setprospectLists,
	} = React.useContext(DesignToolContext);

	/**
	 * resets merge tags and prospects states
	 */
	const resetMergeTagsState = () => {
		setCsvFile(null);
		setMergeTags(defaultDesignToolState.mergeTags);
		setPreviewCardOptions(defaultPreviewCardOptions);
		setSelectedProspectList('');
		setcsvFields(null);
		setcurrentFile(null);
		setprospectListWithProspectData(null);
		setprospectLists([]);
	};

	return { resetMergeTagsState };
};

export default useMergeTagsFunctions;
