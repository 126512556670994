import React from 'react';

interface Props {
	ModelTitle: string;
	progressSteps: any;
}
const ProgressBar: React.FC<Props> = ({ ModelTitle, progressSteps }) => {
	return (
		<>
			<div
				className=" max-w-221 h-fit flex flex-col  py-3 px-6  "
				// style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
			>
				<div className="w-full pt-6 font-inter text-2xl font-bold text-center h-fit" style={{ color: '#111827' }}>
					Progress
				</div>
				<div className="flex gap-x-8 w-full  items-center justify-center mb-16 pt-6 h-82 ">
					<div className="flex flex-col w-fit h-full  items-center relative ">
						<div
							className={
								ModelTitle === 'Connect your email account' && progressSteps.length < 2
									? 'border-l-2 h-1/2 mt-2  '
									: 'hidden'
							}
							style={{ borderColor: '#3B82F6' }}
						></div>
						<div
							className={
								ModelTitle === 'Connect your email account' && progressSteps.length < 2
									? 'border-l-2 h-1/2 mt-2  '
									: 'hidden'
							}
							style={{ borderColor: '#AEAEAE' }}
						></div>
						<div
							className={
								ModelTitle === 'Add some recipients' && progressSteps.length < 2 ? 'border-l-2 h-full mt-2' : 'hidden'
							}
							style={{ borderColor: '#AEAEAE' }}
						></div>
						<div
							className={
								ModelTitle === 'Customize the subject line' || progressSteps.length >= 2
									? 'border-l-2 h-full mt-2'
									: 'hidden '
							}
							style={{ borderColor: '#3B82F6' }}
						></div>
						<div className="flex h-full absolute justify-between flex-col mt-1 ">
							<div>
								<svg
									className={ModelTitle === 'Add some recipients' && progressSteps.length < 1 ? '' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<circle cx="16" cy="16.5" r="5" fill="#2563EB" />
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#2563EB" />
								</svg>
								<svg
									className={ModelTitle !== 'Add some recipients' || progressSteps.length >= 1 ? '' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M22.7071 11.7929C23.0976 12.1834 23.0976 12.8166 22.7071 13.2071L14.7071 21.2071C14.3166 21.5976 13.6834 21.5976 13.2929 21.2071L9.29289 17.2071C8.90237 16.8166 8.90237 16.1834 9.29289 15.7929C9.68342 15.4024 10.3166 15.4024 10.7071 15.7929L14 19.0858L21.2929 11.7929C21.6834 11.4024 22.3166 11.4024 22.7071 11.7929Z"
										fill="#2563EB"
									/>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#2563EB" />
								</svg>
							</div>
							<div>
								<svg
									className={ModelTitle === 'Connect your email account' && progressSteps.length < 2 ? '' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<circle cx="16" cy="16.5" r="5" fill="#2563EB" />
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#2563EB" />
								</svg>
								<svg
									className={
										ModelTitle !== 'Connect your email account' &&
										ModelTitle !== 'Customize the subject line' &&
										progressSteps.length < 2
											? ' '
											: 'hidden'
									}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<circle cx="16" cy="16.5" r="5" fill="#AEAEAE" />
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#AEAEAE" />
								</svg>
								<svg
									className={ModelTitle === 'Customize the subject line' || progressSteps.length >= 2 ? '' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M22.7071 11.7929C23.0976 12.1834 23.0976 12.8166 22.7071 13.2071L14.7071 21.2071C14.3166 21.5976 13.6834 21.5976 13.2929 21.2071L9.29289 17.2071C8.90237 16.8166 8.90237 16.1834 9.29289 15.7929C9.68342 15.4024 10.3166 15.4024 10.7071 15.7929L14 19.0858L21.2929 11.7929C21.6834 11.4024 22.3166 11.4024 22.7071 11.7929Z"
										fill="#2563EB"
									/>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#2563EB" />
								</svg>
							</div>
							<div>
								<svg
									className={ModelTitle !== 'Customize the subject line' ? ' ' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<circle cx="16" cy="16.5" r="5" fill="#AEAEAE" />
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#AEAEAE" />
								</svg>
								<svg
									className={ModelTitle === 'Customize the subject line' ? '' : 'hidden'}
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<circle cx="16" cy="16.5" r="5" fill="#2563EB" />
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#2563EB" />
								</svg>

								<svg
									className="hidden"
									width="32"
									height="33"
									viewBox="0 0 32 33"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white" />
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M22.7071 11.7929C23.0976 12.1834 23.0976 12.8166 22.7071 13.2071L14.7071 21.2071C14.3166 21.5976 13.6834 21.5976 13.2929 21.2071L9.29289 17.2071C8.90237 16.8166 8.90237 16.1834 9.29289 15.7929C9.68342 15.4024 10.3166 15.4024 10.7071 15.7929L14 19.0858L21.2929 11.7929C21.6834 11.4024 22.3166 11.4024 22.7071 11.7929Z"
										fill="#10B981"
									/>
									<rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#10B981" />
								</svg>
							</div>
						</div>
					</div>
					<div className=" flex h-full  justify-between flex-col  py-1 mt-1 ">
						<div>Step 1</div>
						<div>Step 2</div>
						<div>Step 3</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProgressBar;
