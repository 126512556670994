import React from 'react'
import { useSelector } from 'react-redux';
interface Props {
  subjectLine1: string
  cardPreviewHeader: string
  isCta: boolean
  ctaText: string
  ctaLink: string
}
const ViewCardPreview: React.FC<Props> = ({ subjectLine1, cardPreviewHeader, isCta, ctaText, ctaLink }) => {
  const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
  const card = useSelector((state: any) => state.card);
  return (
    <div className='px-7 py-5'>
      <div>
        <div className='flex justify-between'>
          <div>
            <img className="w-28 " src={currentWorkspace?.previewSetting ? currentWorkspace?.previewSetting?.companyLogo:'https://res.cloudinary.com/interstellus/image/upload/v1675421128/Cardclan-Logo-final_t6l4ie.png'} alt='companylogo' />
          </div>
          <div className='mr-20 text-base font-medium' style={{ fontFamily: 'Fredoka One' }}>
            {cardPreviewHeader ? cardPreviewHeader : subjectLine1}
          </div>
          <div></div>
        </div>
        <div className='flex justify-center mt-2'>
          <img className='w-72' src={card?.currentCardData?.designUrl} alt='designImage' />
        </div>
        <div className={isCta ? 'flex justify-center mt-2' : 'hidden'}>
          <a href={`https://${ctaLink}`} target="_blank" className='px-3 py-2 text-white bg-indigo rounded-lg'>
            {ctaText}
          </a>
        </div>
      </div>
    </div>
  )
}

export default ViewCardPreview