import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CustomSwal from '../../../../../../components/CustomSwal/CustomSwalContainer';
import CustomTextField from '../../../../../../components/CustomTextField';
import LoadingButton from '../../../../../../components/LoadingButton/index';
import { setEmailAccountRdx } from '../../../../../../redux/actions';
import { email_account_services } from '../../../../../../services/request/emailAccounts';

const Index = ({ edit, emailAccount, onClose, schedule, emailAccounts }) => {
	const dispatch = useDispatch();
	const [selectedRadioButton, setselectedRadioButton] = useState('TLS');
	const [correspondingPort, setcorrespondingPort] = useState('');
	const [isloading, setisloading] = useState(false);
	const emailAccountRdx = useSelector((state) => state.emailAccountRdx);
	const currentWorkspace = useSelector((state) => state.currentWorkspace);
	const userProfile = useSelector((state) => state.userProfile);
	const [replyTo, setreplyTo] = useState(false);
	const whiteLabelSettings = useSelector((state) => state.whiteLabelSettings);
	const [swalOptions, setSwalOptions] = useState({
		visible: false,
		title: '',
		message: '',
		swalType: '',
	});

	const handleOnRadioButtonCheck = (e) => {
		setselectedRadioButton(e.target.name);
	};
	useEffect(() => {
		if (selectedRadioButton === 'SSL') {
			setcorrespondingPort('465');
		} else if (selectedRadioButton === 'TLS') {
			setcorrespondingPort('587');
		}
	}, [selectedRadioButton]);
	const regexPattern = /^(?!(?:'25')$)\d+/g;
	const validationSchema = Yup.object({
		firstName: Yup.string().min(2, 'must be atleast 2 characters ').max(25, 'must be atmost 25 characters '),
		lastName: Yup.string().min(2, 'must be atleast 2 characters ').max(25, 'must be atmost 25 characters '),
		email: Yup.string().email('Invalid Email Format').required('Email Is Required'),
		smtpPort: Yup.string().matches(
			regexPattern,
			'Email requests made through port 25 are blocked by the Host servers. It is advised to user some other port for this operation.'
		),
	});

	const onSumbit = async (e) => {
		console.log('oN submit');
		e.preventDefault();
		setisloading(true);

		let emailAccountSettings = {
			_id: emailAccount?._id,
			userId: userProfile._id,
			workspaceId: currentWorkspace._id,
			fromName: values.fromName,
			fromEmail: values.fromemail,
			userName: values.userName,
			password: values.password,
			smtphost: values.smtphost,
			smtpPort: parseInt(correspondingPort),
		};
		emailAccountSettings = replyTo
			? { ...emailAccountSettings, replyTo: values.replyto }
			: { ...emailAccountSettings, replyTo: values.fromemail };
		if (edit) {
			email_account_services
				.updateEmailAccount(emailAccountSettings)
				.then((data) => {
					let tempData = emailAccountRdx;
					// if its an array of objects
					if (tempData.length) {
						tempData = tempData?.map((item) => {
							return item.fromEmail === values.fromEmail &&
								item.workspaceId === values.workspaceId &&
								item.userId === values.userId
								? emailAccountSettings
								: item;
						});
					}
					dispatch(setEmailAccountRdx(tempData));
					setSwalOptions({
						visible: true,
						title: 'Success',
						message: `Your email account has been successfully updated`,
						swalType: 'success',
					});
					setisloading(false);
				})
				.catch((err) => {
					console.log('Catch of then');
					setisloading(false);
					setSwalOptions({
						visible: true,
						title: 'Oops',
						message: err.message,
						swalType: 'error',
					});
					console.log(err.message);
				});
		} else {
			email_account_services
				.createEmailAccount(emailAccountSettings)
				.then((data) => {
					let tempData = emailAccountRdx;
					tempData?.push(emailAccountSettings);
					dispatch(setEmailAccountRdx(tempData));
					setSwalOptions({
						visible: true,
						title: 'Success',
						message: `Your email account has been successfully ${edit ? 'updated' : 'connected'} `,
						swalType: 'success',
					});
					setisloading(false);
					resetForm('');
					onClose();
					if (!whiteLabelSettings) {
						const accountInfo = emailAccountSettings?.smtphost;
						if (emailAccountSettings && window.analytics) {
							window.analytics.track('Email_account_connected', {
								accountInfo,
							});
						}
						if (emailAccountSettings && window.hyperengage) {
							window.hyperengage('track', 'Email_account_connected', {
								properties: {
									accountInfo: accountInfo,
								},
							});
						}
					}
				})
				.catch((err) => {
					console.log('Catch of then');
					setisloading(false);
					setSwalOptions({
						visible: true,
						title: 'Oops',
						message: err.message,
						swalType: 'error',
					});
					console.log(err.message);
				});
		}
	};

	const initialValues = {
		fromName: '',
		userName: '',
		fromemail: '',
		smtphost: '',
		replyto: '',
		password: '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit: onSumbit,
		validationSchema,
	});

	const handleport = (e) => {
		setselectedRadioButton('');
		setcorrespondingPort(e.target.value);
	};
	const closeSwal = () => {
		setSwalOptions({
			visible: false,
			title: '',
			message: '',
			swalType: '',
		});
	};

	const { errors, handleChange, touched, values, resetForm, setFieldValue } = formik;

	useEffect(() => {
		if (!!emailAccount) {
			setFieldValue('fromName', emailAccount.fromName);
			setFieldValue('userName', emailAccount.userName);
			setFieldValue('fromemail', emailAccount.fromEmail);
			setFieldValue('smtphost', emailAccount.smtphost);
			setFieldValue('replyto', emailAccount.replyTo || '');
			setFieldValue('password', emailAccount.password);
			setcorrespondingPort(emailAccount.smtpPort || '');
			return !!emailAccount.replyTo ? setreplyTo(true) : false;
		}
	}, [emailAccount, setFieldValue]);

	return (
		<div>
			<form className=" px-8" onSubmit={onSumbit}>
				<div className="flex  gap-x-16  gap-y-3 flex-col py-10 w-full">
					<div className="flex flex-row items-center mb-5">
						<p className=" text-mineShaft text-2xl">{!!edit ? 'Edit' : 'Add'} Email address</p>
						{!whiteLabelSettings && (
						<a
							className="text-indigoLight ml-10"
							target="_blank"
							href="https://cardclan.helpscoutdocs.com/article/24-how-to-set-up-an-smtp-sender-account-cardclan"
							rel="noreferrer"
						>
							Need help connecting email account?
						</a>)}
					</div>
					<div className="grid grid-cols-2">
						<div className="flex flex-col w-11/12 gap-y-4">
							<div className="w-full text-black">
								<CustomTextField
									label="From name"
									name="fromName"
									id="fromName"
									onChange={handleChange}
									value={values.fromName}
									required
									className="new-textField-profile"
									error={Boolean(touched.fromName && errors.fromName)}
									helperText={touched.fromName && errors.fromName}
								/>
							</div>
							<div className="w-full">
								<CustomTextField
									label="user name"
									name="userName"
									id="userName"
									onChange={handleChange}
									value={values.userName}
									required
									className="new-textField-profile"
									error={Boolean(touched.userName && errors.userName)}
									helperText={touched.userName && errors.userName}
								/>
							</div>
							<div className="w-full text-black">
								<CustomTextField
									label="SMTP Host"
									name="smtphost"
									id="smtphost"
									onChange={handleChange}
									value={values.smtphost}
									required
									className="new-textField-profile"
									error={Boolean(touched.username && errors.username)}
									helperText={touched.username && errors.username}
								/>
							</div>

							<div className="flex items-center">
								{' '}
								<input
									type="checkbox"
									onChange={() => setreplyTo(!replyTo)}
									checked={replyTo}
									// className='new-textField-profile'
								/>
								<p className="ml-3">Set a different reply to address</p>
							</div>
						</div>

						{/* --------------left div---------- */}

						<div className="flex w-11/12 flex-col gap-y-4">
							<div className="w-full">
								<CustomTextField
									label="From email"
									name="fromemail"
									id="fromemail"
									required
									onChange={handleChange}
									type="email"
									value={values.fromemail}
									className="new-textField-profile focus:ring-0 "
									error={Boolean(touched.fromemail && errors.fromemail)}
									helperText={touched.fromemail && errors.fromemail}
								/>
							</div>
							<div className="w-full">
								<CustomTextField
									label="password"
									name="password"
									id="password"
									onChange={handleChange}
									value={values.password}
									required
									type="password"
									className="new-textField-profile focus:ring-0 "
									error={Boolean(touched.password && errors.password)}
									helperText={touched.password && errors.password}
								/>
							</div>
							<div className=" flex flex-row justify-between">
								<div className="flex w-3/12">
									{' '}
									<CustomTextField
										label="SMTP Port"
										name="smtpPort"
										required
										className="new-textField-profile"
										value={correspondingPort}
										onChange={handleport}
									/>
								</div>

								<div className="w-6/12 flex flex-row justify-between">
									<div className="flex items-center">
										<input
											radioGroup
											type="radio"
											name="SSL"
											onChange={handleOnRadioButtonCheck}
											checked={selectedRadioButton === 'SSL' ? true : false}
										/>
										<p className="ml-2">SSL</p>
									</div>
									<div className="flex items-center">
										{' '}
										<input
											type="radio"
											name="TLS"
											onChange={handleOnRadioButtonCheck}
											checked={selectedRadioButton === 'TLS' ? true : false}
										/>
										<p className="ml-2">TLS</p>
									</div>
								</div>
							</div>
						</div>
						<div className={replyTo ? 'w-full mt-6' : 'hidden'}>
							<CustomTextField
								label="Reply to"
								//
								name="replyto"
								id="replyto"
								onChange={handleChange}
								className="new-textField-profile"
								value={values.replyto}
							/>
						</div>
					</div>

					<LoadingButton isLoading={isloading} type="submit" className="invitation-button md:w-3/12 w-5/12 mt-5">
						{edit ? 'Update Settings' : 'Save Settings'}
					</LoadingButton>
				</div>
			</form>
			<CustomSwal closeSwal={closeSwal} swalOptions={swalOptions} />
		</div>
	);
};

export default Index;
