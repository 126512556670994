import { DuplicateIcon } from '@heroicons/react/solid';
import React from 'react';
import LoadingButton from '../../../../../../../../components/LoadingButton';
import { CardProspectsData, CardProspectStatusEnum } from '../../../../../../../../contexts/CardProspects/interfaces';

interface Props {
	cardProspectsData: CardProspectsData;
	computeTableColumns: (prospectObj: any) => [string, unknown][];
	computerHeaderColumns: () => Array<string>;
	copyCardUrlHandler: (cardProspect: any) => Promise<void>;
	isCopyCardUrlLoading: boolean;
	selectedCardProspect: string;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
}

const RecipientTable: React.FC<Props> = ({
	computerHeaderColumns,
	cardProspectsData,
	computeTableColumns,
	copyCardUrlHandler,
	isCopyCardUrlLoading,
	selectedCardProspect,
	infiniteRefCopyUrl,
	prospectsLoading,
	initialLoading,
}) => {
	return (
		<>
			<div className={initialLoading ? 'hidden' : 'border-2 border-gray200 h-64 overflow-scroll bg-lightgray'}>
				<table className="min-w-full divide-y divide-lightgray">
					<thead className="bg-lightgray border-b-2 border-gray200">
						<tr>
							{computerHeaderColumns().map((item) => (
								<th
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									{item}
								</th>
							))}
							<th scope="col" className="relative px-6 py-3 text-red">
								<span className="sr-only ">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="border divide-y-2 divide-gray200">
						{cardProspectsData?.cardProspects?.length ? (
							cardProspectsData?.cardProspects.map((cardProspect) => (
								<tr key={cardProspect._id} className={'bg-white'}>
									{computeTableColumns(cardProspect).map((item, idx) => (
										<td
											key={item[0]}
											className={`px-6 py-4 whitespace-nowrap text-sm ${
												idx ? 'text-mediumGray' : 'font-medium text-blackText'
											}`}
										>
											{' '}
											{typeof item[1] === 'string' && !!item[1] ? item[1] : '-'}
										</td>
									))}
									<td className="py-4 whitespace-nowrap text-right text-sm font-medium flex justify-start">
										<LoadingButton
											id={cardProspect._id}
											isLoading={cardProspect._id === selectedCardProspect ? isCopyCardUrlLoading : false}
											disabled={
												cardProspect.status === CardProspectStatusEnum.COPY
													? true
													: cardProspect._id === selectedCardProspect
													? isCopyCardUrlLoading
													: false
											}
											onClick={() => copyCardUrlHandler(cardProspect)}
											className={
												cardProspect.status === CardProspectStatusEnum.COPY
													? 'flex text-sm justify-center w-full text-white bg-indigo400 cursor-not-allowed hover:text-indigo-900 px-4 py-2 gap-2 rounded-md'
													: 'flex text-sm justify-center w-full text-white bg-indigo hover:text-indigo-900 hover:bg-darkIndigo px-4 py-2 gap-2 rounded-md'
											}
										>
											<DuplicateIcon className="w-5 h-5 text-white" />
											Copy URL
										</LoadingButton>
									</td>
								</tr>
							))
						) : (
							<p className={prospectsLoading ? 'hidden' : ''}>No recipients to copy.</p>
						)}
					</tbody>
				</table>
				<div ref={infiniteRefCopyUrl} className="p-2 bg-white">
					<p className={prospectsLoading ? 'animate-pulse rounded-lg h-5 text-indigo text-center' : 'hidden'}>
						Loading ...
					</p>
				</div>
			</div>
			<div
				className={
					initialLoading
						? 'flex flex-col border-2 animate-pulse border-gray200 h-64 sm:overflow-hidden bg-lightgray rounded-md'
						: 'hidden'
				}
			>
				<div className="bg-alto flex-none h-10 my-3 rounded mx-2"></div>
				<div className="bg-alto flex-1 my-3 rounded mx-2"></div>
			</div>
		</>
	);
};

export default RecipientTable;
