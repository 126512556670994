import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { setWhiteLabelSettings } from './redux/actions';
import { custom_domain_services } from './services/request/customDomain';
import React from 'react';
import { ROUTE_TITLES } from './Routes/routeNames';

export const WhiteLabelHead: React.FC = (props: any) => {
	const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
	const dispatch = useDispatch();

	useEffect(() => {
		console.log('White label head executed');
		/** Check whether host url is whitelabel or base */
		if (
			!process.env.REACT_APP_ALLOWED_HOST_DOMAINS?.split(',').includes(window.location.hostname) ||
			window.location.hostname !== 'localhost'
		) {
			/** Verifying the existence of white label settings */

			custom_domain_services
				.getCustomDomain()
				.then((whiteLabelData) => {
					if (whiteLabelData.status) {
						const whiteLabelOptions = whiteLabelData.data;
						if (whiteLabelOptions.connectionStatus && whiteLabelOptions.sslStatus) {
							dispatch(setWhiteLabelSettings(whiteLabelOptions));
						}
					} else {
						console.log('No whitelabel settings found. Using default settings.');
					}
				})
				.catch((err) => console.log(err.message));
		} else {
			console.log('Using default settings.');
		}
	}, [dispatch]);

	const isWhiteLabeled = whiteLabelSettings?.connectionStatus && whiteLabelSettings?.sslStatus;

	const path_title = window.location.href.split('/')[window.location.href.split('/').length - 1];

	const route_app_title = `${window.location.href}`.toLowerCase().includes('viewcard')
		? 'View card'
		: ROUTE_TITLES[path_title.split('?')[0]];

	return (
		<>
			{!!isWhiteLabeled ? (
				<Helmet>
					<meta charSet="utf-8" />
					<title>{`${route_app_title} | ${whiteLabelSettings?.app_title}`}</title>
					<link rel="icon" type="image/png" href={whiteLabelSettings?.logo?.Favicon} sizes="16x16" />
					{/* <link rel="icon" type="image/png" href={"https://s3.us-east-2.amazonaws.com/cardclan.io-assets/Favicon.png" + "?v=123"} sizes="16x16" /> */}
				</Helmet>
			) : (
				<Helmet>
					<title>{`${route_app_title} | CardClan`}</title>
					{process.env.NODE_ENV === 'production' ? (
						<script type="text/javascript">
							{`
                            <!-- Google Tag Manager -->
                            (function (w, d, s, l, i) {
                                w[l] = w[l] || []; w[l].push({
                                'gtm.start':
                            new Date().getTime(), event: 'gtm.js'
                                }); var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                            })(window, document, 'script', 'dataLayer', 'GTM-M7WGW7N');
                            <!-- Google Tag Manager End -->
                        `}
						</script>
					) : (
						''
					)}
				</Helmet>
			)}
			{props.children}
		</>
	);
};
