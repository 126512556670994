import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const useWhitelabelSettings = () => {
	const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
	const [isWhiteLabel, setIsWhiteLabel] = useState<boolean>(false);
	useEffect(() => {
		if (whiteLabelSettings) {
			const whiteLabel = !!whiteLabelSettings && whiteLabelSettings?.connectionStatus && whiteLabelSettings?.sslStatus;
			setIsWhiteLabel(whiteLabel);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return {
		isWhiteLabel,
		whiteLabelSettings,
	};
};
export default useWhitelabelSettings;
