import Konva from 'konva';
import React from 'react';
import { Rect } from 'react-konva';

interface Props {
	rectProps: any;
}

const Rects: React.FC<Props> = ({ rectProps }) => {
	const rectRef = React.useRef<Konva.Rect>(null);

	return <Rect  ref={rectRef} {...rectProps} draggable={false} />;
};
export default Rects;
