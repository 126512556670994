import { useImmerState } from '@shrugsy/use-immer-state';
import { isEqual } from 'lodash';
import React, { createContext, Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentCardData, setCurrentCardId } from '../../redux/actions';
import { card_services } from '../../services/request/card';
import {
	ProspectListsInterface,
	ProspectListWithProspectsInterface,
} from '../../services/request/prospectList.interface';
import { defaultDesignToolState, defaultEditMergeTagOptions, defaultPreviewCardOptions } from './defaultStates';
import { designToolInit, designToolReducer } from './DesignToolReducer';
import {
	Action,
	DesignToolState,
	EditMergeTag,
	EditProspectModalOptions,
	IDesignTool,
	PreviewCardOptions,
	TMergeTag,
} from './types';

const INITIAL_STATE = [{ textBoxes: [], images: [], ctas: [], gifs: [], rects: [] }];

export const DesignToolContext = createContext<IDesignTool>(defaultDesignToolState);

export const DesignToolProvider: React.FC = ({ children }) => {
	const [designToolState, designToolDispatch] = useReducer<Reducer<DesignToolState, Action>>(
		designToolReducer,
		designToolInit
	);
	const [refetch, setRefetch] = useState<boolean>(false);
	const [EmojiModal, setEmojiModal] = useState(false);
	const [selectedProspectList, setSelectedProspectList] = useState<string>('');
	const [csvModalOpen, setCsvModalOpen] = useState(false);
	const [csvFile, setCsvFile] = useState<File | null>(null);
	const [currentFile, setcurrentFile] = useState<string | null>(null);
	const [contactListModalOpen, setContactListModalOpen] = useState(false);
	const [openAddMergeTag, setOpenAddMergeTag] = useState<boolean>(false);
	const [showConfetti, setShowConfetti] = useState<boolean>(false);
	const [prospectListWithProspectData, setprospectListWithProspectData] =
		useImmerState<ProspectListWithProspectsInterface | null>(null);
	const [prospectLists, setprospectLists] = useState<ProspectListsInterface[] | []>([]);
	const [colorList, setColorList] = useState<any>(defaultDesignToolState.colorList);
	const [bgColorList, setBgColorList] = useState<any>(defaultDesignToolState.bgColorList);
	const [colorsList, setColorsList] = useState<any[]>([]);
	const [selectedCardId, setSelectedCardId] = useState<any>(null);
	const [mergeTags, setMergeTags] = useImmerState<Array<TMergeTag>>(defaultDesignToolState.mergeTags);
	const [editMergeTagOptions, setEditMergeTagOptions] = useImmerState<EditMergeTag>(defaultEditMergeTagOptions);
	const [prospectListEdit, setProspectListEdit] = useState(false);
	const [previewCardOptions, setPreviewCardOptions] = useImmerState<PreviewCardOptions>(defaultPreviewCardOptions);
	const [editProspectModalOptions, setEditProspectModalOptions] = useImmerState<EditProspectModalOptions>(
		defaultDesignToolState.editProspectModalOptions
	);
	const [showColorPickerModal, setColorPickerShowModal] = useState(false);
	const [copiedData, setCopiedData] = useState(null);
	const [csvFields, setcsvFields] = useState<Array<string> | null>(null);
	const [loadedImages, setLoadedImages] = useState<string[]>([]);
	let currentUrl = new URL(window.location.href);
	let cardID = currentUrl.searchParams.get('id');
	const [cardPages, setCardPagesX, { goBack, goForward, stepNum, history }] = useImmerState<any>(INITIAL_STATE);
	const [error, setError] = useState<string | null>(null);
	const $textAreaRef = useRef(null);
	const $textToolRef = useRef(null);
	const $emojiModalRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (cardID) {
			card_services
				.getCardById(cardID)
				.then((cardRes) => {
					dispatch(setCurrentCardId(cardRes._id));
					dispatch(setCurrentCardData(cardRes));
					const { cardPages: pagesFromDB, mergeTags: cardMergeTags } = cardRes;
					setCardPagesX((prev: any) => {
						if (!isEqual(prev, pagesFromDB)) {
							prev = pagesFromDB;
						}
						return prev;
					});
					if (pagesFromDB?.length > 1) {
						designToolDispatch({
							type: 'SET_CARD_CURSOR',
							payload: { page1: 0, page2: 1 },
						});
					}
					if (cardMergeTags?.length) {
						setMergeTags(cardMergeTags);
					}
				})
				.catch((cardErr) => {
					setError(JSON.stringify(cardErr));
				});
		}
	}, [cardID, dispatch, setCardPagesX, setMergeTags]);
	return (
		<DesignToolContext.Provider
			value={{
				designToolState,
				designToolDispatch,
				cardPages,
				setCardPagesX,
				goForward,
				goBack,
				stepNum,
				history,
				setEmojiModal,
				EmojiModal,
				csvModalOpen,
				setCsvModalOpen,
				csvFile,
				setCsvFile,
				contactListModalOpen,
				setContactListModalOpen,
				showColorPickerModal,
				setColorPickerShowModal,
				error,
				mergeTags,
				setMergeTags,
				editMergeTagOptions,
				setEditMergeTagOptions,
				openAddMergeTag,
				setOpenAddMergeTag,
				setSelectedProspectList,
				selectedProspectList,
				setprospectListWithProspectData,
				prospectListWithProspectData,
				editProspectModalOptions,
				setEditProspectModalOptions,
				prospectLists,
				setprospectLists,
				prospectListEdit,
				setProspectListEdit,
				previewCardOptions,
				setPreviewCardOptions,
				setcsvFields,
				csvFields,
				setcurrentFile,
				currentFile,
				$textAreaRef,
				$textToolRef,
				$emojiModalRef,
				refetch,
				setRefetch,
				setCopiedData,
				copiedData,
				setSelectedCardId,
				selectedCardId,
				showConfetti,
				setShowConfetti,
				colorList,
				setColorList,
				bgColorList,
				colorsList,
				setColorsList,
				setBgColorList,
				loadedImages,
				setLoadedImages,
			}}
		>
			{children}
		</DesignToolContext.Provider>
	);
};
