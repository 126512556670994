import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { CardProspectsContext } from '../../../../../contexts/CardProspects/CardProspectsContext';
import { DesignToolContext } from '../../../../../contexts/DesignTool/DesignToolContext';
// import { ROUTE_NAMES } from '../../../../../Routes/routeNames';
import { card_prospect_services } from '../../../../../services/request/cardProspect';
import AddRecipientModalContainer from './components/AddRecipientModal/AddRecipientModalContainer';
import CopyCardURLModalContainer from './components/CopyCardURLModal/CopyCardURLModalContainer';
import ScheduleLaterModalContainer from './components/ScheduleLaterModal/ScheduleLaterModalContainer';
import ScheduleSkipContinueModalContainer from './components/ScheduleSkipContinueModal/ScheduleSkipContinueModalContainer';
import Recipients from './Recipients';
declare const window: any;
interface Props {
	handleSearchRecipients: (value: string) => void;
	fetchProspectsAndProspectlist: () => void;
	infiniteRef: any;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
	onKeyDown: (e: any) => void;
	setSkip: React.Dispatch<React.SetStateAction<number>>;
	setTitleModel: any;
	setProgressSteps: React.Dispatch<any>;
	progressSteps: any;
}

const RecipientsContainer: React.FC<Props> = ({
	handleSearchRecipients,
	fetchProspectsAndProspectlist,
	infiniteRef,
	infiniteRefCopyUrl,
	prospectsLoading,
	onKeyDown,
	initialLoading,
	setTitleModel,
	setSkip,
	setProgressSteps,
	progressSteps,
}) => {
	const [openEditModal, setopenEditModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openCopyCardURL, setopenCopyCardURL] = useState<boolean>(false);
	const [recipientEdit, setrecipientEdit] = useState<boolean>(false);
	const [openScheduleLaterModal, setopenScheduleLaterModal] = useState<boolean>(false);
	const [openScheduleSkipContinueModal, setopenScheduleSkipContinueModal] = useState<boolean>(false);
	const cancelButtonRefScheduleLater = useRef(null);
	const { selectedProspectList, mergeTags, prospectLists, selectedCardId } = useContext(DesignToolContext);
	const { cardProspectsData } = useContext(CardProspectsContext);
	const [recipientOnEdit, setRecipientOnEdit] = useState<any>(null);
	const [isRecipientEditLoading, setIsRecipientEditLoading] = useState(false);
	const card = useSelector((state: any) => state.card);
	// const navigate = useNavigate();

	const openEditModalHandler = (edit: boolean) => {
		setrecipientEdit(edit);
		setopenEditModal(true);
	};

	const openCopyCardURLHandler = () => {
		setopenCopyCardURL(true);
	};

	const openSceduleLaterModalHandler = () => {
		!cardProspectsData.errors.errorCount ? setopenScheduleSkipContinueModal(true) : setopenScheduleLaterModal(true);
	};

	const openScheduleSkipContinueModalHandler = () => {
		setopenScheduleSkipContinueModal(true);
		setopenScheduleLaterModal(false);
	};

	const closeScheduleSkipContinueModalHandler = () => {
		setopenScheduleSkipContinueModal(false);
	};

	const closeSceduleLaterModalHandler = () => {
		setopenScheduleLaterModal(false);
	};

	const closeEditModalHandler = () => {
		setopenEditModal(false);
	};

	const closeCopyCardURLHandler = () => {
		setopenCopyCardURL(false);
	};

	const handleEditRecipient = (recipient?: any) => {
		if (recipient) {
			setRecipientOnEdit(recipient);
		} else {
			setRecipientOnEdit(null);
		}
	};

	const updateRecipientHandler = async (e: any) => {
		try {
			e.preventDefault();
			setIsRecipientEditLoading(true);
			if (Object.keys(recipientOnEdit).length) {
				const currRecipient = recipientOnEdit;
				let updatedRecipient: any = {};
				if (Object.keys(mergeTags).length) {
					mergeTags?.forEach((item: any) => {
						updatedRecipient[item.colName] = currRecipient[item.tagName];
					});
					let res: any = null;
					if (recipientEdit) {
						res = await card_prospect_services.updateCardProspect(currRecipient._id, {
							...updatedRecipient,
							prospectId: currRecipient.prospectId,
						});
						console.log('Response after update', res);
					} else {
						const prospectList = prospectLists?.find((item) => {
							return item.fileName === selectedProspectList;
						});
						if (prospectList?._id) {
							res = await card_prospect_services.createCardProspect(prospectList._id, card.currentCardId, {
								...updatedRecipient,
							});
						} else {
							console.log('No prospect list found while adding new prospect/recipient');
						}
					}
					if (res) {
						toast.info(`${recipientEdit ? 'Updated' : 'Added'} recipient successfully`, {
							position: 'bottom-left',
							autoClose: 5000,
						});
						setIsRecipientEditLoading(false);
						fetchProspectsAndProspectlist();
						closeEditModalHandler();
            setSkip(0)
					}
				} else {
					console.log('Unable to update recpient becuase no merge tags found.');
				}
			} else {
				console.log("There's no recipient to update.");
			}
		} catch (error: any) {
			setIsRecipientEditLoading(false);
			swal({
				icon: 'error',
				text: error.message,
			});
			console.log(error);
		} finally {
			setIsRecipientEditLoading(false);
		}
	};

	/**
	 * Changes card Prospects values on event => Onkeychange
	 * @param inputLabel
	 * @param value
	 */
	const recipientValueChangeHandler = (inputLabel: string, value: string) => {
		setRecipientOnEdit({ ...recipientOnEdit, [inputLabel]: value });
	};

	const openModalWithRecipientInfo = (edit: boolean, recipient?: any) => {
		openEditModalHandler(edit);
		handleEditRecipient(recipient);
	};

	const handleSendNow = async (title: string) => {
		try {
			const prospectListId = prospectLists?.find((item) => item.fileName === selectedProspectList)?._id;
			const dateAndTimeOffset = new Date().getTimezoneOffset();
			const date = new Date().getTime();
			const saveNow = true;
			if (prospectListId && selectedCardId) {
				setIsLoading(true);
				await card_prospect_services.updateCardProspects(
					prospectListId,
					selectedCardId,
					date,
					dateAndTimeOffset,
					saveNow
				);

				setTitleModel(title);
				const newArray = [...progressSteps];
				const findStatus = newArray?.includes('1');
				if (!findStatus) {
					newArray.push('1');
					setProgressSteps(newArray);
				}
			} else {
				console.log('No prospect list Or card Id found to process the request.');
			}
		} catch (error: any) {
			console.log({ error });
			swal({
				icon: 'error',
				title: 'Oops!',
				text: error.message,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const notifyDownload = async () => {
		if (cardProspectsData.totalDecrementCount) {
			const proceed = await swal({
				icon: 'warning',
				text: `This action will decrement your recipient limit by ${cardProspectsData.totalDecrementCount}!`,
				buttons: ['Cancel', 'Continue'],
			});
			if (proceed) await handleDownloadCsv();
		} else {
			await handleDownloadCsv();
		}
	};

	const handleDownloadCsv = async () => {
		try {
			const prospectList = prospectLists?.find((item) => {
				return item.fileName === selectedProspectList;
			});
			if (prospectList?._id) {
				const downloadRes = await card_prospect_services.downloadRecipientsCsv(
					card.currentCardId,
					prospectList._id,
					card?.currentCardData?.title
				);
				if (downloadRes) {
					console.log('Successfully downloaded file');
					fetchProspectsAndProspectlist();
				} else {
					console.log('Encountered an error while downloading.');
				}
			} else {
				console.log('no prospect list found');
			}
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<>
			<Recipients
				openEditModalHandler={openEditModalHandler}
				openCopyCardURLHandler={openCopyCardURLHandler}
				openSceduleLaterModalHandler={openSceduleLaterModalHandler}
				handleSearchRecipients={handleSearchRecipients}
				selectedProspectList={selectedProspectList}
				cardProspectsData={cardProspectsData}
				handleEditRecipient={handleEditRecipient}
				openModalWithRecipientInfo={openModalWithRecipientInfo}
				fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
				infiniteRef={infiniteRef}
				onKeyDown={onKeyDown}
				prospectsLoading={prospectsLoading}
				initialLoading={initialLoading}
				handleSendNow={handleSendNow}
				isLoading={isLoading}
				setSkip={setSkip}
			/>
			<AddRecipientModalContainer
				openEditModalHandler={openEditModalHandler}
				closeEditModalHandler={closeEditModalHandler}
				openEditModal={openEditModal}
				recipientEdit={recipientEdit}
				recipientOnEdit={recipientOnEdit}
				updateRecipientHandler={updateRecipientHandler}
				recipientValueChangeHandler={recipientValueChangeHandler}
				isRecipientEditLoading={isRecipientEditLoading}
			/>
			<CopyCardURLModalContainer
				openCopyCardURL={openCopyCardURL}
				openCopyCardURLHandler={openCopyCardURLHandler}
				closeCopyCardURLHandler={closeCopyCardURLHandler}
				fetchProspectsAndProspectlist={fetchProspectsAndProspectlist}
				infiniteRefCopyUrl={infiniteRefCopyUrl}
				prospectsLoading={prospectsLoading}
				initialLoading={initialLoading}
				handleDownloadCsv={notifyDownload}
			/>
			<ScheduleLaterModalContainer
				cancelButtonRefScheduleLater={cancelButtonRefScheduleLater}
				closeSceduleLaterModalHandler={closeSceduleLaterModalHandler}
				openScheduleLaterModal={openScheduleLaterModal}
				openScheduleSkipContinueModalHandler={openScheduleSkipContinueModalHandler}
			/>
			<ScheduleSkipContinueModalContainer
				openScheduleSkipContinueModal={openScheduleSkipContinueModal}
				openScheduleSkipContinueModalHandler={openScheduleSkipContinueModalHandler}
				closeScheduleSkipContinueModalHandler={closeScheduleSkipContinueModalHandler}
				closeSceduleLaterModalHandler={closeSceduleLaterModalHandler}
				setTitleModel={setTitleModel}
				setProgressSteps={setProgressSteps}
				progressSteps={progressSteps}
			/>
		</>
	);
};

export default RecipientsContainer;
