import { AxiosRequestConfig } from 'axios';
import axios from './axios';

const updateCardOpened = (cardId: string, recipientId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `analytics/cardOpened/${cardId}/${recipientId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('CardId and RecipientId error: ', error.message);
			reject(error);
		}
	});
};
const contactEngagement = (workspaceId: string, selectedDate: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/contactEngagement/${workspaceId}/${selectedDate}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('🚀 ~ error in ANALYTICS contact engagement', error);
			reject(error);
		}
	});
};

const campaignAnalytics = (workspaceId: string, selectedDate: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/campaignAnalytics/${workspaceId}/${selectedDate}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('🚀 ~ error', error);
			reject(error);
		}
	});
};
const getSingleCardAnalytics = (cardId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/getCardAnalytics/${cardId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			console.log('🚀 ~ error', error);
			reject(error);
		}
	});
};
const getMostEngagedContact = (workspaceId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/frequency/${workspaceId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error) {
			reject(error);
		}
	});
};
const getRecentContact = (workspaceId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/recentContact/${workspaceId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};
const getTotalCount = (workspaceId: string, getDate: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/totalContact/${workspaceId}/${getDate}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

const getTotalCardSent = (workspaceId: string, email: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `analytics/totalCardAnalytics/${workspaceId}/${email}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const analyticsService = {
	updateCardOpened,
	contactEngagement,
	campaignAnalytics,
	getMostEngagedContact,
	getRecentContact,
	getSingleCardAnalytics,
	getTotalCount,
	getTotalCardSent,
};
