import React from 'react';
import CsvUploaded from './CsvUploaded';

interface Props {
	csvFile: File | null;
	handleFileUpload: (e: any) => void;
	handleDeleteFile: () => void;
}

const CsvUploadedContainer: React.FC<Props> = ({ csvFile, handleFileUpload, handleDeleteFile }) => {
	return <CsvUploaded csvFile={csvFile} handleFileUpload={handleFileUpload} handleDeleteFile={handleDeleteFile} />;
};

export default CsvUploadedContainer;
