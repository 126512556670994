import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { CardProspectsContext } from '../../../../../../../../contexts/CardProspects/CardProspectsContext';
import { CardProspectStatusEnum } from '../../../../../../../../contexts/CardProspects/interfaces';
import { DesignToolContext } from '../../../../../../../../contexts/DesignTool/DesignToolContext';
import { TMergeTag } from '../../../../../../../../contexts/DesignTool/types';
import { card_prospect_services } from '../../../../../../../../services/request/cardProspect';
import RecipientTable from './RecipientTable';

interface Props {
	fetchProspectsAndProspectlist: () => void;
	infiniteRefCopyUrl: any;
	prospectsLoading: boolean;
	initialLoading: boolean;
}

const RecipientTableContainer: React.FC<Props> = ({
	fetchProspectsAndProspectlist,
	infiniteRefCopyUrl,
	prospectsLoading,
	initialLoading,
}) => {
	const { mergeTags } = useContext(DesignToolContext);
	const { cardProspectsData } = useContext(CardProspectsContext);
	const [isCopyCardUrlLoading, setisCopyCardUrlLoading] = useState<boolean>(false);
	const [selectedCardProspect, setselectedCardProspect] = useState<string>('');

	const computeTableColumns = (prospectObj: any): [string, unknown][] => {
		const { _id, prospectId, date, dateAndTimeOffset, saveNow, status, message, ...restAttrs } =
			cardProspectsData?.cardProspects[0];
		let attribs = Object.keys(restAttrs);
		const tableColumns = Object.entries(prospectObj).filter((item) => {
			return attribs.includes(item[0]);
		});
		return tableColumns;
	};

	const computerHeaderColumns = (): Array<string> => {
		const newAttribs: any = [];
		mergeTags.forEach((item: TMergeTag) => {
			newAttribs.push(item.tagName);
		});
		return newAttribs;
	};

	const copyToClipboard = (textToCopy: string) => {
		let dummy = document.createElement('textarea');
		document.body.appendChild(dummy);
		dummy.value = textToCopy;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		toast.info(`Link copied successfully`, {
			position: 'bottom-left',
			autoClose: 5000,
		});
	};

	const copyCardUrlHandler = async (cardProspect: any) => {
		try {
			const proceed = await swal({
				icon: 'warning',
				text: 'This action will decrement your recipient limit!',
				buttons: ['Cancel', 'Continue'],
			});
			if (proceed) {
				setisCopyCardUrlLoading(true);
				setselectedCardProspect(cardProspect._id);
				const res = await card_prospect_services.updateCardProspectStatus(
					cardProspect._id,
					CardProspectStatusEnum.COPY
				);
				copyToClipboard(res.link);
				fetchProspectsAndProspectlist();
			}
		} catch (error: any) {
			console.log({ error });
			toast.error(error.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				pauseOnFocusLoss: false,
			});
		} finally {
			setisCopyCardUrlLoading(false);
		}
	};

	return (
		<RecipientTable
			selectedCardProspect={selectedCardProspect}
			cardProspectsData={cardProspectsData}
			computerHeaderColumns={computerHeaderColumns}
			computeTableColumns={computeTableColumns}
			copyCardUrlHandler={copyCardUrlHandler}
			isCopyCardUrlLoading={isCopyCardUrlLoading}
			infiniteRefCopyUrl={infiniteRefCopyUrl}
			prospectsLoading={prospectsLoading}
			initialLoading={initialLoading}
		/>
	);
};

export default RecipientTableContainer;
