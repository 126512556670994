import { EmailAccountContext } from '../../../../../../contexts/CardProspects/emailAccountContext';
import { FromNameContext } from '../../../../../../contexts/CardProspects/fromName';
import { DesignToolContext } from '../../../../../../contexts/DesignTool/DesignToolContext';
import { addStringInString } from '../../../../../../utils/stringUtils';
import React, { useContext, useRef } from 'react';
import CustomizeSubjectLine from '.';
interface Props {
	title: string;
}
const CustomizeSubjectLineContainer: React.FC<Props> = ({ title }) => {
	const inputRef: any = useRef(null);
	const { mergeTags } = useContext(DesignToolContext);
	const { subjectLine, setSubjectLine, fromName } = useContext(FromNameContext);
	const { selectedEmailAccount } = useContext(EmailAccountContext);

	const handleAddMergeTag = (mergeTag: string) => {
		const sliceInd = inputRef.current?.selectionStart;
		let updatedText: string;
		if (sliceInd) {
			updatedText = addStringInString(subjectLine, sliceInd, `{{${mergeTag}}}`);
		} else {
			updatedText = `{{${mergeTag}}}${subjectLine || ''}`;
		}
		setSubjectLine(updatedText);
		inputRef.current?.focus();
	};
	return (
		<CustomizeSubjectLine
			handleAddMergeTag={handleAddMergeTag}
			selectedEmailAccount={selectedEmailAccount}
			mergeTags={mergeTags}
			fromName={fromName}
			inputRef={inputRef}
			subjectLine={subjectLine}
			setSubjectLine={setSubjectLine}
			title={title}
		/>
	);
};

export default CustomizeSubjectLineContainer;
