import { useContext } from 'react';
import { DesignToolContext } from './DesignToolContext';
import { TRightSideBar } from './types';

const DesignToolActions = () => {
	const { designToolDispatch } = useContext(DesignToolContext);

	const setSelectedPageIndex = (value: number) => {
		designToolDispatch({ type: 'SET_ACTIVE_PAGE_INDEX', payload: value });
	};

	const setCardCursor = (value: { page1: number; page2: number | null }) => {
		designToolDispatch({ type: 'SET_CARD_CURSOR', payload: value });
	};

	const setSelectedShapeName = (value: { shape_id: string | null; shapeType: TRightSideBar }) => {
		designToolDispatch({ type: 'SET_SELECTED_SHAPE_NAME', payload: value });
	};

	const resetStateMachine = () => {
		designToolDispatch({ type: 'RESET_STATE_MACHINE' });
	};

	return {
		setSelectedPageIndex,
		setCardCursor,
		setSelectedShapeName,
		resetStateMachine,
	};
};

export default DesignToolActions;
