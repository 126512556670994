import OpenReplay from '@openreplay/tracker';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import CustomLoader from '../../components/CustomLoader';
import NewHeader from '../../components/NewHeader';
import { ROUTE_NAMES } from '../../Routes/routeNames';
import { user_plan_services } from '../../services/request/userPlan';
import { UserPlanInterface } from '../../services/request/userPlan.interface';
import NewModal from '../../components/NewModal';
import SelectTemplate from './Dashboards/Components/SelectTemplate';
import useSvg from '../../utils/useSvg';
import { Crisp } from 'crisp-sdk-web';
import ChatBox from '../../components/ChatBox';
// import ContactsEngagementsList from './ContactsEngagementList';
// import AvatarImage from '../../assets/images/kate.jpg';
export const tracker = new OpenReplay({
  projectKey: 'GJKBWrfNUq34UHYYIsTf',
  ingestPoint: 'https://openreplay.cardclan.io/ingest',
});
const svgPath = '<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />';
declare const window: any;
const LoggedInLayout: React.FC = () => {
  const { pathname } = useLocation();
  const closeIcon = useSvg('http://www.w3.org/2000/svg', '0 0 24 24', 'white', '#808080', svgPath);
  const whiteLabelSettings = useSelector((state: any) => state.whiteLabelSettings);
  const userPlan = useSelector((state: any) => state.userPlan);
  const userProfile = useSelector((state: any) => state.userProfile);
  const [hover, setHover] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState(false);
  const [expiryBanner, setExpiryBanner] = useState(true);
  const [hoverIndex, sethoverIndex] = useState<number>(0);
  const [checkUserPlan, setUserPlan] = useState<UserPlanInterface>();

  const isCompanyNameAvailable = () => {
    if (userProfile.Company) {
      return userProfile.Company
    }
    else if (userProfile.email) {
      const result = userProfile.email.split('@')[1]
      let domains = ["yahoo.com", "gmail.com", "google.com", "hotmail.com", "me.com", "aol.com", "mac.com", "live.com", "comcast.com", "googlemail.com", "msn.com", "hotmail.co.uk", "yahoo.co.uk", "facebook.com", "verizon.net", "att.net", "gmz.com", "mail.com", 'outlook.com', 'mailinator.com'];
      const check = domains.includes(result)
      if (check) {
        return userProfile.email.split('@')[0]
      } else {
        return result
      }
    }
  }

  const companyName = isCompanyNameAvailable()
  useEffect(() => {
    if (!!userProfile) {
      // Get user plan
      user_plan_services
        .getUserPlan(userProfile?._id)
        .then((data) => {
          if (data) {
            // user plan exists
            setUserPlan(data);
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [userProfile]);
  const handleClick = () => {
    setSelectTemplate(true);
  };
  const handleClose = () => {
    setSelectTemplate(false);
  };
  let navigate = useNavigate();
  const handleUpgradePlan = () => {
    navigate(ROUTE_NAMES.billing);
  };
  const navBarList = [
    {
      title: 'Dashboard',
      path: ROUTE_NAMES.dashboard,
      iconLink: (c: string) => (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-2">
          <path
            d="M1 10L3 8M3 8L10 1L17 8M3 8V18C3 18.5523 3.44772 19 4 19H7M17 8L19 10M17 8V18C17 18.5523 16.5523 19 16 19H13M7 19C7.55228 19 8 18.5523 8 18V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V18C12 18.5523 12.4477 19 13 19M7 19H13"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      title: 'Cards',
      path: ROUTE_NAMES.mycards,
      iconLink: (c: string) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-2">
          <path
            d="M4 16L8.58579 11.4142C9.36683 10.6332 10.6332 10.6332 11.4142 11.4142L16 16M14 14L15.5858 12.4142C16.3668 11.6332 17.6332 11.6332 18.4142 12.4142L20 14M14 8H14.01M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      title: 'Templates',
      path: ROUTE_NAMES.templates,
      iconLink: (c: string) => (
        <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-2">
          <path
            d="M1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V4C1 4.26522 1.10536 4.51957 1.29289 4.70711C1.48043 4.89464 1.73478 5 2 5H16C16.2652 5 16.5196 4.89464 16.7071 4.70711C16.8946 4.51957 17 4.26522 17 4V2C17 1.73478 16.8946 1.48043 16.7071 1.29289C16.5196 1.10536 16.2652 1 16 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8946 1.73478 17 2 17H8C8.26522 17 8.51957 16.8946 8.70711 16.7071C8.89464 16.5196 9 16.2652 9 16V10C9 9.73478 8.89464 9.48043 8.70711 9.29289C8.51957 9.10536 8.26522 9 8 9H2C1.73478 9 1.48043 9.10536 1.29289 9.29289Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2929 9.29289C13.1054 9.48043 13 9.73478 13 10V16C13 16.2652 13.1054 16.5196 13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17H16C16.2652 17 16.5196 16.8946 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16V10C17 9.73478 16.8946 9.48043 16.7071 9.29289C16.5196 9.10536 16.2652 9 16 9H14C13.7348 9 13.4804 9.10536 13.2929 9.29289Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      title: 'Settings',
      path: ROUTE_NAMES.settings,
      iconLink: (c: string) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-2">
          <path
            d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
            stroke={c}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const email = userProfile?.email;
  const uid = userProfile?._id;
  const currentWorkspace = useSelector((state: any) => state.currentWorkspace);
  //const workspaceOwnerId = currentWorkspace?.owner_id
  tracker.setUserID(email || uid || 'No userID for this User');
  tracker.setMetadata('Current Workspace', currentWorkspace?._id);
  tracker.setMetadata('Current Plan', userPlan?._id);

  const name = useSelector((state: any) => {
    let { firstName, lastName } = state?.userProfile;
    let name = firstName && firstName !== '-' ? firstName : '';
    name = name + ' ' + (lastName && lastName !== '-' ? lastName : '');
    return String(name).trim().length ? name : null;
  });
  // only start tracker on production
  if (process.env.NODE_ENV === 'production') {
    tracker.start();
    if (!whiteLabelSettings) {
      if (window.hyperengage) {

        window.hyperengage(
          'identify_account', {
          account_id: currentWorkspace?._id,
          traits: {
            name: currentWorkspace?.name,
            is_trial: userPlan?.subscriptionType==='trial' ? true : false,
            plan_name: userPlan?.plan_data?.plan_id,
            created_at: currentWorkspace?.createdAt,
            trial_start_date: currentWorkspace?.createdAt,
            company_name: companyName
          }
        }
        );
        window.hyperengage(
          'identify_user', {
          user_id: uid,
          traits: {
            name: name,
            first_name: userProfile?.firstName,
            last_name: userProfile?.lastName,
            email: userProfile?.email,
            created_at: userProfile?.createdAt
          }
        }
        );
        if(window.analytics){
          window.analytics.group(currentWorkspace?._id, {
            name: currentWorkspace?.name,
              is_trial: userPlan?.subscriptionType==='trial' ? true : false,
              plan: userPlan?.plan_data?.plan_id,
              createdAt: currentWorkspace?.createdAt,
              trial_start_date: currentWorkspace?.createdAt,
              company_name: companyName
          });  
        }
      }
      
    }
  }

  const isWhiteLabeled =
    whiteLabelSettings?.domain === window.location.hostname &&
    whiteLabelSettings?.connectionStatus &&
    whiteLabelSettings?.sslStatus;

  const handleHover = (index: number) => {
    sethoverIndex(index);
    setHover(true);
  };
  const handleHoverClear = () => {
    setHover(false);
  };

  const defaultStyle = { minHeight: '100vh', backgroundColor: '#113190' };
  const whiteLabelStyle = {
    minHeight: '100vh',
    backgroundColor: whiteLabelSettings?.brandColors?.['Sidebar Color'],
  };
  const sideBarLogo = whiteLabelSettings?.logo?.['Dark Logo'];

  useEffect(() => {
    Crisp.user.setEmail(email);
    Crisp.user.setNickname(name);
    
    Crisp.session.setData({
      user_id : uid,
      email: email,
      plan: userPlan?.plan_id
    });

  }, [name, email, uid, userPlan]);

  return (
    <>
      {!isWhiteLabeled && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
            },
          ]}
        >
          <script defer>
            {process.env.NODE_ENV === 'production' && uid && email
              ? `
              <!-- Segment Identify -->
                analytics.identify('${uid}', {
                  ${name ? `name: '${name}',` : ''}
                  email: '${email}',
                  'Plan Name': '${userPlan?.plan_id}'
                });
                <!-- Segment Identify End -->
            `
              : ''}
          </script>
        </Helmet>
      )}
      {!isWhiteLabeled && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
            },
          ]}
        >
          <script defer>
          
            {process.env.NODE_ENV === 'production' && uid && email
              ? `
                <!-- Group Identify -->
                  analytics.group(${currentWorkspace?._id}, {
                    name: ${name},
                    companyName: ${companyName},
                    planName: ${userPlan?.plan_id},
                    isTrial: ${userPlan?.trialUsed},
                    planType: ${userPlan?.subscriptionType}
                  });              
                  <!-- Group Identify End-->
            `
              : ''}
          </script>
        </Helmet>
      )}
      {/* {!isWhiteLabeled && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
            },
          ]}
        >
          <script defer>
            {process.env.NODE_ENV === 'production' && uid && email
              ? `
              <!-- User Identify Start -->
              Beacon('identify', {
                name: '${name}',
                email: '${email}',
                userid: '${uid}',
              })
              <!-- User Identify End -->
            `
              : ''}
          </script>
        </Helmet>
      )} */}
      {!isWhiteLabeled && (
        <ChatBox />
      )}
      <div className="flex flex-row font-inter relative bg-bgGray gap-x-8">
        <div
          className={'hidden  pt-5 gap-y-8 max-w-112  fixed  md:block h-full'}
          style={isWhiteLabeled ? whiteLabelStyle : defaultStyle}
        >
          <div className="w-full pb-8 flex flex-col items-center justify-between h-full   ">
            <div className="w-full flex flex-col ">
              <div className="flex justify-center w-14   items-center  rounded-full mx-auto">
                <img
                  className="md:h-12  h-8 pl-1 md:pt-0 "
                  alt="cclan"
                  src={isWhiteLabeled ? sideBarLogo : 'https://s3.us-east-2.amazonaws.com/cardclan.io-assets/cclan.png'}
                />
              </div>
              <div className="flex-1 mt-6 w-full px-2 space-y-6">
                {navBarList.map((item, index) => (
                  <Suspense fallback={<CustomLoader />} key={item.title}>
                    <NavLink
                      onMouseOver={() => handleHover(index)}
                      onMouseLeave={handleHoverClear}
                      to={item.path}
                      className={({ isActive }) =>
                        isActive
                          ? 'bg-navbarActive flex px-3 py-2 rounded-md flex-col group items-center justify-center text-white w-full'
                          : 'flex  py-2 px-3 hover:bg-navbarActive hover:text-white rounded-md flex-col  items-center justify-center text-white w-full'
                      }
                    >
                      {item.iconLink(
                        pathname.includes(item.path) || (hover && hoverIndex === index) ? '#DBE3FE' : '#2858F0'
                      )}
                      <p className=" flex text-center px-1 text-sm">{item.title}</p>
                    </NavLink>
                  </Suspense>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className=" max-w-112
				 md:flex hidden"
        >
          sasiakhdn
        </div>
        <div className=" h-screen w-full">
          <div
            style={{
              display: pathname.includes(ROUTE_NAMES.settings) ? 'none' : '',
            }}
          >

            <NewHeader handleClick={handleClick} />
          </div>
          {/* Desktop Banner */}
          <div
            className={
              expiryBanner && checkUserPlan && checkUserPlan?.plan_data?.plan_id === 'dummy_plan'
                ? 'top-0 fixed bg-rose-500 flex  justify-between ml-2.5 items-center px-6 md:px-40 h-14 text-white z-50'
                : 'hidden'
            }
            style={{ width: '100%' }}
          >
            <div></div>
            <div className="flex ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p>
                Your current plan is expired.&nbsp;
                <span>
                  <button className="underline hover:no-underline" onClick={handleUpgradePlan}>
                    Upgrade Plan
                  </button>
                </span>
                &nbsp;here.
              </p>
            </div>
            <div>
              <button onClick={() => setExpiryBanner(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* Mobile Banner */}
          <div
            className={
              expiryBanner && checkUserPlan && checkUserPlan?.plan_data?.plan_id === 'dummy_plan'
                ? 'top-0 fixed bg-rose-500  hidden w-full  justify-between items-center px-8 h-14 text-white z-40'
                : 'hidden'
            }
          >
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p>
                Your current plan is expired.&nbsp;
                <span>
                  <button className="underline hover:no-underline" onClick={handleUpgradePlan}>
                    Upgrade Plan
                  </button>
                </span>
                &nbsp;here.
              </p>
            </div>
            <div>
              <button onClick={() => setExpiryBanner(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Suspense fallback={<CustomLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <NewModal open={selectTemplate} closeIcon={closeIcon} onClose={handleClose} className="w-4/6 bg-white relative">
        <SelectTemplate handleClose={handleClose} />
      </NewModal>
    </>
  );
};

export default LoggedInLayout;
