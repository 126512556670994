import generatesvgUrl from './generatesvgUrl';
import spacetime from 'spacetime';
import informal from 'spacetime-informal';

/** Doesn't allow Special characters or backspaces */
export const regexPatternFormik = /^[a-z|A-Z|]\w+(?: [\w]+)*\s*$/;
export const stageDimensions = { width: 320, height: 458 };
export const googleFontsList = [
	'Montserrat',
	'Comfortaa',
	'Euphoria Script',
	'Josefin Sans',
	'Oswald',
	'Alfa Slab One',
	'Bangers',
	'Caveat',
	'DM Sans',
	'East Sea Dokdo',
	'Homemade Apple',
	'Kristi',
	'Lobster Two',
	'Maven Pro',
	'Monoton',
	'Playfair Display',
	'Raleway',
	'Roboto Slab',
	'Shadows Into Light',
	'Swanky and Moo Moo',
];
export const fontSizes = Array.from(Array(50).keys()).map((num) => num + 1);

export const imageProxyBlur = 'https://image.cardclan.io/api/imageproxy/10x,q20/';

export const defaultTextNoEffect = {
	effectType: 'none',
	fill: 'black',
	stroke: '',
	shadowEnabled: false,
	shadowBlur: 0,
	shadowColor: '',
	shadowOffset: { x: 0, y: 0 },
};
export const defaultTextShadow = {
	effectType: 'shadow',
	fill: 'black',
	shadowEnabled: true,
	shadowColor: 'grey',
	shadowOffset: { x: 2, y: 2 },
	shadowBlur: 2,
	stroke: '',
};
export const defaultTextHollow = {
	effectType: 'hollow',
	fill: 'white',
	stroke: 'black',
	strokeWidth: 0.6,
	shadowColor: '',
	shadowEnabled: false,
	shadowBlur: 0,
	shadowOffset: { x: 0, y: 0 },
};
export const defaultTextLift = {
	effectType: 'lift',
	fill: 'black',
	shadowEnabled: true,
	shadowBlur: 10,
	stroke: '',
	shadowColor: '',
	shadowOffset: { x: 0, y: 0 },
};
export const defaultTextSplice = {
	effectType: 'splice',
	shadowColor: 'grey',
	shadowOffset: { x: 2, y: 2 },
	shadowBlur: 5,
	fill: 'white',
	stroke: 'black',
	shadowEnabled: true,
};
export const defaultTextGlitch = {
	effectType: 'glitch',
	fill: 'darkerblue',
	shadowEnabled: true,
	shadowColor: 'magenta',
	shadowOffset: { x: -2, y: -2 },
	shadowBlur: 2,
	stroke: '',
};
export const defaultTextNeon = {
	effectType: 'neon',
	fill: 'magenta',
	shadowColor: 'magenta',
	shadowEnabled: true,
	shadowBlur: 10,
	shadowOffset: { x: -2, y: -2 },
};
export const defaultBackgroundProps = {
	draggable: false,
	name: 'bg_image',
	id: 'images_background',
	// FIXME - use some small white background image from our s3 server
	downloadUrl: 'https://s3.us-east-2.amazonaws.com/cardclan.io-assets/whitebg.png',
	x: 0,
	y: 0,
	width: stageDimensions.width,
	height: stageDimensions.height,
};
export const CloseIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
</svg>`);
export const DeleteIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>`);
export const CloneIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
</svg>`);
export const DraftIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
</svg>`);
export const TickIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="#ffffff" viewBox="0 0 24 24" stroke="#000000">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
  </svg>`);
export const CalendarIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="#ffffff" viewBox="0 0 24 24" stroke="#000000">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>`);
export const LeftCaretIcon_SVG =
	generatesvgUrl(`<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
  </svg>`);

export const i18nTimezones = {
	'Pacific/Midway': 'Midway Island, Samoa',
	'Pacific/Honolulu': 'Hawaii',
	'America/Juneau': 'Alaska',
	'America/Boise': 'Mountain Time',
	'America/Dawson': 'Dawson, Yukon',
	'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
	'America/Phoenix': 'Arizona',
	'America/Chicago': 'Central Time',
	'America/Regina': 'Saskatchewan',
	'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
	'America/Belize': 'Central America',
	'America/Detroit': 'Eastern Time',
	'America/Bogota': 'Bogota, Lima, Quito',
	'America/Caracas': 'Caracas, La Paz',
	'America/Santiago': 'Santiago',
	'America/St_Johns': 'Newfoundland and Labrador',
	'America/Sao_Paulo': 'Brasilia',
	'America/Tijuana': 'Tijuana, Pacific Time',
	'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
	'America/Godthab': 'Greenland',
	'Atlantic/Azores': 'Azores',
	'Atlantic/Cape_Verde': 'Cape Verde Islands',
	GMT: 'UTC',
	'Europe/London': 'Edinburgh, London',
	'Europe/Dublin': 'Dublin',
	'Europe/Lisbon': 'Lisbon',
	'Africa/Casablanca': 'Casablanca, Monrovia',
	'Atlantic/Canary': 'Canary Islands',
	'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
	'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
	'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
	'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
	'Africa/Algiers': 'West Central Africa',
	'Europe/Bucharest': 'Bucharest',
	'Africa/Cairo': 'Cairo',
	'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
	'Europe/Athens': 'Athens, Istanbul, Minsk',
	'Asia/Jerusalem': 'Jerusalem',
	'Africa/Harare': 'Harare, Pretoria',
	'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
	'Asia/Kuwait': 'Kuwait, Riyadh',
	'Africa/Nairobi': 'Nairobi',
	'Asia/Baghdad': 'Baghdad',
	'Asia/Tehran': 'Tehran',
	'Asia/Dubai': 'Abu Dhabi, Muscat',
	'Asia/Baku': 'Baku, Tbilisi, Yerevan',
	'Asia/Kabul': 'Kabul',
	'Asia/Yekaterinburg': 'Ekaterinburg',
	'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
	'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
	'Asia/Kathmandu': 'Kathmandu',
	'Asia/Dhaka': 'Astana, Dhaka',
	'Asia/Colombo': 'Sri Jayawardenepura',
	'Asia/Almaty': 'Almaty, Novosibirsk',
	'Asia/Rangoon': 'Yangon Rangoon',
	'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
	'Asia/Krasnoyarsk': 'Krasnoyarsk',
	'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
	'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
	'Asia/Taipei': 'Taipei',
	'Australia/Perth': 'Perth',
	'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
	'Asia/Seoul': 'Seoul',
	'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
	'Asia/Yakutsk': 'Yakutsk',
	'Australia/Darwin': 'Darwin',
	'Australia/Adelaide': 'Adelaide',
	'Australia/Sydney': 'Canberra, Melbourne, Sydney',
	'Australia/Brisbane': 'Brisbane',
	'Australia/Hobart': 'Hobart',
	'Asia/Vladivostok': 'Vladivostok',
	'Pacific/Guam': 'Guam, Port Moresby',
	'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
	'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
	'Pacific/Fiji': 'Fiji Islands',
	'Pacific/Auckland': 'Auckland, Wellington',
	'Pacific/Tongatapu': "Nuku'alofa",
};

const labelStyle = 'original';

export const getOptions = Object.entries(i18nTimezones)
	.reduce((selectOptions: any, zone) => {
		const now = spacetime.now().goto(zone[0]);
		const tz = now.timezone();
		const tzStrings = informal.display(zone[0]);

		let label = '';
		let abbrev = zone[0];
		let altName = zone[0];

		if (tzStrings && tzStrings.daylight && tzStrings.standard) {
			abbrev = now.isDST() ? tzStrings.daylight.abbrev : tzStrings.standard.abbrev;
			altName = now.isDST() ? tzStrings.daylight.name : tzStrings.standard.name;
		}

		const min = tz.current.offset * 60;
		const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
		const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

		switch (labelStyle) {
			case 'original':
				label = prefix;
				break;
			// case 'altName':
			//   label = `${prefix} ${!altName.includes('/') ? `(${altName})` : ''}`;
			//   break;
			// case 'abbrev':
			//   label = `${prefix} ${abbrev.length < 5 ? `(${abbrev})` : ''}`;
			//   break;
			default:
				label = `${prefix}`;
		}

		selectOptions.push({
			value: zone[0],
			label: label,
			offset: tz.current.offset,
			abbrev: abbrev,
			altName: altName,
		});

		return selectOptions;
	}, [])
	.sort((a: any, b: any) => a.offset - b.offset);
