import { AxiosRequestConfig } from 'axios';
import axios from './axios';

const getCardsByUser = (userId: string, workspaceId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/card/user/${userId}/${workspaceId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('getCardsByUser error: ', error.message);
			reject(error);
		}
	});
};

const getCardsByWorkspace = (workspace_id: string, date: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/card/workspace/${workspace_id}/${date}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const getCardById = (cardId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/card/${cardId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const getCardByIdForMergeTag = (cardId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/card/merge-tags/${cardId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const getCardPagesById = (cardId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/cardpage/${cardId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data.cardPages);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const createNewCard = (userId: string, cardObj: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const { cardPages, ...cardData } = cardObj;
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/card`,
				data: {
					card: {
						...cardData,
						owner: userId,
					},
					cardPages,
				},
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const updateCardById = (cardObj: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const { _id, cardPages, ...card } = cardObj;
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/${_id}`,
				data: { cardPages, card },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};
const updateCardTitleById = (cardObj: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const { _id, cardPages, ...card } = cardObj;
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/cardtitle/${_id}`,
				data: { cardPages, card },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const updateCardPagesById = (cardId: string, cardPages: any[]): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/cardpages/${cardId}`,
				data: { cardPages },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const updateCardStatusByCardId = (cardId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/change-status/${cardId}`,
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec error while changing card status to sent', error);
			reject(error);
		}
	});
};

const cloneCardById = (cardId: string, userId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'get',
				url: `/card/clone/${cardId}`,
				params: {
					userId,
				},
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const changeCardCoverUrl = (cardId: string, coverUrl: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/change-cover/${cardId}`,
				params: { coverUrl },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const deleteCardById = (cardId: string, userId: string): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'delete',
				url: `/card/`,
				params: { cardId, deleterId: userId },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const scheduleCard = (cardId: string, cardData: any, prospectListId: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'post',
				url: `/recipient`,
				data: { cardId, cardData, prospectListId },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

const updateMergeTags = (cardId: string, mergeTags: Array<any>, prospectList_id?: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			const config: AxiosRequestConfig = {
				method: 'put',
				url: `/card/merge-tags/${cardId}`,
				data: { prospectList_id, mergeTags },
			};
			const resp = await axios(config);
			const response = resp.data;
			resolve(response.data);
		} catch (error: any) {
			console.log('Axios catch exec', error);
			reject(error);
		}
	});
};

export const card_services = {
	getCardsByUser,
	getCardsByWorkspace,
	getCardById,
	getCardPagesById,
	createNewCard,
	updateCardById,
	updateCardPagesById,
	cloneCardById,
	changeCardCoverUrl,
	deleteCardById,
	scheduleCard,
	updateCardStatusByCardId,
	updateMergeTags,
	getCardByIdForMergeTag,
  updateCardTitleById
};
