export const defaultPreviewCardOptions = {
	modalOpen: false,
	previewProspect: false,
	prospectData: null,
};

export const defaultEditMergeTagOptions = {
	open: false,
	tag: null,
};

export const defaultDesignToolState = {
	designToolState: {
		activePageIndex: 0,
		cardCursor: { page1: 0, page2: null },
		right_side_bar_selection: { element: null, type: null },
		selectedShapeName: null,
		$stage1: { current: null },
		$layer1: { current: null },
		$tr1: { current: null },
		$selectionRect1: { current: null },
		$stage2: { current: null },
		$layer2: { current: null },
		$tr2: { current: null },
		$selectionRect2: { current: null },
	},
	designToolDispatch: () => null,
	cardPages: [],
	setCardPagesX: () => null,
	EmojiModal: false,
	copiedData: null,
	setEmojiModal: () => false,
	refetch: false,
	setRefetch: () => null,
	setCopiedData: () => null,
	csvModalOpen: false,
	setCsvModalOpen: () => false,
	csvFile: null,
	setCsvFile: () => null,
	showConfetti: false,
	setShowConfetti: ()=> null,
	contactListModalOpen: false,
	setContactListModalOpen: () => null,
	openAddMergeTag: false,
	setOpenAddMergeTag: () => null,
	showColorPickerModal: false,
	setColorPickerShowModal: () => false,
	setSelectedProspectList: () => null,
	setProspectListEdit: () => null,
	prospectListEdit: false,
	selectedProspectList: '',
	prospectListWithProspectData: null,
	setprospectListWithProspectData: () => null,
	editProspectModalOptions: { open: false, prospectData: null, loading: false },
	setEditProspectModalOptions: () => null,
	setprospectLists: () => null,
	previewCardOptions: {
		modalOpen: false,
		previewProspect: false,
		prospectData: null,
	},
	setPreviewCardOptions: () => null,
	prospectLists: [],
	colorList: ["#B2DEEC", "#FFE15D", "#F49D1A", "#DC3535", "#B01E68"],
	setColorList: () => null,
	setLoadedImages: () => null,
	loadedImages: [],
	bgColorList: ["#B2DEEC", "#FFE15D", "#F49D1A", "#DC3535", "#B01E68"],
	colorsList: [],
	setBgColorList: () => null,
	setColorsList: () => null,
	goForward: () => null,
	goBack: () => null,
	stepNum: 0,
	history: [],
	setMergeTags: () => null,
	csvFields: null,
	setcsvFields: () => null,
	setcurrentFile: () => null,
	currentFile: null,
	mergeTags: [
		{ tagName: 'name', colName: 'Select value', fallback: '' },
		{ tagName: 'email', colName: 'Select value', fallback: '' },
	],
	editMergeTagOptions: defaultEditMergeTagOptions,
	setEditMergeTagOptions: () => null,
	error: null,
	$textAreaRef: { current: null },
	$textToolRef: { current: null },
	$emojiModalRef: { current: null },
  setSelectedCardId: () => null,
  selectedCardId:null
};
